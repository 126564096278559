import React from "react";
import loginService from '../services/login';
import Cookies from 'universal-cookie';

var UserStateContext = React.createContext();
var UserDispatchContext = React.createContext();

function userReducer(state, action) {
  switch (action.type) {
    case "LOGIN_SUCCESS":
      return { ...state, isAuthenticated: true };
      case "LOGIN_FAILURE":
        return { ...state, isAuthenticated: false };
      case "SIGN_OUT_SUCCESS":
      return { ...state, isAuthenticated: false };
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function UserProvider({ children }) {
  var [state, dispatch] = React.useReducer(userReducer, {
    isAuthenticated: !!localStorage.getItem("id_token"),
  });

  return (
    <UserStateContext.Provider value={state}>
      <UserDispatchContext.Provider value={dispatch}>
        {children}
      </UserDispatchContext.Provider>
    </UserStateContext.Provider>
  );
}

function useUserState() {
  var context = React.useContext(UserStateContext);
  if (context === undefined) {
    throw new Error("useUserState must be used within a UserProvider");
  }
  return context;
}

function useUserDispatch() {
  var context = React.useContext(UserDispatchContext);
  if (context === undefined) {
    throw new Error("useUserDispatch must be used within a UserProvider");
  }
  return context;
}

export { UserProvider, useUserState, useUserDispatch, loginUser, signOut };

// ###########################################################

async function loginUser(dispatch, login, password, history, setError, setMessage) {
  setError(false);

  if (!!login && !!password) {

    const resp = await loginService(login, password);
    if (resp.user === null || resp.token === null) {
      if(resp.message === 'AUTH_NOT_VERIFIED') {
        dispatch({ type: "LOGIN_FAILURE" });
        setMessage('Controlla la tua casella di posta elettronica per completare la procedura di verifica.');
        setError(true);
      } else {
        dispatch({ type: "LOGIN_FAILURE" });
        setError(true);
        setMessage('Email o password errata');
      }
    } else {

      const cookies = new Cookies();
      cookies.set('token', resp.token, { path: '/' });
      cookies.set('username', resp.user.username, { path: '/' });
      cookies.set('id', resp.user.id, { path: '/' });
      cookies.set('tipo', resp.user.tipo, { path: '/' });

      setError(null)
      dispatch({ type: 'LOGIN_SUCCESS' });
      window.location.reload();

    }
  } else {
    dispatch({ type: "LOGIN_FAILURE" });
    setError(true);
  }
}

function signOut(dispatch, history) {
  const cookies = new Cookies();
  cookies.set('token', '', { path: '/' });
  cookies.set('username', '', { path: '/' });
  cookies.set('id', '', { path: '/' });
  cookies.set('tipo', '', { path: '/' });

  dispatch({ type: "SIGN_OUT_SUCCESS" });
  window.location.href = '/';
  history.push("/login");
}
