import React from "react";
import ReactLoading from "react-loading";
import "./style.css";
import Cookies from "universal-cookie";
import { Form } from "react-bootstrap";
import getUtente from "../services/getUtente"; 
import HeBLayout from "../components/HeBLayout"; 

const cookie = new Cookies();
const username = cookie.get("username", { path: "/" });
const token = cookie.get("token", { path: "/" });
const type = cookie.get("tipo", { path: "/" });
export default class Settings extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			utente: [],
			nome: "",
			cognome: "",
			telefono: "",
			ragione_sociale: "",
			nome_azienda: "",
			p_iva: "",
			codice_univoco: "",
			link_calendly: "",
			link_info_page: "",
			indirizzo: "",
			error: "",
			alertModal: false,
			loaderBtn: false,
			codice_fiscale: "",
		};
	}

	componentDidMount() {
		this.setState({ loading: true });
		this.reload();
		this.setState({ loading: false });
	}

	async reload() {
		await getUtente(username, token).then(array => {
			console.log(array);
			this.setState({
				nome: array.nome,
				cognome: array.cognome,
				telefono: array.telefono,
				ragione_sociale: array.ragione_sociale,
				nome_azienda: array.nome_azienda,
				p_iva: array.p_iva,
				codice_univoco: array.codice_univoco,
				link_calendly: array.link_calendly,
				token_calendly: array.token_calendly,
				email_calendly: array.email_calendly,
				link_info_page: array.link_info_page,
				indirizzo: array.indirizzo,
				codice_fiscale: array.codice_fiscale,
				cap: array.cap,
				city: array.city,
				provincia: array.provincia,
				iban: array.iban,
			});
		});
	}

	render() {
		if (this.state.loading)
			return (
				<HeBLayout>
					<div className="d-flex justify-content-center vh-100">
						<ReactLoading
							type={"bubbles"}
							color={"orange"}
							height={"10%"}
							width={"10%"}
							className="align-self-center"
						/>
					</div>
				</HeBLayout>
			);
		else
			return (
				<HeBLayout>
					<div style={{ width: "90%", marginInline: "auto", overflow: "hidden" }}>
						<div style={{ paddingTop: 30, paddingBottom: 10, overflow: "hidden", width: "100%" }}>
							<div className="">
								<div className="row align-items-center d-flex"> 
									<Form.Group className="mb-3 col-sm-12 col-md-3">
										<Form.Label>Nome</Form.Label>
										<Form.Control
											readOnly
											placeholder={this.state.nome}
											onChange={value => {
												this.setState({ nome: value.target.value });
											}}
											value={this.state.nome}
										/>
									</Form.Group>
									<Form.Group className="mb-3 col-sm-12 col-md-3">
										<Form.Label>Cognome</Form.Label>
										<Form.Control
											readOnly
											placeholder={this.state.cognome}
											onChange={value => {
												this.setState({ cognome: value.target.value });
											}}
											value={this.state.cognome}
										/>
									</Form.Group>
									{
										type !== "3" && (
											<>
											<Form.Group className="mb-3 col-sm-12 col-md-3">
													<Form.Label>Numero di Telefono</Form.Label>
													<Form.Control
														readOnly
														placeholder={this.state.telefono}
														onChange={value => {
															this.setState({ telefono: value.target.value });
														}}
														value={this.state.telefono}
													/>
												</Form.Group>
												<Form.Group className="mb-3 col-sm-12 col-md-3">
													<Form.Label>Ragione Sociale</Form.Label>
													<Form.Control
														readOnly
														placeholder={this.state.ragione_sociale}
														onChange={value => {
															this.setState({ ragione_sociale: value.target.value });
														}}
														value={this.state.ragione_sociale}
													/>
												</Form.Group>
												<Form.Group className="mb-3 col-sm-12 col-md-3">
													<Form.Label>Partita IVA</Form.Label>
													<Form.Control
														readOnly
														placeholder={this.state.p_iva}
														onChange={value => {
															this.setState({ p_iva: value.target.value });
														}}
														value={this.state.p_iva}
													/>
												</Form.Group>
												<Form.Group className="mb-3 col-sm-12 col-md-3 mt-3">
													<Form.Label>Codice Univoco</Form.Label>
													<Form.Control
														readOnly
														placeholder={this.state.codice_univoco}
														onChange={value => {
															this.setState({ codice_univoco: value.target.value });
														}}
														value={this.state.codice_univoco}
													/>
												</Form.Group>
												<Form.Group className="mb-3 col-sm-12 col-md-3">
													<Form.Label>
														Nome Azienda
														<br />
														(da usare nelle conversazioni)
													</Form.Label>
													<Form.Control
														readOnly
														placeholder={this.state.nome_azienda}
														onChange={value => {
															this.setState({ nome_azienda: value.target.value });
														}}
														value={this.state.nome_azienda}
													/>
												</Form.Group>
											</> 
										)
									}
									
									<Form.Group className="mb-3 col-sm-12 col-md-3">
										<Form.Label>Indirizzo</Form.Label>
										<Form.Control
											readOnly
											placeholder={this.state.indirizzo}
											onChange={value => {
												this.setState({ indirizzo: value.target.value });
											}}
											value={this.state.indirizzo}
										/>
									</Form.Group>
									{
										type === "3" && (
											<>
												<Form.Group className="mb-3 col-sm-12 col-md-3">
													<Form.Label>Codice Fiscale</Form.Label>
													<Form.Control
														readOnly 
														style={{ textTransform: "uppercase" }}
														placeholder={this.state.codice_fiscale}
														onChange={value => {
															this.setState({ codice_fiscale: value.target.value });
														}}
														value={this.state.codice_fiscale}
													/>
												</Form.Group>
												<Form.Group className="mb-3 col-sm-12 col-md-3">
													<Form.Label>Cap</Form.Label>
													<Form.Control
														readOnly
														placeholder={this.state.cap}
														onChange={value => {
															this.setState({ cap: value.target.value });
														}}
														value={this.state.cap}
													/>
												</Form.Group>
												<Form.Group className="mb-3 col-sm-12 col-md-3">
													<Form.Label>Citt&aacute;</Form.Label>
													<Form.Control
														readOnly
														placeholder={this.state.city}
														onChange={value => {
															this.setState({ city: value.target.value });
														}}
														value={this.state.city}
													/>
												</Form.Group>
												<Form.Group className="mb-3 col-sm-12 col-md-3">
													<Form.Label>IBAN</Form.Label>
													<Form.Control
														readOnly
														readOnly
														style={{ textTransform: "uppercase" }}
														placeholder={this.state.iban}
														onChange={value => {
															this.setState({ iban: value.target.value });
														}}
														value={this.state.iban}
													/>
												</Form.Group>
											</>
										)
									}
									
									{
										type !== "3" && ( 
											<Form.Group className="mb-3 col-sm-12 col-md-3 mt-3">
												<Form.Label>Link Info Page</Form.Label>
												<Form.Control
													readOnly
													placeholder={this.state.link_info_page}
													onChange={value => {
														this.setState({ link_info_page: value.target.value });
													}}
													value={this.state.link_info_page}
												/>
											</Form.Group> 
										)
									} 
								</div>
							</div>
						</div>
					</div>
				</HeBLayout>
			);
	}
}
