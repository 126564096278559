import React from 'react'  

export default class HomeSecription extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            
        }
    }
    componentDidMount(){
    }
    render() {
        return (
            <>
                <div style={{padding:0}} className="overflow-hidden sezione-100percent infoHome position-relative"> 
                    <div className="col-sm-12 col-md-8 desc col-xxl-8 pe-3 ps-3 w-75">
                        <p style={{color:"black"}} className="pt-3 HeeboThin ">
                        Crediamo fermamente che la creazione di connessioni significative sia la chiave del successo nel mondo del business. Per questo motivo ci siamo dedicati a rendere questo processo non solo più facile, ma anche accessibile a tutti. Utilizzando le tecnologie più avanzate nel campo dell’intelligenza artificiale e unendo la nostra profonda comprensione delle dinamiche dei social network, trasformiamo il tuo profilo LinkedIn in una risorsa inestimabile per la crescita della tua attività. Honey&Bees rappresenta la soluzione definitiva per la generazione di lead B2B su LinkedIn, unendo innovazione, facilità d’uso e risultati concreti.
                        </p>
                    </div>  
                </div>
            </>
        )
    }
}