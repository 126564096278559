import React, { useEffect, useState } from "react";
import Cookies from "universal-cookie";
import ReactLoading from "react-loading";
import "./style.css";
import logoAppius from "../images/h&bdark.png";
import checkEmail from "../services/checkEmail";
import { Card } from "react-bootstrap";
import { Button } from "@mui/material";
import { Typography } from "@material-ui/core";

export default function VerificaEmail(props) {
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    const cookies = new Cookies();
    let username = cookies.get("username", { path: "/" });
    let token = cookies.get("token", { path: "/" });
    if (username && token) {
      window.location.href = "/dashboard";
    }
  }, []);

  const urlParams = new URLSearchParams(window.location.search);
  const uuid = urlParams.get("uuid");

  useEffect(() => {
    const verifyEmail = async () => {
      const res = await checkEmail(uuid);
      if (res.result === "OK") {
        setSuccess(true);
      } else {
        setError("Questo link non è valido");
      }
    };

    if (uuid) {
      verifyEmail();
    } else {
      setError("Questo link non è valido");
    }
  }, [uuid]);

  if (success) {
    return (
      <div className="container-fluid backgroundLogin">
        <div className="d-flex vh-100 justify-content-center align-items-center">
          <Card
            style={{ width: 450, background: "rgba(151, 151, 151, 0.468)" }}
          >
            <Card.Body>
              <div className="w-100 d-flex justify-content-center mb-3">
                <img
                  onClick={() => (window.location.href = "/")}
                  src={logoAppius}
                  alt=""
                  style={{
                    padding: 9,
                    width: "70%",
                    cursor: "pointer",
                    background: "rgb(255 255 255 / 42%)",
                    borderTopRightRadius: 50,
                    borderBottomLeftRadius: 10,
                  }}
                />
              </div>
              <Card.Title className="d-flex flex-column align-items-center text-dark text-center">
                <Typography variant="h2" className="mb-4">
                  Registrazione completata
                </Typography>
                <Typography variant="p" className="mb-4">
                  Ora puoi effettuare il login e utilizzare i servizi di
                  Honey&Bees. <br />
                </Typography>
                <Button
                  className="myButton d-flex w-100"
                  variant="contained"
                  color="primary"
                  onClick={() => (window.location.href = "/login")}
                >
                  Accedi
                </Button>
              </Card.Title>
            </Card.Body>
          </Card>
        </div>
      </div>
    );
  }

  return (
    <div className="container-fluid backgroundLogin">
      <div className="d-flex vh-100 justify-content-center align-items-center">
        {!error && (
          <ReactLoading
            type={"bubbles"}
            color={"orange"}
            height={"10%"}
            width={"10%"}
            className="align-self-center "
          />
        )}
        {error && (
          <div className="alert alert-danger" role="alert">
            {error}
          </div>
        )}
      </div>
    </div>
  );
}
