import React, { useEffect, useState } from 'react'
import Cookies from 'universal-cookie';
import _ from 'lodash';

import searchClientiAll from '../services/searchClientiAll';
import getAppuntiClienti from '../services/getAppuntiClienti';
import addAppuntiClienti from '../services/addAppuntiClienti';
import deleteAppuntiClienti from '../services/deleteAppuntiClienti';
import editAppuntiClienti from '../services/editAppuntiClienti';

import { Button, Modal, Stack } from 'react-bootstrap';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faPlus } from '@fortawesome/free-solid-svg-icons';
import { TextField, List, ListItem, ListItemText, Box, Alert, Snackbar } from '@mui/material';
import HeBLayout from '../components/HeBLayout';

const cookie = new Cookies()
const username = cookie.get('username', { path: "/" })
const token = cookie.get('token', { path: "/" })

const headCells = [
    'url',
    'nome',
    'stato',
    'note',
    'creazione',
    'aggiornamento',
    'azioni'
]

const getDaysDiffFromToday = (date) => {
    const today = new Date()
    const dateToCompare = new Date(date)
    const diffTime = Math.abs(today - dateToCompare)
    const diffHours = Math.ceil(diffTime / (1000 * 60 * 60))
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))
    return (diffHours - 1) < 24 ? diffHours - 1 + ' ore fa' : diffDays + ' giorni fa'
}

const AppuntiClienti = () => {
    const [rows, setRows] = useState([])
    const [showModalAppunto, setShowModalAppunto] = useState(false)
    const [clienti, setClienti] = useState([])
    const [selected, setSelected] = useState(null)
    const [alert, setAlert] = useState({ severity: 'success', message: '' })

    const handleSearchClienti = (search) => {
        if (search.length > 1) { 
            searchClientiAll(username, token, search)
                .then(res => res.result === 'ok' ? setClienti(res.data) : console.log("Errore"))
                .catch(err => console.log(err))
        }
    }
    const handleSearchClientiDebounce = _.debounce(handleSearchClienti, 500)

    const handleaAddReminder = () => {
        if (selected) {
            addAppuntiClienti(username, token, selected.url, selected.nome, selected.stato, selected.note, selected.azienda, selected.id_contatto)
                .then(res => {
                    if (res.result === 'ok') {
                        reload()
                        setShowModalAppunto(false)
                        setClienti([])
                        setSelected(null)
                    } else { console.log("Errore") }
                })
                .catch(err => console.log(err))
        }
    }

    const handleUpdateReminder = (row) => {
        editAppuntiClienti(username, token, row.stato, row.note, row.id)
            .then(res => {
                if (res.result === 'ok') {
                    reload()
                    setAlert({ severity: 'success', message: 'Appunto modificato correttamente' })
                } else {
                    setAlert({ severity: 'error', message: 'Errore durante la modifica dell\'appunto' })
                }
            })
            .catch(err => console.log(err))
    }

    const reload = () => {
        getAppuntiClienti(username, token)
            .then(res => { res.result === 'ok' ? setRows(res.data) : console.log("Errore") })
            .catch(err => console.log(err))
    }

    useEffect(() => {
        if (!showModalAppunto) setClienti([])
    }, [showModalAppunto])

    useEffect(() => {
        reload()
    }, [])

    return (
        <HeBLayout>
            <Snackbar
                open={alert.message !== ''}
                autoHideDuration={2000}
                onClose={() => setAlert({ message: '' })}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                <Alert onClose={() => setAlert({ message: '' })} severity={alert.severity} sx={{ width: '100%' }}>
                    {alert.message}
                </Alert>
            </Snackbar>
            {/* MODAL */}
            <Modal
                centered
                show={showModalAppunto}
                onHide={() => { setShowModalAppunto(false); setClienti([]); setSelected(null) }}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Aggiungi reminder</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {selected === null &&
                        <TextField
                            label="Cerca cliente"
                            variant="outlined"
                            fullWidth
                            onChange={(e) => {
                                handleSearchClientiDebounce(e.target.value.trim())
                            }}
                        />
                    }

                    {clienti.length > 0 && <List className='d-flex flex-column'>
                        {
                            clienti.map((cliente, index) => {
                                return (
                                    <ListItem disablePadding key={'cliente_' + cliente.id} sx={{ paddingLeft: 1, cursor: 'pointer', direction: 'column', display: 'flex' }}>
                                        <ListItemText
                                            primary={cliente.nome}
                                            onClick={() => {
                                                setSelected(cliente)
                                                setClienti([])
                                            }}
                                        />
                                        {index !== clienti.length - 1 && <div style={{ width: '100%', height: 1, backgroundColor: '#cccccc' }} />}
                                    </ListItem>
                                )
                            })
                        }
                    </List>}
                    {selected && clienti.length === 0 && <Box sx={{ width: '100%' }}>
                        <Stack
                            direction="column"
                            sx={{ width: '100%', marginTop: '1rem' }}
                        >
                            <TextField
                                sx={{ width: '100%', marginTop: '1rem' }}
                                label="Nome completo"
                                variant="outlined"
                                inputProps={{ readOnly: true }}
                                value={selected.nome}
                            />
                            <TextField
                                sx={{ width: '100%', marginTop: '1rem' }}
                                label="Stato"
                                variant='outlined'
                                value={selected.stato}
                                onChange={(e) => setSelected({ ...selected, stato: e.target.value })}
                            />
                            <TextField
                                sx={{ width: '100%', marginTop: '1rem' }}
                                label="Note"
                                variant='outlined'
                                multiline
                                rows={4}
                                value={selected.note}
                                onChange={(e) => setSelected({ ...selected, note: e.target.value })}
                            />
                        </Stack>
                    </Box>
                    }

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={() => { setShowModalAppunto(false); setClienti([]); setSelected(null) }}>
                        Chiudi
                    </Button>
                    <Button variant="primary" onClick={handleaAddReminder}>Aggiungi</Button>
                </Modal.Footer>
            </Modal>

            {/* TABELLA */}
            <div style={{ width: '85%' }} className='mt-3 mx-auto d-flex flex-column'>
                <div className='d-flex flex-row-reverse'>
                    <Button
                        variant='primary'
                        className='my-1'
                        onClick={() => setShowModalAppunto(true)}
                    >
                        <FontAwesomeIcon icon={faPlus} />
                    </Button>
                </div>
                <TableContainer component={Paper} >
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                {
                                    headCells.map((headCell) => (
                                        <TableCell key={'headcell_' + headCell} sx={{ fontWeight: 'bold' }} align={['creazione', 'aggiornamento'].includes(headCell) ? 'right' : 'left'}>
                                            {headCell[0].toUpperCase() + headCell.split('').slice(1).join('')}
                                        </TableCell>
                                    ))
                                }
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows.map((row) => (
                                <TableRow
                                    key={row.id}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                        <TextField
                                            label=""
                                            variant="standard"
                                            inputProps={{ readOnly: true }}
                                            value={row.url}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <TextField
                                            label=""
                                            variant="standard"
                                            inputProps={{ readOnly: true }}
                                            value={row.nome_completo}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <TextField
                                            label=""
                                            variant="standard"
                                            defaultValue={row.stato}
                                            onBlur={(e) => {
                                                let row_temp = row
                                                row_temp.stato = e.target.value.trim()
                                                handleUpdateReminder(row_temp)
                                            }}
                                            onKeyDown={(e) => {
                                                if (e.key === 'Enter') {
                                                    let row_temp = row
                                                    row_temp.stato = e.target.value.trim()
                                                    handleUpdateReminder(row_temp)
                                                }
                                            }}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <TextField
                                            label=""
                                            variant="standard"
                                            defaultValue={row.note}
                                            onBlur={(e) => {
                                                let row_temp = row
                                                row_temp.note = e.target.value.trim()
                                                handleUpdateReminder(row_temp)
                                            }}
                                            onKeyDown={(e) => {
                                                if (e.key === 'Enter') {
                                                    let row_temp = row
                                                    row_temp.note = e.target.value.trim()
                                                    handleUpdateReminder(row_temp)
                                                }
                                            }}
                                        />
                                    </TableCell>
                                    <TableCell sx={{ maxWidth: 300 }} align='right'>{getDaysDiffFromToday(row.created_at)}</TableCell>
                                    <TableCell sx={{ maxWidth: 300 }} align='right' >{getDaysDiffFromToday(row.updated_at)}</TableCell>
                                    <TableCell>
                                        <Button
                                            variant='danger'
                                            onClick={() => {
                                                if (window.confirm("Vuoi eliminare l'appunto?")) {
                                                    deleteAppuntiClienti(username, token, row.id)
                                                        .then(res => res.result === 'ok' ? reload() : console.log("Errore"))
                                                        .catch(err => console.log(err))
                                                }
                                            }}
                                        >
                                            <FontAwesomeIcon icon={faTrash} />
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </HeBLayout>
    )

}

export default AppuntiClienti