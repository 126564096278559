import React from "react";
import Cookies from "universal-cookie";
import {
  CircularProgress,
  TextField,
  Snackbar,
  Checkbox,
} from "@material-ui/core";

import getAllAccount from "../services/getAllAccount";
import { Tooltip, Typography } from "@mui/material";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import HeBLayout from "../components/HeBLayout";
import { VMModal } from "../components/admin/VMModal";
import getIP from "../services/getIP"; 
import editIpAddress from "../services/editIpAddress";
import { faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import deleteVM from "../services/deleteVM";

const headCells = [
  {
    id: "ip",
    numeric: false,
    disablePadding: false,
    label: "IP address",
  },
  {
    id: "nome_completo",
    numeric: false,
    disablePadding: false,
    label: "Account assegnato",
  },
  {
    id: "azione",
    numeric: false,
    disablePadding: false,
    label: "Azione",
  },
];

const cookie = new Cookies();
const username = cookie.get("username", { path: "/" });
const token = cookie.get("token", { path: "/" });
const type = cookie.get("tipo", { path: "/" });

export default class VM extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      rows: [],
      filteredRows: [],
      modal: {
        show: false,
      },
      toastAlert: {
        open: false,
        message: "",
        severity: "success",
      },
      filter: false,
    };
  }

  handleEditAccount = async (id) => {
    const row = this.state.rows.find((r) => r.id === id);
    const res = await editIpAddress(username, token, row.ip_address, id); 
    if (res.result === "KO") {
      this.setState({
        toastAlert: {
          open: true,
          message: "Errore durante la modifica dell'account",
          severity: "error",
        },
      });
      return;
    }
    this.setState({
      toastAlert: {
        open: true,
        message: "Account modificato con successo",
        severity: "success",
      },
    });
  };

  handleDeleteVM = async (id) => {
    const res = await deleteVM(username, token, id); 
    if (res.result === "KO") {
      this.setState({
        toastAlert: {
          open: true,
          message: "Errore durante la cancellazione della VM",
          severity: "error",
        },
      });
      return;
    }
    this.setState({
      toastAlert: {
        open: true,
        message: "VM cancellata con successo",
        severity: "success",
      },
    });
    this.reload();
  };

  componentDidMount() {
    this.reload();
  }

  modalOpen = async () => {
    this.setState({ modal: { show: true } });
  };

  modalClose = async () => {
    this.setState({ modal: { show: false } });
    this.reload();
  };

  handleFilter = async () => {
    this.setState({ filter: !this.state.filter });
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.state.filter !== prevState.filter) {
      const { filter, rows } = this.state;
      if (filter) {
        this.setState({
          filteredRows: rows.filter((row) => row.nome_completo === "N/A"),
        });
      } else {
        this.setState({ filteredRows: rows });
      }
    }
  }

  async reload() {
    this.setState({ loading: true });
    const accounts = await getAllAccount(username, token);
    if (accounts["result"] === "KO") {
      this.setState({
        toastAlert: {
          open: true,
          message: "Errore durante il caricamento degli account",
          severity: "error",
        },
      });
      return;
    }
    const ips = await getIP(username, token);
    if (ips["result"] === "KO") {
      this.setState({
        toastAlert: {
          open: true,
          message: "Errore durante il caricamento degli IP",
          severity: "error",
        },
      });
      return;
    }
    const rows = ips.map((ip) => {
      const account = accounts["data"].find(
        (acc) => ip.ip_address === acc.ip_proxy
      );
      return {
        id: ip.id,
        ip_address: ip.ip_address,
        nome_completo: account ? account.nome_completo : "N/A",
      };
    });
    this.setState({ rows, filteredRows: rows, loading: false });
  }

  render() {
    if (this.state.loading) {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <CircularProgress />
        </div>
      );
    }

    const { filteredRows } = this.state;
    const currentRows = filteredRows;

    return (
      <HeBLayout>
        <VMModal open={this.state.modal.show} onClose={this.modalClose} />
        <div
          style={{
            width: "92%",
            marginInline: "auto",
            marginTop: "20px",
            marginBottom: "20px",
            maxHeight: "90%",
            overflowY: "hidden",
          }}
        >
          {/* TOAST ALERT */}
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            open={this.state.toastAlert.open}
            onClose={() =>
              this.setState({
                toastAlert: { open: false, message: "", severity: "success" },
              })
            }
            autoHideDuration={3000}
            key={"topright"}
          >
            <div
              style={{
                backgroundColor:
                  this.state.toastAlert.severity === "success"
                    ? "#4caf50"
                    : "#f44336",
                padding: "6px 12px",
                borderRadius: "4px",
                color: "white",
              }}
            >
              {this.state.toastAlert.message}
            </div>
          </Snackbar>

          {/* SEARCH */}
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div>
              {/* checkbox material ui */}
              <Checkbox value={this.state.filter} onClick={this.handleFilter} />
              <Typography
                style={{ display: "inline-block", marginLeft: "10px" }}
              >
                VM non assegnate
              </Typography>
            </div>
            <Button
              className="myButton"
              variant="contained"
              onClick={this.modalOpen}
            >
              Aggiungi VM
            </Button>
          </div>

          {/* TABLE */}
          <TableContainer
            component={Paper}
            sx={{ overflow: "auto", height: "80vh" }}
          >
            <Table s ped bordered hover responsive>
              <TableHead>
                <TableRow>
                  {headCells.map((headCell) => {
                    if (headCell.admin && type !== "1") return null;
                    return (
                      <TableCell
                        key={headCell.id}
                        style={{ width: headCell.width + "px" }}
                      >
                        {headCell.label}
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>

              <TableBody>
                {currentRows.map((row, i) => (
                  <TableRow key={row.id}>
                    {/* IP */}
                    <TableCell sx={{ padding: "5px 10px" }}>
                      <TextField
                        InputProps={{
                          disableUnderline: true,
                        }}
                        defaultValue={row.ip_address}
                        onChange={(e) => {
                          this.setState({
                            rows: this.state.rows.map((r) => {
                              if (r.id === row.id) {
                                r.ip_address = e.target.value;
                              }
                              return r;
                            }),
                          });
                        }}
                        onBlur={async () => {
                          if (!row.new) {
                            await this.handleEditAccount(row.id);
                          }
                        }}
                        onKeyPress={async (e) => {
                          if (e.key === "Enter" && !row.new) {
                            await this.handleEditAccount(row.id);
                          }
                        }}
                      />
                    </TableCell>

                    {/* NOME COMPLETO */}
                    <TableCell sx={{ padding: "5px 10px" }}>
                      <TextField
                        InputProps={{
                          disableUnderline: true,
                          readOnly: true,
                        }}
                        sx={{ width: "fit-content" }}
                        defaultValue={row.nome_completo}
                      />
                    </TableCell>

                    <TableCell sx={{ padding: "5px 10px" }}>
                      <Tooltip title="Elimina VM" placement="top">
                        <Button
                          onClick={() =>
                            window.confirm(
                              `Sei sicuro di voler eliminare la VM ${row.ip_address}?`
                            )
                              ? this.handleDeleteVM(row.id)
                              : null
                          }
                          variant="text"
                          color="inherit"
                        >
                          <FontAwesomeIcon size={"md"} icon={faTrashCan} />
                        </Button>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </HeBLayout>
    );
  }
}
