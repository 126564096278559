import React, { useEffect, useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import getMessaggiCall from "../../services/getMessaggiCall";
import Cookies from "universal-cookie";

const cookie = new Cookies();
const username = cookie.get("username", { path: "/" });
const token = cookie.get("token", { path: "/" });

const ConversationDialog = ({ modal, onCancel }) => {
    const [messaggi, setMessaggi] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            if (modal.show != null) {
                const messaggi = await getMessaggiCall(username, token, modal.id);
                setMessaggi(messaggi);
            }
        };

        fetchData();
    }, [modal.id, modal.show]);

    const getMessageStyle = (type) => ({
        padding: "8px",
        margin: "8px 0",
        borderRadius: "4px",
        border: "1px solid #ddd",
        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
        backgroundColor: type === "question" ? "#f9f9f9" : "#dff9d8",
        textAlign: type === "question" ? "left" : "right",
    });

    return (
        <Dialog open={Boolean(modal.show)} onClose={onCancel}>
            <DialogTitle>Conversazione</DialogTitle>
            <DialogContent>
                {messaggi.map((messaggio) => (
                    <div key={messaggio.id} style={getMessageStyle(messaggio.type)}>
                        <DialogContentText>{messaggio.type === "question" ? "Domanda:" : "Risposta:"}</DialogContentText>
                        <DialogContentText>{messaggio.messaggio}</DialogContentText>
                    </div>
                ))}
            </DialogContent>
            <DialogActions>
                <Button className="myButton" onClick={onCancel} color="primary">
                    Chiudi
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ConversationDialog;
