import React from "react";
import ReactLoading from "react-loading";
import Cookies from "universal-cookie";
import ListeTable from "../components/admin/ListeTable";
import getUtenti from "../services/getUtenti";
import HeBLayout from "../components/HeBLayout";

const cookies = new Cookies();
const username = cookies.get("username", { path: "/" });
const token = cookies.get("token", { path: "/" });
let type = cookies.get("tipo", { path: "/" });

const Liste = () => {
	const [loading, setLoading] = React.useState(true);
	const [users, setUsers] = React.useState([]);

	const getAndSetUser = async (username, token) => {
		const users = await getUtenti(username, token);
		if(users.profili.length > 0) {
			setUsers(users.profili);
		}
	};

	React.useEffect(() => {
		if(type === '1') {
			getAndSetUser(username, token);
		}
		setLoading(false);
	}, []);

	if (loading) {
		return (
			<HeBLayout>
				<div className="d-flex justify-content-center vh-100">
					<ReactLoading
						type={"bubbles"}
						color={"orange"}
						height={"10%"}
						width={"10%"}
						className="align-self-center "
					/>
				</div>
			</HeBLayout>
		);
	} else {
		return (
			<HeBLayout>
				<ListeTable username={username} token={token} users={users} />
			</HeBLayout>
		);
	}
};

export default Liste;
