import React from "react";

export default class Constants extends React.Component {
	static apisFolder = "/apis_test_esterni/";
	static ipAddress = "80.211.130.235";
	static mainUrl = "80.211.130.235" + Constants.apisFolder;
	static basicUrl = "80.211.130.235/";
	static http = require("follow-redirects").http;
	static apisFolder = "/api/";
	static ipAddress = "honeyandbees.cloud";
	static mainUrl = "honeyandbees.cloud" + Constants.apisFolder;
	static basicUrl = "honeyandbees.cloud/";
	static http = require("follow-redirects").https;

	static generateUserToken() {
		var result = "";
		var characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
		var charactersLength = characters.length;
		for (var i = 0; i < 20; i++) {
			result += characters.charAt(Math.floor(Math.random() * charactersLength));
		}
		return result;
	}
	static generateUserToken() {
		var result = "";
		var characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
		var charactersLength = characters.length;
		for (var i = 0; i < 20; i++) {
			result += characters.charAt(Math.floor(Math.random() * charactersLength));
		}
		return result;
	}

	static getPrenotazione(data, ora, nome, email) {
		var prenotazione =
			"" +
			'<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Strict//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-strict.dtd">' +
			"<html>" +
			"  <head>" +
			"    <!-- Compiled with Bootstrap Email version: 1.3.1 -->" +
			'    <meta http-equiv="Content-Type" content="text/html; charset=utf-8">' +
			'    <meta http-equiv="x-ua-compatible" content="ie=edge">' +
			'    <meta name="x-apple-disable-message-reformatting">' +
			'    <meta name="viewport" content="width=device-width, initial-scale=1">' +
			'    <meta name="format-detection" content="telephone=no, date=no, address=no, email=no">' +
			'    <style type="text/css">' +
			"      body,table,td{font-family:Helvetica,Arial,sans-serif !important}.ExternalClass{width:100%}.ExternalClass,.ExternalClass p,.ExternalClass span,.ExternalClass font,.ExternalClass td,.ExternalClass div{line-height:150%}a{text-decoration:none}*{color:inherit}a[x-apple-data-detectors],u+#body a,#MessageViewBody a{color:inherit;text-decoration:none;font-size:inherit;font-family:inherit;font-weight:inherit;line-height:inherit}img{-ms-interpolation-mode:bicubic}table:not([class^=s-]){font-family:Helvetica,Arial,sans-serif;mso-table-lspace:0pt;mso-table-rspace:0pt;border-spacing:0px;border-collapse:collapse}table:not([class^=s-]) td{border-spacing:0px;border-collapse:collapse}@media screen and (max-width: 600px){.w-full,.w-full>tbody>tr>td{width:100% !important}.w-24,.w-24>tbody>tr>td{width:250px !important}.w-40,.w-40>tbody>tr>td{width:160px !important}.p-lg-10:not(table),.p-lg-10:not(.btn)>tbody>tr>td,.p-lg-10.btn td a{padding:0 !important}.p-3:not(table),.p-3:not(.btn)>tbody>tr>td,.p-3.btn td a{padding:12px !important}.p-6:not(table),.p-6:not(.btn)>tbody>tr>td,.p-6.btn td a{padding:24px !important}*[class*=s-lg-]>tbody>tr>td{font-size:0 !important;line-height:0 !important;height:0 !important}.s-4>tbody>tr>td{font-size:16px !important;line-height:16px !important;height:16px !important}.s-6>tbody>tr>td{font-size:24px !important;line-height:24px !important;height:24px !important}.s-10>tbody>tr>td{font-size:40px !important;line-height:40px !important;height:40px !important}}" +
			"    </style>" +
			"  </head>" +
			'  <body class="bg-light" style="outline: 0; width: 100%; min-width: 100%; height: 100%; -webkit-text-size-adjust: 100%; -ms-text-size-adjust: 100%; font-family: Helvetica, Arial, sans-serif; line-height: 24px; font-weight: normal; font-size: 16px; -moz-box-sizing: border-box; -webkit-box-sizing: border-box; box-sizing: border-box; color: #000000; margin: 0; padding: 0; border-width: 0;" bgcolor="#edece1">' +
			'    <table class="bg-light body" valign="top" role="presentation" border="0" cellpadding="0" cellspacing="0" style="outline: 0; width: 100%; min-width: 100%; height: 100%; -webkit-text-size-adjust: 100%; -ms-text-size-adjust: 100%; font-family: Helvetica, Arial, sans-serif; line-height: 24px; font-weight: normal; font-size: 16px; -moz-box-sizing: border-box; -webkit-box-sizing: border-box; box-sizing: border-box; color: rgb(24, 7, 113); margin: 0; padding: 0; border-width: 0;" bgcolor="#edece1">' +
			"      <tbody>" +
			"        <tr>" +
			'          <td valign="top" style="line-height: 24px; font-size: 16px; margin: 0;" align="left" bgcolor="#edece1">' +
			'            <table class="container" role="presentation" border="0" cellpadding="0" cellspacing="0" style="width: 100%;">' +
			"              <tbody>" +
			"                <tr>" +
			'                  <td align="center" style="line-height: 24px; font-size: 16px; margin: 0; padding: 0 16px; background-color: #fff; height: 100vh;">' +
			"                    <!--[if (gte mso 9)|(IE)]>" +
			'                      <table align="center" role="presentation">' +
			"                        <tbody>" +
			"                          <tr>" +
			'                            <td width="600">' +
			"                    <![endif]-->" +
			'                    <table align="center" role="presentation" border="0" cellpadding="0" cellspacing="0" style="width: 100%; max-width: 600px; margin: 0 auto;">' +
			"                      <tbody>" +
			"                        <tr>" +
			'                          <td style="line-height: 24px; font-size: 16px; margin: 0;" align="left" >' +
			'                            <table class="s-10 w-full" role="presentation" border="0" cellpadding="0" cellspacing="0" style="width: 100%;" width="100%">' +
			"                              <tbody>" +
			"                                <tr>" +
			'                                  <td style="line-height: 40px; font-size: 40px; width: 100%; height: 40px; margin: 0;" align="left" width="100%" height="40">' +
			"                                    &#160;" +
			"                                  </td>" +
			"                                </tr>" +
			"                              </tbody>" +
			"                            </table>" +
			'                            <table class="ax-center" role="presentation" align="center" border="0" cellpadding="0" cellspacing="0" style="margin: 0 auto;">' +
			"                              <tbody>" +
			"                                <tr>" +
			'                                  <td style="line-height: 24px; font-size: 16px; margin: 0;" align="left">' +
			'                                    <img class="w-24" src="http://195.231.38.199/static/media/h&bdark.5ff8b3a3.png" style="height: auto; line-height: 100%; outline: none; text-decoration: none; display: block; width: 230px; border-style: none; border-width: 0;" width="96">' +
			"                                  </td>" +
			"                                </tr>" +
			"                              </tbody>" +
			"                            </table>" +
			'                            <table class="s-10 w-full" role="presentation" border="0" cellpadding="0" cellspacing="0" style="width: 100%;" width="100%">' +
			"                              <tbody>" +
			"                                <tr>" +
			'                                  <td style="line-height: 40px; font-size: 40px; width: 100%; height: 40px; margin: 0;" align="left" width="100%" height="40">' +
			"                                    &#160;" +
			"                                  </td>" +
			"                                </tr>" +
			"                              </tbody>" +
			"                            </table>" +
			'                            <table class="card p-6 p-lg-10 space-y-4" role="presentation" border="0" cellpadding="0" cellspacing="0" style="border-radius: 25px; border-collapse: separate !important; width: 100%; overflow: hidden; border: 1px solid #95927a7d;box-shadow: 0 5px 15px rgba(0,0,0,.35);" bgcolor="#ffffff">' +
			"                              <tbody>" +
			"                                <tr>" +
			'                                  <td style="line-height: 24px; font-size: 16px; width: 100%; margin: 0; padding: 40px; background-color: #95927a7d;" align="left" >' +
			'                                    <h1 class="h3 fw-700" style="padding-top: 0; padding-bottom: 0; font-weight: 700 !important; vertical-align: baseline; font-size: 28px; line-height: 33.6px; margin: 0;" align="left">' +
			"                                      Conferma prenotazione" +
			"                                    </h1>" +
			'                                    <table class="s-4 w-full" role="presentation" border="0" cellpadding="0" cellspacing="0" style="width: 100%;" width="100%">' +
			"                                      <tbody>" +
			"                                        <tr>" +
			'                                          <td style="line-height: 16px; font-size: 16px; width: 100%; height: 16px; margin: 0;" align="left" width="100%" height="16">' +
			"                                            &#160;" +
			"                                          </td>" +
			"                                        </tr>" +
			"                                      </tbody>" +
			"                                    </table>" +
			'                                    <p class="" style="line-height: 24px; font-size: 16px; width: 100%; margin: 0;" align="left">' +
			"                                      Grazie per aver scelto i servizi di consulenza di Honey&Bees. Siamo lieti di confermare la vostra prenotazione." +
			"                                    </p>" +
			'                                    <table class="s-4 w-full" role="presentation" border="0" cellpadding="0" cellspacing="0" style="width: 100%;" width="100%">' +
			"                                      <tbody>" +
			"                                        <tr>" +
			'                                          <td style="line-height: 16px; font-size: 16px; width: 100%; height: 16px; margin: 0;" align="left" width="100%" height="16">' +
			"                                            &#160;" +
			"                                          </td>" +
			"                                        </tr>" +
			"                                      </tbody>" +
			"                                    </table>" +
			'                                    <table class="s-4 w-full" role="presentation" border="0" cellpadding="0" cellspacing="0" style="width: 100%;" width="100%">' +
			"                                      <tbody>" +
			"                                        <tr>" +
			'                                          <td style="line-height: 16px; font-size: 16px; width: 100%; height: 16px; margin: 0;" align="left" width="100%" height="16">' +
			"                                            &#160;" +
			"                                          </td>" +
			"                                        </tr>" +
			"                                      </tbody>" +
			"                                    </table>" +
			"                                    <div>" +
			"                                      <h4>Dettagli della Prenotazione:</h4>" +
			"                                      <ul>" +
			"                                        <li>" +
			"                                          Data e Ora: " +
			data +
			" " +
			ora +
			"                                        </li>" +
			"                                        <li>" +
			"                                          Nome: " +
			nome +
			"                                        </li>" +
			"                                        <li>" +
			"                                          Email: " +
			email +
			"                                        </li>" +
			"                                      </ul>" +
			"                                    </div>" +
			'                                    <table class="s-4 w-full" role="presentation" border="0" cellpadding="0" cellspacing="0" style="width: 100%;" width="100%">' +
			"                                      <tbody>" +
			"                                        <tr>" +
			'                                          <td style="line-height: 16px; font-size: 16px; width: 100%; height: 16px; margin: 0;" align="left" width="100%" height="16">' +
			"                                            &#160;" +
			"                                          </td>" +
			"                                        </tr>" +
			"                                      </tbody>" +
			"                                    </table>" +
			"                                  </td>" +
			"                                </tr>" +
			"                              </tbody>" +
			"                            </table>" +
			'                            <table class="s-10 w-full" role="presentation" border="0" cellpadding="0" cellspacing="0" style="width: 100%;" width="100%">' +
			"                              <tbody>" +
			"                                <tr>" +
			'                                  <td style="line-height: 40px; font-size: 40px; width: 100%; height: 40px; margin: 0;" align="left" width="100%" height="40">' +
			"                                    &#160;" +
			"                                  </td>" +
			"                                </tr>" +
			"                              </tbody>" +
			"                            </table> " +
			'                            <table class="s-6 w-full" role="presentation" border="0" cellpadding="0" cellspacing="0" style="width: 100%;" width="100%">' +
			"                              <tbody>" +
			"                                <tr>" +
			'                                  <td style="line-height: 24px; font-size: 24px; width: 100%; height: 24px; margin: 0;" align="left" width="100%" height="24">' +
			"                                    &#160;" +
			"                                  </td>" +
			"                                </tr>" +
			"                              </tbody>" +
			"                            </table>" +
			'                            <div class="text-muted" style="color: rgb(24, 7, 113); font-size: 14px;display: flex; justify-content: space-around;" align="center" >' +
			'                              <div class="mb-0" style="margin-top: 0; margin-bottom: 0;">' +
			"                                  <small>" +
			'                                  <a href="https://honeyandbees.it/privacy-policy">Privacy Policy</a>' +
			"                                  </small>" +
			"                              </div>" +
			'                              <div class="mb-0" style="margin-top: 0; margin-bottom: 0;">' +
			"                                  <small>" +
			'                                      <a href="https://honeyandbees.it/cookie-policy">Cookie Policy</a>' +
			"                                  </small>" +
			"                              </div>" +
			'                              <div class="mb-0" style="margin-top: 0; margin-bottom: 0;">' +
			"                                  <small>" +
			'                                      <a href="https://honeyandbees.it/termini-e-condizioni">Termini e Condizioni</a>' +
			"                                  </small>" +
			"                              </div>" +
			"                          </div> " +
			'                          <div class="text-muted" style="color: rgb(24, 7, 113); font-size: 14px;display: flex; justify-content: space-around;" align="center" >' +
			"                              <p>© 2023 APPIUS SRLS - Fiorano Modenese(MO) P.IVA 03898560366 - Cap.soc. 3000€ i.v.</p>" +
			"                          </div>" +
			'                            <table class="s-6 w-full" role="presentation" border="0" cellpadding="0" cellspacing="0" style="width: 100%;" width="100%">' +
			"                              <tbody>" +
			"                                <tr>" +
			'                                  <td style="line-height: 24px; font-size: 24px; width: 100%; height: 24px; margin: 0;" align="left" width="100%" height="24">' +
			"                                    &#160;" +
			"                                  </td>" +
			"                                </tr>" +
			"                              </tbody>" +
			"                            </table>" +
			"                          </td>" +
			"                        </tr>" +
			"                      </tbody>" +
			"                    </table>" +
			"                    <!--[if (gte mso 9)|(IE)]>" +
			"                    </td>" +
			"                  </tr>" +
			"                </tbody>" +
			"              </table>" +
			"                    <![endif]-->" +
			"                  </td>" +
			"                </tr>" +
			"              </tbody>" +
			"            </table>" +
			"          </td>" +
			"        </tr>" +
			"      </tbody>" +
			"    </table>" +
			"  </body>" +
			"</html>" +
			"" +
			"";
		return prenotazione;
	}
	static getPrenotazione(data, ora, nome, email) {
		var prenotazione =
			"" +
			'<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Strict//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-strict.dtd">' +
			"<html>" +
			"  <head>" +
			"    <!-- Compiled with Bootstrap Email version: 1.3.1 -->" +
			'    <meta http-equiv="Content-Type" content="text/html; charset=utf-8">' +
			'    <meta http-equiv="x-ua-compatible" content="ie=edge">' +
			'    <meta name="x-apple-disable-message-reformatting">' +
			'    <meta name="viewport" content="width=device-width, initial-scale=1">' +
			'    <meta name="format-detection" content="telephone=no, date=no, address=no, email=no">' +
			'    <style type="text/css">' +
			"      body,table,td{font-family:Helvetica,Arial,sans-serif !important}.ExternalClass{width:100%}.ExternalClass,.ExternalClass p,.ExternalClass span,.ExternalClass font,.ExternalClass td,.ExternalClass div{line-height:150%}a{text-decoration:none}*{color:inherit}a[x-apple-data-detectors],u+#body a,#MessageViewBody a{color:inherit;text-decoration:none;font-size:inherit;font-family:inherit;font-weight:inherit;line-height:inherit}img{-ms-interpolation-mode:bicubic}table:not([class^=s-]){font-family:Helvetica,Arial,sans-serif;mso-table-lspace:0pt;mso-table-rspace:0pt;border-spacing:0px;border-collapse:collapse}table:not([class^=s-]) td{border-spacing:0px;border-collapse:collapse}@media screen and (max-width: 600px){.w-full,.w-full>tbody>tr>td{width:100% !important}.w-24,.w-24>tbody>tr>td{width:250px !important}.w-40,.w-40>tbody>tr>td{width:160px !important}.p-lg-10:not(table),.p-lg-10:not(.btn)>tbody>tr>td,.p-lg-10.btn td a{padding:0 !important}.p-3:not(table),.p-3:not(.btn)>tbody>tr>td,.p-3.btn td a{padding:12px !important}.p-6:not(table),.p-6:not(.btn)>tbody>tr>td,.p-6.btn td a{padding:24px !important}*[class*=s-lg-]>tbody>tr>td{font-size:0 !important;line-height:0 !important;height:0 !important}.s-4>tbody>tr>td{font-size:16px !important;line-height:16px !important;height:16px !important}.s-6>tbody>tr>td{font-size:24px !important;line-height:24px !important;height:24px !important}.s-10>tbody>tr>td{font-size:40px !important;line-height:40px !important;height:40px !important}}" +
			"    </style>" +
			"  </head>" +
			'  <body class="bg-light" style="outline: 0; width: 100%; min-width: 100%; height: 100%; -webkit-text-size-adjust: 100%; -ms-text-size-adjust: 100%; font-family: Helvetica, Arial, sans-serif; line-height: 24px; font-weight: normal; font-size: 16px; -moz-box-sizing: border-box; -webkit-box-sizing: border-box; box-sizing: border-box; color: #000000; margin: 0; padding: 0; border-width: 0;" bgcolor="#edece1">' +
			'    <table class="bg-light body" valign="top" role="presentation" border="0" cellpadding="0" cellspacing="0" style="outline: 0; width: 100%; min-width: 100%; height: 100%; -webkit-text-size-adjust: 100%; -ms-text-size-adjust: 100%; font-family: Helvetica, Arial, sans-serif; line-height: 24px; font-weight: normal; font-size: 16px; -moz-box-sizing: border-box; -webkit-box-sizing: border-box; box-sizing: border-box; color: rgb(24, 7, 113); margin: 0; padding: 0; border-width: 0;" bgcolor="#edece1">' +
			"      <tbody>" +
			"        <tr>" +
			'          <td valign="top" style="line-height: 24px; font-size: 16px; margin: 0;" align="left" bgcolor="#edece1">' +
			'            <table class="container" role="presentation" border="0" cellpadding="0" cellspacing="0" style="width: 100%;">' +
			"              <tbody>" +
			"                <tr>" +
			'                  <td align="center" style="line-height: 24px; font-size: 16px; margin: 0; padding: 0 16px; background-color: #fff; height: 100vh;">' +
			"                    <!--[if (gte mso 9)|(IE)]>" +
			'                      <table align="center" role="presentation">' +
			"                        <tbody>" +
			"                          <tr>" +
			'                            <td width="600">' +
			"                    <![endif]-->" +
			'                    <table align="center" role="presentation" border="0" cellpadding="0" cellspacing="0" style="width: 100%; max-width: 600px; margin: 0 auto;">' +
			"                      <tbody>" +
			"                        <tr>" +
			'                          <td style="line-height: 24px; font-size: 16px; margin: 0;" align="left" >' +
			'                            <table class="s-10 w-full" role="presentation" border="0" cellpadding="0" cellspacing="0" style="width: 100%;" width="100%">' +
			"                              <tbody>" +
			"                                <tr>" +
			'                                  <td style="line-height: 40px; font-size: 40px; width: 100%; height: 40px; margin: 0;" align="left" width="100%" height="40">' +
			"                                    &#160;" +
			"                                  </td>" +
			"                                </tr>" +
			"                              </tbody>" +
			"                            </table>" +
			'                            <table class="ax-center" role="presentation" align="center" border="0" cellpadding="0" cellspacing="0" style="margin: 0 auto;">' +
			"                              <tbody>" +
			"                                <tr>" +
			'                                  <td style="line-height: 24px; font-size: 16px; margin: 0;" align="left">' +
			'                                    <img class="w-24" src="http://195.231.38.199/static/media/h&bdark.5ff8b3a3.png" style="height: auto; line-height: 100%; outline: none; text-decoration: none; display: block; width: 230px; border-style: none; border-width: 0;" width="96">' +
			"                                  </td>" +
			"                                </tr>" +
			"                              </tbody>" +
			"                            </table>" +
			'                            <table class="s-10 w-full" role="presentation" border="0" cellpadding="0" cellspacing="0" style="width: 100%;" width="100%">' +
			"                              <tbody>" +
			"                                <tr>" +
			'                                  <td style="line-height: 40px; font-size: 40px; width: 100%; height: 40px; margin: 0;" align="left" width="100%" height="40">' +
			"                                    &#160;" +
			"                                  </td>" +
			"                                </tr>" +
			"                              </tbody>" +
			"                            </table>" +
			'                            <table class="card p-6 p-lg-10 space-y-4" role="presentation" border="0" cellpadding="0" cellspacing="0" style="border-radius: 25px; border-collapse: separate !important; width: 100%; overflow: hidden; border: 1px solid #95927a7d;box-shadow: 0 5px 15px rgba(0,0,0,.35);" bgcolor="#ffffff">' +
			"                              <tbody>" +
			"                                <tr>" +
			'                                  <td style="line-height: 24px; font-size: 16px; width: 100%; margin: 0; padding: 40px; background-color: #95927a7d;" align="left" >' +
			'                                    <h1 class="h3 fw-700" style="padding-top: 0; padding-bottom: 0; font-weight: 700 !important; vertical-align: baseline; font-size: 28px; line-height: 33.6px; margin: 0;" align="left">' +
			"                                      Conferma prenotazione" +
			"                                    </h1>" +
			'                                    <table class="s-4 w-full" role="presentation" border="0" cellpadding="0" cellspacing="0" style="width: 100%;" width="100%">' +
			"                                      <tbody>" +
			"                                        <tr>" +
			'                                          <td style="line-height: 16px; font-size: 16px; width: 100%; height: 16px; margin: 0;" align="left" width="100%" height="16">' +
			"                                            &#160;" +
			"                                          </td>" +
			"                                        </tr>" +
			"                                      </tbody>" +
			"                                    </table>" +
			'                                    <p class="" style="line-height: 24px; font-size: 16px; width: 100%; margin: 0;" align="left">' +
			"                                      Grazie per aver scelto i servizi di consulenza di Honey&Bees. Siamo lieti di confermare la vostra prenotazione." +
			"                                    </p>" +
			'                                    <table class="s-4 w-full" role="presentation" border="0" cellpadding="0" cellspacing="0" style="width: 100%;" width="100%">' +
			"                                      <tbody>" +
			"                                        <tr>" +
			'                                          <td style="line-height: 16px; font-size: 16px; width: 100%; height: 16px; margin: 0;" align="left" width="100%" height="16">' +
			"                                            &#160;" +
			"                                          </td>" +
			"                                        </tr>" +
			"                                      </tbody>" +
			"                                    </table>" +
			'                                    <table class="s-4 w-full" role="presentation" border="0" cellpadding="0" cellspacing="0" style="width: 100%;" width="100%">' +
			"                                      <tbody>" +
			"                                        <tr>" +
			'                                          <td style="line-height: 16px; font-size: 16px; width: 100%; height: 16px; margin: 0;" align="left" width="100%" height="16">' +
			"                                            &#160;" +
			"                                          </td>" +
			"                                        </tr>" +
			"                                      </tbody>" +
			"                                    </table>" +
			"                                    <div>" +
			"                                      <h4>Dettagli della Prenotazione:</h4>" +
			"                                      <ul>" +
			"                                        <li>" +
			"                                          Data e Ora: " +
			data +
			" " +
			ora +
			"                                        </li>" +
			"                                        <li>" +
			"                                          Nome: " +
			nome +
			"                                        </li>" +
			"                                        <li>" +
			"                                          Email: " +
			email +
			"                                        </li>" +
			"                                      </ul>" +
			"                                    </div>" +
			'                                    <table class="s-4 w-full" role="presentation" border="0" cellpadding="0" cellspacing="0" style="width: 100%;" width="100%">' +
			"                                      <tbody>" +
			"                                        <tr>" +
			'                                          <td style="line-height: 16px; font-size: 16px; width: 100%; height: 16px; margin: 0;" align="left" width="100%" height="16">' +
			"                                            &#160;" +
			"                                          </td>" +
			"                                        </tr>" +
			"                                      </tbody>" +
			"                                    </table>" +
			"                                  </td>" +
			"                                </tr>" +
			"                              </tbody>" +
			"                            </table>" +
			'                            <table class="s-10 w-full" role="presentation" border="0" cellpadding="0" cellspacing="0" style="width: 100%;" width="100%">' +
			"                              <tbody>" +
			"                                <tr>" +
			'                                  <td style="line-height: 40px; font-size: 40px; width: 100%; height: 40px; margin: 0;" align="left" width="100%" height="40">' +
			"                                    &#160;" +
			"                                  </td>" +
			"                                </tr>" +
			"                              </tbody>" +
			"                            </table> " +
			'                            <table class="s-6 w-full" role="presentation" border="0" cellpadding="0" cellspacing="0" style="width: 100%;" width="100%">' +
			"                              <tbody>" +
			"                                <tr>" +
			'                                  <td style="line-height: 24px; font-size: 24px; width: 100%; height: 24px; margin: 0;" align="left" width="100%" height="24">' +
			"                                    &#160;" +
			"                                  </td>" +
			"                                </tr>" +
			"                              </tbody>" +
			"                            </table>" +
			'                            <div class="text-muted" style="color: rgb(24, 7, 113); font-size: 14px;display: flex; justify-content: space-around;" align="center" >' +
			'                              <div class="mb-0" style="margin-top: 0; margin-bottom: 0;">' +
			"                                  <small>" +
			'                                  <a href="https://honeyandbees.it/privacy-policy">Privacy Policy</a>' +
			"                                  </small>" +
			"                              </div>" +
			'                              <div class="mb-0" style="margin-top: 0; margin-bottom: 0;">' +
			"                                  <small>" +
			'                                      <a href="https://honeyandbees.it/cookie-policy">Cookie Policy</a>' +
			"                                  </small>" +
			"                              </div>" +
			'                              <div class="mb-0" style="margin-top: 0; margin-bottom: 0;">' +
			"                                  <small>" +
			'                                      <a href="https://honeyandbees.it/termini-e-condizioni">Termini e Condizioni</a>' +
			"                                  </small>" +
			"                              </div>" +
			"                          </div> " +
			'                          <div class="text-muted" style="color: rgb(24, 7, 113); font-size: 14px;display: flex; justify-content: space-around;" align="center" >' +
			"                              <p>© 2023 APPIUS SRLS - Fiorano Modenese(MO) P.IVA 03898560366 - Cap.soc. 3000€ i.v.</p>" +
			"                          </div>" +
			'                            <table class="s-6 w-full" role="presentation" border="0" cellpadding="0" cellspacing="0" style="width: 100%;" width="100%">' +
			"                              <tbody>" +
			"                                <tr>" +
			'                                  <td style="line-height: 24px; font-size: 24px; width: 100%; height: 24px; margin: 0;" align="left" width="100%" height="24">' +
			"                                    &#160;" +
			"                                  </td>" +
			"                                </tr>" +
			"                              </tbody>" +
			"                            </table>" +
			"                          </td>" +
			"                        </tr>" +
			"                      </tbody>" +
			"                    </table>" +
			"                    <!--[if (gte mso 9)|(IE)]>" +
			"                    </td>" +
			"                  </tr>" +
			"                </tbody>" +
			"              </table>" +
			"                    <![endif]-->" +
			"                  </td>" +
			"                </tr>" +
			"              </tbody>" +
			"            </table>" +
			"          </td>" +
			"        </tr>" +
			"      </tbody>" +
			"    </table>" +
			"  </body>" +
			"</html>" +
			"" +
			"";
		return prenotazione;
	}

	static getAcquisto(chiamate, importo, data) {
		// format data to dd/mm/yyyy hh/mm
		var date = new Date(data);
		var day = date.getDate();
		var month = date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : "" + (date.getMonth() + 1);
		var year = date.getFullYear();
		var hours = date.getHours() < 10 ? "0" + date.getHours() : "" + date.getHours();
		var minutes = date.getMinutes() < 10 ? "0" + date.getMinutes() : "" + date.getMinutes();

		const dataFormatted = day + "/" + month + "/" + year + " " + hours + ":" + minutes;

		let number_importo = parseFloat(importo);
		let format_importo = number_importo.toFixed(2);

		var acquisto =
			"" +
			'<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Strict//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-strict.dtd">' +
			"<html>" +
			"  <head>" +
			"    <!-- Compiled with Bootstrap Email version: 1.3.1 -->" +
			'    <meta http-equiv="Content-Type" content="text/html; charset=utf-8">' +
			'    <meta http-equiv="x-ua-compatible" content="ie=edge">' +
			'    <meta name="x-apple-disable-message-reformatting">' +
			'    <meta name="viewport" content="width=device-width, initial-scale=1">' +
			'    <meta name="format-detection" content="telephone=no, date=no, address=no, email=no">' +
			'    <style type="text/css">' +
			"      body,table,td{font-family:Helvetica,Arial,sans-serif !important}.ExternalClass{width:100%}.ExternalClass,.ExternalClass p,.ExternalClass span,.ExternalClass font,.ExternalClass td,.ExternalClass div{line-height:150%}a{text-decoration:none}*{color:inherit}a[x-apple-data-detectors],u+#body a,#MessageViewBody a{color:inherit;text-decoration:none;font-size:inherit;font-family:inherit;font-weight:inherit;line-height:inherit}img{-ms-interpolation-mode:bicubic}table:not([class^=s-]){font-family:Helvetica,Arial,sans-serif;mso-table-lspace:0pt;mso-table-rspace:0pt;border-spacing:0px;border-collapse:collapse}table:not([class^=s-]) td{border-spacing:0px;border-collapse:collapse}@media screen and (max-width: 600px){.w-full,.w-full>tbody>tr>td{width:100% !important}.w-24,.w-24>tbody>tr>td{width:250px !important}.w-40,.w-40>tbody>tr>td{width:160px !important}.p-lg-10:not(table),.p-lg-10:not(.btn)>tbody>tr>td,.p-lg-10.btn td a{padding:0 !important}.p-3:not(table),.p-3:not(.btn)>tbody>tr>td,.p-3.btn td a{padding:12px !important}.p-6:not(table),.p-6:not(.btn)>tbody>tr>td,.p-6.btn td a{padding:24px !important}*[class*=s-lg-]>tbody>tr>td{font-size:0 !important;line-height:0 !important;height:0 !important}.s-4>tbody>tr>td{font-size:16px !important;line-height:16px !important;height:16px !important}.s-6>tbody>tr>td{font-size:24px !important;line-height:24px !important;height:24px !important}.s-10>tbody>tr>td{font-size:40px !important;line-height:40px !important;height:40px !important}}" +
			"    </style>" +
			"  </head>" +
			'  <body class="bg-light" style="outline: 0; width: 100%; min-width: 100%; height: 100%; -webkit-text-size-adjust: 100%; -ms-text-size-adjust: 100%; font-family: Helvetica, Arial, sans-serif; line-height: 24px; font-weight: normal; font-size: 16px; -moz-box-sizing: border-box; -webkit-box-sizing: border-box; box-sizing: border-box; color: #000000; margin: 0; padding: 0; border-width: 0;" bgcolor="#edece1">' +
			'    <table class="bg-light body" valign="top" role="presentation" border="0" cellpadding="0" cellspacing="0" style="outline: 0; width: 100%; min-width: 100%; height: 100%; -webkit-text-size-adjust: 100%; -ms-text-size-adjust: 100%; font-family: Helvetica, Arial, sans-serif; line-height: 24px; font-weight: normal; font-size: 16px; -moz-box-sizing: border-box; -webkit-box-sizing: border-box; box-sizing: border-box; color: rgb(24, 7, 113); margin: 0; padding: 0; border-width: 0;" bgcolor="#edece1">' +
			"      <tbody>" +
			"        <tr>" +
			'          <td valign="top" style="line-height: 24px; font-size: 16px; margin: 0;" align="left" bgcolor="#edece1">' +
			'            <table class="container" role="presentation" border="0" cellpadding="0" cellspacing="0" style="width: 100%;">' +
			"              <tbody>" +
			"                <tr>" +
			'                  <td align="center" style="line-height: 24px; font-size: 16px; margin: 0; padding: 0 16px; background-color: #fff; height: 100vh;">' +
			"                    <!--[if (gte mso 9)|(IE)]>" +
			'                      <table align="center" role="presentation">' +
			"                        <tbody>" +
			"                          <tr>" +
			'                            <td width="600">' +
			"                    <![endif]-->" +
			'                    <table align="center" role="presentation" border="0" cellpadding="0" cellspacing="0" style="width: 100%; max-width: 600px; margin: 0 auto;">' +
			"                      <tbody>" +
			"                        <tr>" +
			'                          <td style="line-height: 24px; font-size: 16px; margin: 0;" align="left" >' +
			'                            <table class="s-10 w-full" role="presentation" border="0" cellpadding="0" cellspacing="0" style="width: 100%;" width="100%">' +
			"                              <tbody>" +
			"                                <tr>" +
			'                                  <td style="line-height: 40px; font-size: 40px; width: 100%; height: 40px; margin: 0;" align="left" width="100%" height="40">' +
			"                                    &#160;" +
			"                                  </td>" +
			"                                </tr>" +
			"                              </tbody>" +
			"                            </table>" +
			'                            <table class="ax-center" role="presentation" align="center" border="0" cellpadding="0" cellspacing="0" style="margin: 0 auto;">' +
			"                              <tbody>" +
			"                                <tr>" +
			'                                  <td style="line-height: 24px; font-size: 16px; margin: 0;" align="left">' +
			'                                    <img class="w-24" src="http://195.231.38.199/static/media/h&bdark.5ff8b3a3.png" style="height: auto; line-height: 100%; outline: none; text-decoration: none; display: block; width: 230px; border-style: none; border-width: 0;" width="96">' +
			"                                  </td>" +
			"                                </tr>" +
			"                              </tbody>" +
			"                            </table>" +
			'                            <table class="s-10 w-full" role="presentation" border="0" cellpadding="0" cellspacing="0" style="width: 100%;" width="100%">' +
			"                              <tbody>" +
			"                                <tr>" +
			'                                  <td style="line-height: 40px; font-size: 40px; width: 100%; height: 40px; margin: 0;" align="left" width="100%" height="40">' +
			"                                    &#160;" +
			"                                  </td>" +
			"                                </tr>" +
			"                              </tbody>" +
			"                            </table>" +
			'                            <table class="card p-6 p-lg-10 space-y-4" role="presentation" border="0" cellpadding="0" cellspacing="0" style="border-radius: 25px; border-collapse: separate !important; width: 100%; overflow: hidden; border: 1px solid #95927a7d;box-shadow: 0 5px 15px rgba(0,0,0,.35);" bgcolor="#ffffff">' +
			"                              <tbody>" +
			"                                <tr>" +
			'                                  <td style="line-height: 24px; font-size: 16px; width: 100%; margin: 0; padding: 40px; background-color: #95927a7d;" align="left" >' +
			'                                    <h1 class="h3 fw-700" style="padding-top: 0; padding-bottom: 0; font-weight: 700 !important; vertical-align: baseline; font-size: 28px; line-height: 33.6px; margin: 0;" align="left">' +
			"                                      Grazie per l'acquisto" +
			"                                    </h1>" +
			'                                    <table class="s-4 w-full" role="presentation" border="0" cellpadding="0" cellspacing="0" style="width: 100%;" width="100%">' +
			"                                      <tbody>" +
			"                                        <tr>" +
			'                                          <td style="line-height: 16px; font-size: 16px; width: 100%; height: 16px; margin: 0;" align="left" width="100%" height="16">' +
			"                                            &#160;" +
			"                                          </td>" +
			"                                        </tr>" +
			"                                      </tbody>" +
			"                                    </table>" +
			'                                    <p class="" style="line-height: 24px; font-size: 16px; width: 100%; margin: 0;" align="left">' +
			"                                      Grazie per aver scelto Honey&Bees per i vostri servizi di lead generation B2B su Linkedin. Siamo entusiasti di confermare l'avvenuto acquisto del nostro pacchetto chiamate." +
			"                                    </p>" +
			'                                    <table class="s-4 w-full" role="presentation" border="0" cellpadding="0" cellspacing="0" style="width: 100%;" width="100%">' +
			"                                      <tbody>" +
			"                                        <tr>" +
			'                                          <td style="line-height: 16px; font-size: 16px; width: 100%; height: 16px; margin: 0;" align="left" width="100%" height="16">' +
			"                                            &#160;" +
			"                                          </td>" +
			"                                        </tr>" +
			"                                      </tbody>" +
			"                                    </table>" +
			'                                    <table class="s-4 w-full" role="presentation" border="0" cellpadding="0" cellspacing="0" style="width: 100%;" width="100%">' +
			"                                      <tbody>" +
			"                                        <tr>" +
			'                                          <td style="line-height: 16px; font-size: 16px; width: 100%; height: 16px; margin: 0;" align="left" width="100%" height="16">' +
			"                                            &#160;" +
			"                                          </td>" +
			"                                        </tr>" +
			"                                      </tbody>" +
			"                                    </table>" +
			"                                    <div>" +
			"                                      <h4>Dettagli dell' acquisto:</h4>" +
			"                                      <ul>" +
			"                                        <li>" +
			"                                            Data acquisto: " +
			dataFormatted +
			"                                        </li>" +
			"                                        <li>" +
			"                                          Numero chiamate: " +
			chiamate +
			"                                        </li>" +
			"                                        <li>" +
			"                                          Prezzo: " +
			format_importo +
			" &euro;" +
			"                                        </li>" +
			"                                      </ul>" +
			"                                    </div>" +
			'                                    <table class="s-4 w-full" role="presentation" border="0" cellpadding="0" cellspacing="0" style="width: 100%;" width="100%">' +
			"                                      <tbody>" +
			"                                        <tr>" +
			'                                          <td style="line-height: 16px; font-size: 16px; width: 100%; height: 16px; margin: 0;" align="left" width="100%" height="16">' +
			"                                            &#160;" +
			"                                          </td>" +
			"                                        </tr>" +
			"                                      </tbody>" +
			"                                    </table>" +
			"                                  </td>" +
			"                                </tr>" +
			"                              </tbody>" +
			"                            </table>" +
			'                            <table class="s-10 w-full" role="presentation" border="0" cellpadding="0" cellspacing="0" style="width: 100%;" width="100%">' +
			"                              <tbody>" +
			"                                <tr>" +
			'                                  <td style="line-height: 40px; font-size: 40px; width: 100%; height: 40px; margin: 0;" align="left" width="100%" height="40">' +
			"                                    &#160;" +
			"                                  </td>" +
			"                                </tr>" +
			"                              </tbody>" +
			"                            </table> " +
			'                            <table class="s-6 w-full" role="presentation" border="0" cellpadding="0" cellspacing="0" style="width: 100%;" width="100%">' +
			"                              <tbody>" +
			"                                <tr>" +
			'                                  <td style="line-height: 24px; font-size: 24px; width: 100%; height: 24px; margin: 0;" align="left" width="100%" height="24">' +
			"                                    &#160;" +
			"                                  </td>" +
			"                                </tr>" +
			"                              </tbody>" +
			"                            </table>" +
			'                            <div class="text-muted" style=" font-size: 14px; text-align: justify;" >' +
			'                                <div class="mb-0" style="margin-top: 0; margin-bottom: 0;display: inline-block; width: 32%;text-align: center;">' +
			"                                    <small>" +
			'                                       <a style="color: rgb(24, 7, 113);" href="https://www.iubenda.com/privacy-policy/15534763">Privacy Policy</a>' +
			"                                    </small>" +
			"                                </div>" +
			'                                <div class="mb-0" style="margin-top: 0; margin-bottom: 0;display: inline-block; width: 32%;text-align: center;">' +
			"                                    <small>" +
			'                                        <a style="color: rgb(24, 7, 113);" href="https://www.iubenda.com/privacy-policy/15534763/cookie-policy">Cookie Policy</a>' +
			"                                    </small>" +
			"                                </div>" +
			'                                <div class="mb-0" style="margin-top: 0; margin-bottom: 0;display: inline-block; width: 32%;text-align: center;">' +
			"                                    <small>" +
			'                                        <a style="color: rgb(24, 7, 113);" href="https://honeyandbees.cloud/condizioni-di-servizio">Termini e Condizioni</a>' +
			"                                    </small>" +
			"                                </div>" +
			"                            </div> " +
			'                            <div class="text-muted" style="color: rgb(24, 7, 113); font-size: 14px;display: flex; justify-content: space-around;" align="center" >' +
			"                                <p>&copy; 2023 APPIUS SRLS - Fiorano Modenese(MO) P.IVA 03898560366 - Cap.soc. 3000 &euro; i.v.</p>" +
			"                            </div>" +
			"                          </td>" +
			"                        </tr>" +
			"                      </tbody>" +
			"                    </table>" +
			"                    <!--[if (gte mso 9)|(IE)]>" +
			"                    </td>" +
			"                  </tr>" +
			"                </tbody>" +
			"              </table>" +
			"                    <![endif]-->" +
			"                  </td>" +
			"                </tr>" +
			"              </tbody>" +
			"            </table>" +
			"          </td>" +
			"        </tr>" +
			"      </tbody>" +
			"    </table>" +
			"  </body>" +
			"</html>" +
			"" +
			"";
		return acquisto;
	}

	static getBot() {
		var bot =
			"" +
			'<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Strict//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-strict.dtd">' +
			"<html>" +
			"  <head>" +
			"    <!-- Compiled with Bootstrap Email version: 1.3.1 -->" +
			'    <meta http-equiv="Content-Type" content="text/html; charset=utf-8">' +
			'    <meta http-equiv="x-ua-compatible" content="ie=edge">' +
			'    <meta name="x-apple-disable-message-reformatting">' +
			'    <meta name="viewport" content="width=device-width, initial-scale=1">' +
			'    <meta name="format-detection" content="telephone=no, date=no, address=no, email=no">' +
			'    <style type="text/css">' +
			"      body,table,td{font-family:Helvetica,Arial,sans-serif !important}.ExternalClass{width:100%}.ExternalClass,.ExternalClass p,.ExternalClass span,.ExternalClass font,.ExternalClass td,.ExternalClass div{line-height:150%}a{text-decoration:none}*{color:inherit}a[x-apple-data-detectors],u+#body a,#MessageViewBody a{color:inherit;text-decoration:none;font-size:inherit;font-family:inherit;font-weight:inherit;line-height:inherit}img{-ms-interpolation-mode:bicubic}table:not([class^=s-]){font-family:Helvetica,Arial,sans-serif;mso-table-lspace:0pt;mso-table-rspace:0pt;border-spacing:0px;border-collapse:collapse}table:not([class^=s-]) td{border-spacing:0px;border-collapse:collapse}@media screen and (max-width: 600px){.w-full,.w-full>tbody>tr>td{width:100% !important}.w-24,.w-24>tbody>tr>td{width:250px !important}.w-40,.w-40>tbody>tr>td{width:160px !important}.p-lg-10:not(table),.p-lg-10:not(.btn)>tbody>tr>td,.p-lg-10.btn td a{padding:0 !important}.p-3:not(table),.p-3:not(.btn)>tbody>tr>td,.p-3.btn td a{padding:12px !important}.p-6:not(table),.p-6:not(.btn)>tbody>tr>td,.p-6.btn td a{padding:24px !important}*[class*=s-lg-]>tbody>tr>td{font-size:0 !important;line-height:0 !important;height:0 !important}.s-4>tbody>tr>td{font-size:16px !important;line-height:16px !important;height:16px !important}.s-6>tbody>tr>td{font-size:24px !important;line-height:24px !important;height:24px !important}.s-10>tbody>tr>td{font-size:40px !important;line-height:40px !important;height:40px !important}}" +
			"    </style>" +
			"  </head>" +
			'  <body class="bg-light" style="outline: 0; width: 100%; min-width: 100%; height: 100%; -webkit-text-size-adjust: 100%; -ms-text-size-adjust: 100%; font-family: Helvetica, Arial, sans-serif; line-height: 24px; font-weight: normal; font-size: 16px; -moz-box-sizing: border-box; -webkit-box-sizing: border-box; box-sizing: border-box; color: #000000; margin: 0; padding: 0; border-width: 0;" bgcolor="#edece1">' +
			'    <table class="bg-light body" valign="top" role="presentation" border="0" cellpadding="0" cellspacing="0" style="outline: 0; width: 100%; min-width: 100%; height: 100%; -webkit-text-size-adjust: 100%; -ms-text-size-adjust: 100%; font-family: Helvetica, Arial, sans-serif; line-height: 24px; font-weight: normal; font-size: 16px; -moz-box-sizing: border-box; -webkit-box-sizing: border-box; box-sizing: border-box; color: rgb(24, 7, 113); margin: 0; padding: 0; border-width: 0;" bgcolor="#edece1">' +
			"      <tbody>" +
			"        <tr>" +
			'          <td valign="top" style="line-height: 24px; font-size: 16px; margin: 0;" align="left" bgcolor="#edece1">' +
			'            <table class="container" role="presentation" border="0" cellpadding="0" cellspacing="0" style="width: 100%;">' +
			"              <tbody>" +
			"                <tr>" +
			'                  <td align="center" style="line-height: 24px; font-size: 16px; margin: 0; padding: 0 16px; background-color: #fff; height: 100vh;">' +
			"                    <!--[if (gte mso 9)|(IE)]>" +
			'                      <table align="center" role="presentation">' +
			"                        <tbody>" +
			"                          <tr>" +
			'                            <td width="600">' +
			"                    <![endif]-->" +
			'                    <table align="center" role="presentation" border="0" cellpadding="0" cellspacing="0" style="width: 100%; max-width: 600px; margin: 0 auto;">' +
			"                      <tbody>" +
			"                        <tr>" +
			'                          <td style="line-height: 24px; font-size: 16px; margin: 0;" align="left" >' +
			'                            <table class="s-10 w-full" role="presentation" border="0" cellpadding="0" cellspacing="0" style="width: 100%;" width="100%">' +
			"                              <tbody>" +
			"                                <tr>" +
			'                                  <td style="line-height: 40px; font-size: 40px; width: 100%; height: 40px; margin: 0;" align="left" width="100%" height="40">' +
			"                                    &#160;" +
			"                                  </td>" +
			"                                </tr>" +
			"                              </tbody>" +
			"                            </table>" +
			'                            <table class="ax-center" role="presentation" align="center" border="0" cellpadding="0" cellspacing="0" style="margin: 0 auto;">' +
			"                              <tbody>" +
			"                                <tr>" +
			'                                  <td style="line-height: 24px; font-size: 16px; margin: 0;" align="left">' +
			'                                    <img class="w-24" src="http://195.231.38.199/static/media/h&bdark.5ff8b3a3.png" style="height: auto; line-height: 100%; outline: none; text-decoration: none; display: block; width: 230px; border-style: none; border-width: 0;" width="96">' +
			"                                  </td>" +
			"                                </tr>" +
			"                              </tbody>" +
			"                            </table>" +
			'                            <table class="s-10 w-full" role="presentation" border="0" cellpadding="0" cellspacing="0" style="width: 100%;" width="100%">' +
			"                              <tbody>" +
			"                                <tr>" +
			'                                  <td style="line-height: 40px; font-size: 40px; width: 100%; height: 40px; margin: 0;" align="left" width="100%" height="40">' +
			"                                    &#160;" +
			"                                  </td>" +
			"                                </tr>" +
			"                              </tbody>" +
			"                            </table>" +
			'                            <table class="card p-6 p-lg-10 space-y-4" role="presentation" border="0" cellpadding="0" cellspacing="0" style="border-radius: 25px; border-collapse: separate !important; width: 100%; overflow: hidden; border: 1px solid #95927a7d;box-shadow: 0 5px 15px rgba(0,0,0,.35);" bgcolor="#ffffff">' +
			"                              <tbody>" +
			"                                <tr>" +
			'                                  <td style="line-height: 24px; font-size: 16px; width: 100%; margin: 0; padding: 40px 40px 20px 40px; background-color: #95927a7d;" align="left" >' +
			'                                    <h1 class="h3 fw-700" style="padding-top: 0; padding-bottom: 0; font-weight: 700 !important; vertical-align: baseline; font-size: 28px; line-height: 33.6px; margin: 0;" align="left">' +
			"                                      La tua lista {{{lista}}} è pronta" +
			"                                    </h1>" +
			'                                    <table class="s-4 w-full" role="presentation" border="0" cellpadding="0" cellspacing="0" style="width: 100%;" width="100%">' +
			"                                      <tbody>" +
			"                                        <tr>" +
			'                                          <td style="line-height: 16px; font-size: 16px; width: 100%; height: 16px; margin: 0;" align="left" width="100%" height="16">' +
			"                                            &#160;" +
			"                                          </td>" +
			"                                        </tr>" +
			"                                      </tbody>" +
			"                                    </table>" +
			'                                    <p class="" style="line-height: 24px; font-size: 16px; width: 100%; margin: 0;" align="left">' +
			"                                      Siamo lieti di informarti che il caricamento della lista {{{lista}}} è stata completata con successo ed abbiamo già iniziato a contattare i tuoi futuri clienti." +
			"                                    </p>" +
			"                                    <p>A presto.</p>" +
			'                                    <table class="s-4 w-full" role="presentation" border="0" cellpadding="0" cellspacing="0" style="width: 100%;" width="100%">' +
			"                                      <tbody>" +
			"                                        <tr>" +
			'                                          <td style="line-height: 16px; font-size: 16px; width: 100%; height: 16px; margin: 0;" align="left" width="100%" height="16">' +
			"                                            &#160;" +
			"                                          </td>" +
			"                                        </tr>" +
			"                                      </tbody>" +
			"                                    </table>" +
			"                                  </td>" +
			"                                </tr>" +
			"                              </tbody>" +
			"                            </table>" +
			'                            <table class="s-10 w-full" role="presentation" border="0" cellpadding="0" cellspacing="0" style="width: 100%;" width="100%">' +
			"                              <tbody>" +
			"                                <tr>" +
			'                                  <td style="line-height: 40px; font-size: 40px; width: 100%; height: 40px; margin: 0;" align="left" width="100%" height="40">' +
			"                                    &#160;" +
			"                                  </td>" +
			"                                </tr>" +
			"                              </tbody>" +
			"                            </table> " +
			'                            <table class="s-6 w-full" role="presentation" border="0" cellpadding="0" cellspacing="0" style="width: 100%;" width="100%">' +
			"                              <tbody>" +
			"                                <tr>" +
			'                                  <td style="line-height: 24px; font-size: 24px; width: 100%; height: 24px; margin: 0;" align="left" width="100%" height="24">' +
			"                                    &#160;" +
			"                                  </td>" +
			"                                </tr>" +
			"                              </tbody>" +
			"                            </table>" +
			'                             <div class="text-muted" style=" font-size: 14px; text-align: justify;" >' +
			'                                <div class="mb-0" style="margin-top: 0; margin-bottom: 0;display: inline-block; width: 32%;text-align: center;">' +
			"                                    <small>" +
			'                                       <a style="color: rgb(24, 7, 113);" href="https://www.iubenda.com/privacy-policy/15534763">Privacy Policy</a>' +
			"                                    </small>" +
			"                                </div>" +
			'                                <div class="mb-0" style="margin-top: 0; margin-bottom: 0;display: inline-block; width: 32%;text-align: center;">' +
			"                                    <small>" +
			'                                        <a style="color: rgb(24, 7, 113);" href="https://www.iubenda.com/privacy-policy/15534763/cookie-policy">Cookie Policy</a>' +
			"                                    </small>" +
			"                                </div>" +
			'                                <div class="mb-0" style="margin-top: 0; margin-bottom: 0;display: inline-block; width: 32%;text-align: center;">' +
			"                                    <small>" +
			'                                        <a style="color: rgb(24, 7, 113);" href="https://honeyandbees.cloud/condizioni-di-servizio">Termini e Condizioni</a>' +
			"                                    </small>" +
			"                                </div>" +
			"                            </div> " +
			'                            <div class="text-muted" style="color: rgb(24, 7, 113); font-size: 14px;display: flex; justify-content: space-around;" align="center" >' +
			"                                <p>&copy; 2023 APPIUS SRLS - Fiorano Modenese(MO) P.IVA 03898560366 - Cap.soc. 3000 &euro; i.v.</p>" +
			"                            </div>" +
			'                            <table class="s-6 w-full" role="presentation" border="0" cellpadding="0" cellspacing="0" style="width: 100%;" width="100%">' +
			"                              <tbody>" +
			"                                <tr>" +
			'                                  <td style="line-height: 24px; font-size: 24px; width: 100%; height: 24px; margin: 0;" align="left" width="100%" height="24">' +
			"                                    &#160;" +
			"                                  </td>" +
			"                                </tr>" +
			"                              </tbody>" +
			"                            </table>" +
			"                          </td>" +
			"                        </tr>" +
			"                      </tbody>" +
			"                    </table>" +
			"                    <!--[if (gte mso 9)|(IE)]>" +
			"                    </td>" +
			"                  </tr>" +
			"                </tbody>" +
			"              </table>" +
			"                    <![endif]-->" +
			"                  </td>" +
			"                </tr>" +
			"              </tbody>" +
			"            </table>" +
			"          </td>" +
			"        </tr>" +
			"      </tbody>" +
			"    </table>" +
			"  </body>" +
			"</html>" +
			"" +
			"";
		return bot;
	}

	static province = {
		AG: "Agrigento",
		AL: "Alessandria",
		AN: "Ancona",
		AO: "Aosta",
		AR: "Arezzo",
		AP: "Ascoli Piceno",
		AT: "Asti",
		AV: "Avellino",
		BA: "Bari",
		BT: "Barletta-Andria-Trani",
		BL: "Belluno",
		BN: "Benevento",
		BG: "Bergamo",
		BI: "Biella",
		BO: "Bologna",
		BZ: "Bolzano",
		BS: "Brescia",
		BR: "Brindisi",
		CA: "Cagliari",
		CL: "Caltanissetta",
		CB: "Campobasso",
		CI: "Carbonia-Iglesias",
		CE: "Caserta",
		CT: "Catania",
		CZ: "Catanzaro",
		CH: "Chieti",
		CO: "Como",
		CS: "Cosenza",
		CR: "Cremona",
		KR: "Crotone",
		CN: "Cuneo",
		EN: "Enna",
		FM: "Fermo",
		FE: "Ferrara",
		FI: "Firenze",
		FG: "Foggia",
		FC: "Forlì-Cesena",
		FR: "Frosinone",
		GE: "Genova",
		GO: "Gorizia",
		GR: "Grosseto",
		IM: "Imperia",
		IS: "Isernia",
		SP: "La Spezia",
		AQ: "L'Aquila",
		LT: "Latina",
		LE: "Lecce",
		LC: "Lecco",
		LI: "Livorno",
		LO: "Lodi",
		LU: "Lucca",
		MC: "Macerata",
		MN: "Mantova",
		MS: "Massa-Carrara",
		MT: "Matera",
		ME: "Messina",
		MI: "Milano",
		MO: "Modena",
		MB: "Monza e della Brianza",
		NA: "Napoli",
		NO: "Novara",
		NU: "Nuoro",
		OT: "Olbia-Tempio",
		OR: "Oristano",
		PD: "Padova",
		PA: "Palermo",
		PR: "Parma",
		PV: "Pavia",
		PG: "Perugia",
		PU: "Pesaro e Urbino",
		PE: "Pescara",
		PC: "Piacenza",
		PI: "Pisa",
		PT: "Pistoia",
		PN: "Pordenone",
		PZ: "Potenza",
		PO: "Prato",
		RG: "Ragusa",
		RA: "Ravenna",
		RC: "Reggio Calabria",
		RE: "Reggio Emilia",
		RI: "Rieti",
		RN: "Rimini",
		RM: "Roma",
		RO: "Rovigo",
		SA: "Salerno",
		VS: "Medio Campidano",
		SS: "Sassari",
		SV: "Savona",
		SI: "Siena",
		SR: "Siracusa",
		SO: "Sondrio",
		TA: "Taranto",
		TE: "Teramo",
		TR: "Terni",
		TO: "Torino",
		OG: "Ogliastra",
		TP: "Trapani",
		TN: "Trento",
		TV: "Treviso",
		TS: "Trieste",
		UD: "Udine",
		VA: "Varese",
		VE: "Venezia",
		VB: "Verbano-Cusio-Ossola",
		VC: "Vercelli",
		VR: "Verona",
		VV: "Vibo Valentia",
		VI: "Vicenza",
		VT: "Viterbo",
	};
}
