import React, { useRef, useState } from "react";
import createRechargeRequest from "../../services/createRechargeRequest";
import Cookies from "universal-cookie";
import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
  TextField,
} from "@mui/material";
import small from "../../videos/small.mp4";
import medium from "../../videos/medium.mp4";
import large from "../../videos/large.mp4";
import bestSeller from "../../images/bestSeller.png";
import getCoupon from "../../services/getCoupon";
import { styled } from "@mui/material/styles";
import { CheckCircleIcon } from "@material-ui/data-grid";
import { Error } from "@material-ui/icons";

const RoundedPrice = ({ price }) => {
  const roundedPrice = Math.ceil(price * 100) / 100;
  return <>{roundedPrice.toFixed(2)}€</>;
};

const CssTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "white",
  },
  "& label.MuiInputLabel-root": {
    color: "white!important",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#B2BAC2",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#E0E3E7",
    },
    "&:hover fieldset": {
      borderColor: "#B2BAC2",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#6F7E8C",
    },
    "& input": {
      color: "white",
    },
    "& label": {
      color: "white",
    },
  },
});

const cookie = new Cookies();
const username = cookie.get("username", { path: "/" });
const token = cookie.get("token", { path: "/" });

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const PacchettiModal = (props) => {
  const videoRefs = useRef(props.pacchetti.map(() => React.createRef()));

  const [couponText, setCouponText] = useState("");
  const [coupon, setCoupon] = useState(null);
  const [couponValidator, setCouponValidator] = useState({
    valid: false,
    message: "",
  });

  const playVideo = (index) => {
    if (videoRefs.current[index].current) {
      videoRefs.current[index].current.play();
    }
  };

  const pauseVideo = (index) => {
    if (videoRefs.current[index].current) {
      videoRefs.current[index].current.pause();
    }
  };

  const onClickCard = (id) => {
    const coupon_id = coupon ? coupon.id : null;
    createRechargeRequest(username, token, id, coupon_id).then((res) => {
      if (res.result === "ok") {
        window.location.href = res.stripe_url;
      }
    });
  };

  const canceled = () => {
    props.onCancel();
  };

  const checkCoupon = async () => {
    const resp = await getCoupon(username, token, couponText);
    if (resp.result === "ok") {
      setCouponValidator({
        valid: true,
        message:
          "Grandioso! Il tuo coupon è stato applicato. Hai diritto a uno sconto del " +
          resp.coupon.value +
          "%",
      });
      setCoupon(resp.coupon);
    } else {
      setCouponValidator({
        valid: false,
        message: "Coupon non valido",
      });
    }
  };

  return (
    <Dialog
      maxWidth="md"
      fullWidth={true}
      open={props.modal.show}
      TransitionComponent={Transition}
      keepMounted
      onClose={canceled}
      aria-describedby="alert-dialog-slide-description"
    >
      <div className="pricing p-5">
        <DialogTitle color="white" className="text-center fw-bold">
          Scegli il pacchetto giusto per te
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            className="text-center fw-bold mb-5"
            color={"white"}
          >
            Acquista un pacchetto e inizia a contattare i tuoi clienti
          </DialogContentText>
          {!couponValidator.valid && (
            <DialogContent className="row d-flex justify-content-center">
              <DialogContentText
                id="alert-dialog-slide-description"
                className="text-center fw-bold"
                color={"white"}
              >
                Hai un codice sconto? Inseriscilo qui sotto
              </DialogContentText>
              <div className="col-12 d-flex">
                <CssTextField
                  fullWidth
                  id="outlined-basic"
                  label="Inserisci il tuo coupon"
                  variant="outlined"
                  value={couponText}
                  onChange={(e) => setCouponText(e.target.value)}
                />
                <Button
                  variant="contained"
                  color="primary"
                  onClick={checkCoupon}
                >
                  Applica
                </Button>
              </div>
              {couponValidator.message && (
                <DialogContentText
                  id="alert-dialog-slide-description"
                  color="white"
                  className="text-center fw-bold mt-1"
                >
                  <Error style={{ color: "red" }} fontSize="medium" />
                  {" " + couponValidator.message}
                </DialogContentText>
              )}
            </DialogContent>
          )}
          {couponValidator.valid && (
            <DialogContent className="row d-flex justify-content-center">
              <DialogContentText
                id="alert-dialog-slide-description"
                className="text-center fw-bold"
                color={"white"}
              >
                <CheckCircleIcon style={{ color: "green" }} />{" "}
                {couponValidator.message}
              </DialogContentText>
            </DialogContent>
          )}
        </DialogContent>
        <div className="row d-flex align-items-center">
          {props.pacchetti.length > 0 &&
            props.pacchetti.map((pacchetto, index) => {
              return (
                <div className="col-sm-12 col-lg-4 p-2" key={index}>
                  <div
                    style={{
                      height: pacchetto.in_evidenza === "1" ? 350 : 300,
                    }}
                    className="card mb-5 mb-lg-0"
                    onMouseEnter={() => playVideo(index)}
                    onMouseLeave={() => pauseVideo(index)}
                  >
                    <video
                      ref={videoRefs.current[index]}
                      src={
                        pacchetto.nome === "Small"
                          ? small
                          : pacchetto.nome === "Medium"
                          ? medium
                          : large
                      }
                      alt=""
                      style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        height: "100%",
                        width: "100%",
                        objectFit: "cover",
                        zIndex: 0,
                        borderRadius: "10px",
                      }}
                    />
                    <div
                      style={{
                        position: "absolute",
                        backgroundColor: "rgba(0,0,0,0.5)",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                        zIndex: 1,
                        borderRadius: "10px",
                      }}
                    />
                    <div className="card-body position-relative">
                      {pacchetto.in_evidenza === "1" && (
                        <img
                          src={bestSeller}
                          alt="best seller"
                          style={{
                            position: "absolute",
                            top: -50,
                            right: -26,
                            width: "50%",
                            zIndex: 9,
                          }}
                        />
                      )}
                      <div>
                        <h3 className="card-title text-uppercase text-center text-light">
                          {pacchetto.nome}
                        </h3>
                        {couponValidator.valid && coupon ? (
                          <>
                            <p className="card-price text-center text-light">
                              <span className="fs-1">
                                <del>{pacchetto.prezzo}€</del>
                              </span>
                              <span className="fs-5"> + IVA</span>
                            </p>
                            <p className="card-price text-center text-light">
                              <span className="fs-1">
                                <RoundedPrice
                                  price={
                                    pacchetto.prezzo -
                                    (pacchetto.prezzo * coupon.value) / 100
                                  }
                                />
                              </span>
                              <span className="fs-5"> + IVA</span>
                            </p>
                          </>
                        ) : (
                          <p className="card-price text-center text-light">
                            <span className="fs-1">{pacchetto.prezzo}€</span>
                            <span className="fs-5"> + IVA</span>
                          </p>
                        )}
                      </div>
                      <div style={{ height: 1, backgroundColor: "gray" }} />
                      <div>
                        <h5 className="card-title text-uppercase text-center mb-3 text-light">
                          {pacchetto.num_chiamate} chiamate
                        </h5>
                        <div className="d-grid">
                          <button
                            href="#"
                            className="btn btn-pricing text-uppercase"
                            onClick={() => onClickCard(pacchetto.id)}
                          >
                            Acquista
                          </button>
                        </div>
                        <div
                          className="d-flex justify-content-between card-title text-uppercase text-light pt-2 flex-wrap "
                          style={{ fontSize: "12px", gap: "8px" }}
                        >
                          <span
                            className="text-center"
                            style={{ margin: "0 auto" }}
                          >
                            150 messaggi di test
                          </span>
                          <span
                            className="text-center"
                            style={{ margin: "0 auto" }}
                          >
                            Liste illimitate
                          </span>
                          <span
                            className="text-center"
                            style={{ margin: "0 auto" }}
                          >
                            Messaggi illimitati
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </Dialog>
  );
};

export default PacchettiModal;
