import React, { useContext } from "react";
import EmailsTable from "../components/admin/EmailsTable";
import HeBLayout from "../components/HeBLayout";
import NotifyContext from "../context/NotifyContext";

const Emails = () => {
	const { selectedUser } = useContext(NotifyContext);
	return (
		<HeBLayout>
			<EmailsTable selectedUser={selectedUser} />
		</HeBLayout>
	);
};

export default Emails;
