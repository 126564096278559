"use client"
import React, { useEffect } from "react"; 
import * as Icon from 'react-feather'; 
import BlogJson from '../utils/JsonBlog'; 
import { Link } from "react-router-dom";
import Navbar from "../components/Navbar"; 
import Footer from "../components/Footer";
import PageBanner from "../components/PageBanner";


const Blog = () => {
    const [isMobile, setMobile] = React.useState(false);
    const [hasNav, setNav] = React.useState(false); 
    const [sortedArticles, setSortedArticles] = React.useState([]);

    const handleResize = () => {
        if (window.innerWidth < 1275) {
            setMobile(true);
        } else {
            setMobile(false);
        }
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        setNav(document !== null);
        window.addEventListener("resize", handleResize);
        handleResize();
        const array = BlogJson();
        setSortedArticles(array.sort((a, b) => (a.id > b.id) ? -1 : 1));
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    if(hasNav) return(
        <> 
            <Navbar /> 
            <div className="page-title-area overflow-hidden pt-0" style={{ alignContent: 'center', textAlign: 'center' }}>
                <PageBanner pageTitle={"Il nostro Blog"} /> 
                <div className="container"> 
                    <div className="row justify-content-center align-items-stretch">
                        {
                            sortedArticles.map(article => {   
                                if(article.id === sortedArticles.length && !isMobile ) {
                                    return (
                                        <div key={article.id} className="last-post-bg"> 
                                            <div className="last-post-date">
                                                <Icon.Calendar /> {article.data}
                                            </div>
                                            <div className="col-11">
                                                <div className="row">
                                                    <div className="single-blog-post last-post">
                                                        <div className="blog-image col-8 d-flex align-items-center">
                                                            <Link to={"/blogPost/"+article.page}>
                                                                <img className='h-75 w-75' src={require(`../images${article.img}`)} alt="img" />
                                                            </Link>
                                                        </div> 
                                                        <div id="height-last-post" className="blog-post-content pt-0 pb-0 col-4 d-flex flex-column  ">
                                                            <div style={{height:'40%'}}>
                                                                <h2>
                                                                    <Link style={{color:'#41495c'}} className="text-decoration-none" to={"/blogPost/"+article.page}>
                                                                        {article.title}
                                                                    </Link>
                                                                </h2> 
                                                                <h5>{article.desc}</h5> 
                                                            </div>
                                                            <div style={{height:'50%'}}>
                                                                <p className="pt-3 max-wid-post ">{article.text}</p> 
                                                            </div>
                                                            <div className="mt-3" style={{height:'10%'}}>
                                                                <Link to={"/blogPost/"+article.page} className="read-more-btn text-decoration-none fs-4">
                                                                    Scopri di piu <Icon.ArrowRight />
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    </div> 
                                                </div>
                                            </div>
                                        </div>
                                    )
                                } else {
                                    return (
                                    <div key={article.id} className="col-lg-4 col-md-6">
                                    <div className="single-blog-post">
                                            <div className="blog-image">
                                                <Link to={"/blogPost/"+article.page}>
                                                    <img className='h-100 w-100' src={require(`../images${article.img}`)} alt="img" />
                                                </Link> 
                                                <div className="date">
                                                    <Icon.Calendar /> {article.data}
                                                </div>
                                            </div> 
                                            <div className="blog-post-content">
                                                <h3>
                                                    <Link style={{color:'#41495c'}} className="text-decoration-none" to={"/blogPost/"+article.page}>
                                                        {article.title}
                                                    </Link>
                                                </h3>
                            
                                                <p>{article.desc}</p>
                
                                                <Link to={"/blogPost/"+article.page} className="read-more-btn text-decoration-none">
                                                    Scopri di piu <Icon.ArrowRight />
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                    )
                                } 
                            })
                        }
                    </div>
                </div>
            </div>  
            <Footer />
        </>
    )
    else return <></>
}

export default Blog;