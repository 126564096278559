import React, { useState, createContext, useEffect } from "react";
import getNotificheCount from "../services/getNotificheCount";
import Cookies from "universal-cookie";

const NotifyContext = createContext();

const cookies = new Cookies();
let username = cookies.get("username", { path: "/" });
let token = cookies.get("token", { path: "/" });
let id = cookies.get("id", { path: "/" });

export const NotifyProvider = ({ children }) => {
	const [countNotify, setCountNotify] = useState(0);
	const [selectedUser, setSelectedUser] = useState(id);

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await getNotificheCount(username, token);

				if (response.result === "ok") {
					setCountNotify(response.notifiche.count);
				}
			} catch (error) {
				console.error("Errore durante il recupero del conteggio delle notifiche:", error);
			}
		};

		if (token && username) {
			fetchData();
		}
	}, []);

	const decrementNotify = () => {
		return setCountNotify(prev => prev - 1);
	};

	return (
		<NotifyContext.Provider value={{ countNotify, decrementNotify, selectedUser, setSelectedUser }}>
			{children}
		</NotifyContext.Provider>
	);
};

export default NotifyContext;
