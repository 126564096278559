import React, { useEffect } from "react";
import "./style.css";
import aperturaLink from "../services/aperturaLink";

const UniqueCode = () => {
	useEffect(() => {
		const path = window.location.pathname.split("/");
		const code = path[path.length - 1];

		if (code) {
			aperturaLink(code).then(res => {
				const link = res.link;
				if (link) {
					let redirectUrl = link;
					if (!/^https?:\/\//i.test(redirectUrl)) {
						redirectUrl = "https://" + redirectUrl;
					}
					window.location.href = redirectUrl;
				} else {
					window.location.href = "/";
				}
			});
		}
	}, []);

	return <></>;
};

export default UniqueCode;
