import React from "react";
import "./style.css";
import Cookies from "universal-cookie";

const cookie = new Cookies();
const username = cookie.get("username");
const token = cookie.get("token");

export default class PreferitiChatTest extends React.Component {
	constructor(props) {
		super(props);
		this.messageRes = React.createRef();
		this.state = {
			path: "",
			newMessage: "",
			modal: false,
			sequenza: "",
			pausa: "",
			modalEdit: false,
			selectedStato: "Tutti",
			selectedPredefinito: null,
			predefinito_id: null,
		};
	}

	componentDidMount() {
		this.reload();
	}

	async reload() {
		this.props.getPredefinitiResp();
		let path = window.location.href.split("/")[3];
		this.setState({ username, token, path });
	}

	render() {
		return (
			<div style={{ width: "20%", borderLeft: "1px solid gray" }} className="p-3 h-100 overflow-scroll">
				<div className="predefiniti">
					{this.props.predefiniti &&
						this.props.predefiniti.length > 0 &&
						this.props.predefiniti.map((predefinito, i) => {
							if (predefinito.cestino) return null;
							return (
								<div key={i} className="row m-2">
									<div className="col position-relative align-items-center border-bottom border-white pb-3 mt-3">
										<span
											className="position-absolute top-0 end-0 p-2 rounded text-white bg-success fw-bold cursor_pointer"
											onClick={() => this.props.addPredefinito(predefinito.id)}
										>
											+
										</span>
										<p className="m-0 text-white fw-bold fs-6">Sequenza: {predefinito.sequenza}</p>
										<p className="m-0 text-white fw-bold fs-6">Pausa: {predefinito.pausa}</p>
										<p className="m-0 text-white fw-bold fs-6">
											Tipo: {predefinito.prompt === 1 ? "Prompt" : "Messaggio"}
										</p>
										<p className="m-0 text-white fw-bold fs-6">
											Stato del contatto: {predefinito.stato}
										</p>
										<p className="m-0 text-white fs-7">{predefinito.messaggio}</p>
									</div>
								</div>
							);
						})}
				</div>
			</div>
		);
	}
}
