import React from 'react';

const PageBanner = ({pageTitle}) => {
    return (
        <div className="page-title-area text-color" style={{ alignContent: 'center', textAlign: 'center' }}>
            <div>
                <div>
                    <div>
                        <h2 className='HeeboBold'>{pageTitle.toUpperCase()}</h2>
                        <p style={{ backgroundColor: '#f7b51e', width: '100px', marginLeft: '50vw', transform: 'translateX(-50%)', height: 4, borderRadius: 2, marginTop: '10px', marginBottom: '10px', alignSelf: 'center' }} />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PageBanner;