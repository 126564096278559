import React, { useContext } from "react";
import Cookies from "universal-cookie";
import HeBLayout from "../components/HeBLayout";
import CampagneTable from "../components/CampagneTable";
import NotifyContext from "../context/NotifyContext";

const Campagne = props => {
	const { selectedUser } = useContext(NotifyContext);
	return (
		<HeBLayout>
			<div
				style={{
					width: "96%",
					marginInline: "auto",
					marginTop: "20px",
					marginBottom: "20px",
					maxHeight: "90%",
				}}
			>
				<CampagneTable {...props} selectedUser={selectedUser} />
			</div>
		</HeBLayout>
	);
};

export default Campagne;
