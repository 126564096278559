
import React from 'react';
import verificaMail from '../services/verificaMail';
import ReactLoading from 'react-loading';

const qs = require('querystring');

export default class VerifyEmail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            innerHeight: window.innerHeight,
            innerWidth: window.innerWidth,
        };
    }

    handleResize() {
        this.setState({ innerHeight: window.innerHeight, innerWidth: window.innerWidth });
    }

    async componentDidMount() {

        window.addEventListener('resize', this.handleResize.bind(this));

        const parsed = qs.parse(window.location.hash);
        let token = parsed['verify?token'];

        if (token !== undefined) {
            let res = await verificaMail(token);
            if (res.result === 'ok') {
                this.setState({ success: true, isLoading: false });
            } else {
                this.setState({ success: false, isLoading: false });
            }
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize.bind(this));
    }

    render() {

        if (this.state.loading) return (
            <div className="d-flex justify-content-center vh-100">
                <ReactLoading type={'bubbles'} color={'orange'} height={'10%'} width={'10%'} className="align-self-center " />
            </div>
        )
        return (
            <>
                <img alt='' style={{ position: 'absolute', top: 30, left: 50, height: 50, width: 'auto', cursor: 'pointer' }} src={ require('../images/Honey&bees3.png') } onClick={() => window.location.href = '/' } />
                { this.state.success ? (
                    <div style={{ ...this.mainViewSuccess, width: this.state.innerWidth < 1100 ? '90%' : '50%', marginLeft: this.state.innerWidth < 1100 ? '5%' : '25%', marginTop: (this.state.innerHeight - 300)/2,  }}>
                        <div style={{ marginTop: '150px', transform: 'translateY(-50%)', textAlign: 'center' }}>
                            <h4 style={{ width: '80%', marginLeft: '10%', marginTop: '50px', fontSize: 24, fontWeight: 'bold', textAlign: 'center', color: 'darkgrey' }}>Email verificata correttamente!</h4>
                            <div className="btn btn-primary" onClick={() => window.location.href = '/login'} style={{ marginTop: 10 }}>Vai al login</div>
                        </div>
                    </div>
                ) : (
                    <div style={{ ...this.mainViewError, width: this.state.innerWidth < 1100 ? '90%' : '50%', marginLeft: this.state.innerWidth < 1100 ? '5%' : '25%', marginTop: (this.state.innerHeight - 300) / 2, }}>
                        <h4 style={{ width: '80%', marginLeft: '10%', marginTop: '150px', fontSize: 24, fontWeight: 'bold', textAlign: 'center', transform: 'translateY(-50%)', color: 'darkgrey' }}>Si è verificato un errore durante la verifica dell'email.</h4>
                    </div>
                )}
            </>
        );
    }

    mainViewSuccess = {
        height: '300px',
        minHeight: '300px',
        maxHeight: '300px',
        overflowX: 'hidden',
        overflowY: 'hidden',
        backgroundColor: '#F6FFE3',
        borderRadius: '10px',
        boxShadow: '0px 2px 10px darkgrey',
        marginBottom: 20
    };

    mainViewError = {
        height: '300px',
        minHeight: '300px',
        maxHeight: '300px',
        overflowX: 'hidden',
        overflowY: 'hidden',
        backgroundColor: '#FFF6F4',
        borderRadius: '10px',
        boxShadow: '0px 2px 10px darkgrey',
        marginBottom: 20
    };
}