import React, { useState, useEffect, useRef } from "react";
import { Alert, Card, Form } from "react-bootstrap";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Cookies from "universal-cookie";
import ReactLoading from "react-loading";
import logoAppius from "../images/h&bdark.png";
import checkUsernameEmail from "../services/checkUsernameEmail";
import addUtenteRegistrazione from "../services/addUtenteRegistrazione";
import "./style.css";
import { Button } from "@mui/material";
import { Typography } from "@material-ui/core"; 
import {AnimatePresence, motion} from 'framer-motion/dist/framer-motion'
import getPacchettiChiamate from "../services/getPacchettiChiamate";
import bestSeller from "../images/bestSeller.png"; 
import small from "../videos/small.mp4";
import medium from "../videos/medium.mp4";
import large from "../videos/large.mp4";

export default function Register() {
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState("");
  const [form, setForm] = useState({
    nome: "",
    cognome: "",
    username: "",
    email: "",
    password: "",
    confirmPassword: "",
    telefono: "",
    ragione_sociale: "",
    p_iva: "",
    codice_univoco: "",
    citta: "",
    cap: "",
    indirizzo: "",
    provincia: "",
    privacy: false,
    uuid: "",
    pacchetto: "",
  });
  const [alert, setAlert] = useState(false); 
  const [success, setSuccess] = useState(false);
  const [step, setStep] = useState(1);
  const [pacchetti, setPacchetti] = useState([]); 
  const [active, setActive] = useState(2);
  const [isMobile, setIsMobile] = useState(false); 


  const handleNextStep = () => {
    setStep(step + 1);
  };

  const handleResize = () => {
    if (window.innerWidth < 992) {
        setIsMobile(true);
    } else {
        setIsMobile(false);
    }
  }; 

  useEffect(() => {
    getPacchettiChiamate().then(res => {
      setPacchetti(res)
    });
    const cookies = new Cookies();
    let username = cookies.get("username", { path: "/" });
    let token = cookies.get("token", { path: "/" });
    if (username && token) {
      setLoading(true);
      window.location.href = "/dashboard";
    } else {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", () => handleResize());
      return () => {
        window.removeEventListener("resize", () => handleResize());
      };
  }, []); 

  const verifyUsernameEmail = async () => {
    if (!form.email || !form.nome || !form.cognome) {
      setAlert(true);
      setMessage("Compila tutti i campi!");
      return;
    }

    const regexEmail = new RegExp(
      "^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$"
    );
    if (!regexEmail.test(form.email)) {
      setAlert(true);
      setMessage("Email non valida");
      return;
    }

    // if (form.password !== form.confirmPassword) {
    //   setAlert(true);
    //   setMessage("Le password non coincidono");
    //   return;
    // }

    // const regexPassword = new RegExp(
    //   "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})"
    // );
    // if (!regexPassword.test(form.password)) {
    //   setAlert(true);
    //   setMessage(
    //     "La password deve contenere almeno 8 caratteri, una lettera maiuscola, una minuscola e un numero"
    //   );
    //   return;
    // }

    if (!form.privacy) {
      setAlert(true);
      setMessage("Devi accettare i termini e le condizioni di servizio");
      return;
    }

    let res = await checkUsernameEmail(
      form.email,
      form.nome,
      form.cognome,
      form.ragione_sociale
    );
    console.log(res);
      if (res.result === "OK") {
        handleNextStep();
        setAlert(false);
        setForm({ ...form, uuid: res.uuid });
        setMessage("");
      } else if (res.error === "email already exists") {
        setAlert(true);
        setMessage("Email già esistente");
      } else if (res.error === "user already exists") {
        setAlert(true);
        setMessage("User già esistente");
      }
  } 

  const handleRegister = async () => {
    setLoading(true);
    setAlert(false);

    if (active === "") {
      setAlert(true);
      setLoading(false);
      setMessage("Seleziona un pacchetto per continuare");
      return;
    }
    
    const res = await addUtenteRegistrazione(
      form.nome,
      form.cognome, 
      form.ragione_sociale, 
      form.email,
      form.uuid,
      active
    ); 

    if (res.result === "ok") { 
      setSuccess(true);
      setLoading(false);
    } else {
      setAlert(true);
      setLoading(false);
      setMessage("Errore nella registrazione, riprova più tardi");
    }
  };

  if (loading) {
    return (
      <div className="container-fluid backgroundLogin">
        <div className="d-flex vh-100 justify-content-center align-items-center">
          <ReactLoading
            type={"bubbles"}
            color={"orange"}
            height={"10%"}
            width={"10%"}
            className="align-self-center "
          />
        </div>
      </div>
    );
  }

  if (success) {
    return (
      <div className="container-fluid backgroundLogin">
        <div className="d-flex vh-100 justify-content-center align-items-center">
          <Card
            style={{ width: 450, background: "rgba(151, 151, 151, 0.468)" }}
          >
            <Card.Body>
              <div className="w-100 d-flex justify-content-center mb-3">
                <img
                  onClick={() => (window.location.href = "/")}
                  src={logoAppius}
                  alt=""
                  style={{
                    padding: 9,
                    width: "70%",
                    cursor: "pointer",
                    background: "rgb(255 255 255 / 42%)",
                    borderTopRightRadius: 50,
                    borderBottomLeftRadius: 10,
                  }}
                />
              </div>
              <Card.Title className="d-flex flex-column align-items-center text-dark text-center">
                <Typography variant="h2" className="mb-4">
                  Registrazione completata{" "}
                </Typography>
                <Typography variant="p" className="mb-4">
                  Ti abbiamo inviato una mail di conferma all'indirizzo{" "}
                  {form.email}. Controlla la tua casella di posta elettronica e
                  clicca sul link per confermare la tua registrazione.
                </Typography>
              </Card.Title>
            </Card.Body>
          </Card>
        </div>
      </div>
    );
  }

  return (
    <div className="backgroundLogin container-fluid "> 
      {step === 1 && (
        <div className=" row vh-100 d-flex align-items-center">  
          <AnimatePresence exitBeforeEnter>
              <motion.div
                key="step1"
                initial={{ opacity: 1, x: 0 }}
                animate={{ opacity: 1, x: 0 }}
                exit={{ opacity: 0, x: -300 }}
                transition={{ duration: 0.5 }}
              >
                <div className="d-flex justify-content-center">
                  <Card style={{ width: 450, background: "rgba(151, 151, 151, 0.468)" }}>
                    <Card.Body>
                      <div className="w-100 d-flex justify-content-center mb-3">
                        <img
                          onClick={() => (window.location.href = "/")}
                          src={logoAppius}
                          alt=""
                          style={{
                            padding: 9,
                            width: "70%",
                            cursor: "pointer",
                            background: "rgb(255 255 255 / 42%)",
                            borderTopRightRadius: 50,
                            borderBottomLeftRadius: 10,
                          }}
                        />
                      </div>
                      <Card.Title className="d-flex flex-column align-items-center">
                        <Form className="w-100">
                          {step === 1 && (
                            <>
                              <FloatingLabel
                                controlId="floatingInput"
                                label="Nome"
                                className="mb-3 fs-6"
                              >
                                <Form.Control
                                  style={{ background: "rgb(255 255 255 / 31%)" }}
                                  type="text"
                                  value={form.nome}
                                  placeholder="Nome"
                                  onChange={(e) =>
                                    setForm({ ...form, nome: e.target.value })
                                  }
                                />
                              </FloatingLabel>
                              <FloatingLabel
                                controlId="floatingInput"
                                label="Cognome"
                                className="mb-3 fs-6"
                              >
                                <Form.Control
                                  style={{ background: "rgb(255 255 255 / 31%)" }}
                                  type="text"
                                  placeholder="Cognome"
                                  value={form.cognome}
                                  onChange={(e) =>
                                    setForm({ ...form, cognome: e.target.value })
                                  }
                                />
                              </FloatingLabel>
                              <FloatingLabel
                                controlId="floatingInput"
                                label="Ragione Sociale"
                                className="mb-3 fs-6"
                              >
                                <Form.Control
                                  style={{ background: "rgb(255 255 255 / 31%)" }}
                                  type="text"
                                  placeholder="Ragione Sociale"
                                  value={form.ragione_sociale}
                                  onChange={(e) =>
                                    setForm({ ...form, ragione_sociale: e.target.value })
                                  }
                                />
                              </FloatingLabel>
                              <FloatingLabel
                                controlId="floatingInput"
                                label="Email"
                                className="mb-3 fs-6"
                              >
                                <Form.Control
                                  style={{ background: "rgb(255 255 255 / 31%)" }}
                                  type="email"
                                  placeholder="Email"
                                  value={form.email}
                                  onChange={(e) =>
                                    setForm({ ...form, email: e.target.value })
                                  }
                                />
                              </FloatingLabel> 
                              <div className="d-flex align-items-baseline">
                                <Form.Check
                                  style={{
                                    fontSize: 18,
                                    color: "grey",
                                    display: "flex",
                                    alignItems: "baseline",
                                  }}
                                  className="mb-3 fs-6"
                                  type="checkbox"
                                  onChange={(e) =>
                                    setForm({ ...form, privacy: e.target.checked })
                                  }
                                />
                                <p
                                  style={{
                                    fontSize: 18,
                                    color: "grey",
                                    display: "flex",
                                    alignItems: "baseline",
                                    marginLeft: 5,
                                    marginBottom: 0,
                                  }}
                                >
                                  Accetto i{" "}
                                  <a
                                    className="text-dark mx-1 fs-6"
                                    href="/condizioni-di-servizio"
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    {" "}
                                    termini e le condizioni di servizio
                                  </a>
                                </p>
                              </div>
                            </>
                          )} 
                        </Form>
                        {alert && (
                          <Alert
                            style={{ fontSize: 16 }}
                            key={"danger"}
                            variant={"danger"}
                            className="mt-1 w-100"
                          >
                            {message}
                          </Alert>
                        )}
                      </Card.Title>
                      <div className="d-flex align-items-center flex-column"> 
                          <Button
                            className="mt-1 mb-1 myButton"
                            variant="contained"
                            onClick={() => verifyUsernameEmail()}
                          >
                            Continua
                          </Button> 
                      </div>
                    </Card.Body>
                  </Card> 
                </div>
              </motion.div>
          </AnimatePresence> 
        </div>
      )} 
      {step === 2 && (
        <div className={` row d-flex align-items-center ${isMobile ? "p-4" : "vh-100" }`}>  
          <AnimatePresence AnimatePresence exitBeforeEnter>
              <motion.div
                key="step2"
                initial={{ opacity: 0, x: 300 }}
                animate={{ opacity: 1, x: 0 }}
                exit={{ opacity: 0, x: 300 }}
                transition={{ duration: 0.5 }}
              >
                <Card style={{ width: '100%', background: "rgba(151, 151, 151, 0.468)" }}>
                  <Card.Body>
                    <div className="w-100 d-flex justify-content-center mb-3">
                      <img
                        onClick={() => (window.location.href = "/")}
                        src={logoAppius}
                        alt=""
                        style={{
                          padding: 9,
                          width: 250,
                          cursor: "pointer",
                          background: "rgb(255 255 255 / 42%)",
                          borderTopRightRadius: 50,
                          borderBottomLeftRadius: 10,
                        }}
                      />
                    </div>
                    <Card.Title className="d-flex justify-content-center align-items-center">
                      <h2 className="text-dark mb-2">Seleziona un pacchetto:</h2>
                    </Card.Title> 
                    <Card.Body className="d-flex flex-column align-items-center">
                    <div className="row d-flex align-items-center">
                        {pacchetti.length > 0 &&
                            pacchetti.map((pacchetto, index) => {
                                const features = JSON.parse(pacchetto.features);
                                return (
                                    <div
                                        className="col-sm-12 col-lg-4 p-2 background-none"
                                        key={pacchetto.id}
                                        style={{cursor: "pointer"}}
                                        onClick={() => {
                                          setActive(pacchetto.id)
                                        }}
                                    >
                                        <div
                                            style={{
                                                height:
                                                    pacchetto.in_evidenza ===
                                                    "1"
                                                        ? 450
                                                        : 400,
                                                borderRadius: "10px",
                                                boxShadow: active === pacchetto.id ? "rgba(0, 0, 150, 1) 0px 5px 15px" : "none",
                                                borderColor: active === pacchetto.id ? "rgba(0, 0, 150, 1)" : "transparent",
                                            }}
                                            className="card mb-5 mb-lg-0"
                                        >  
                                            <div 
                                                style={{ height: "100%" }}
                                            >
                                                <video
                                                    autoPlay
                                                    loop
                                                    muted
                                                    playsInline
                                                    className="video-price" 
                                                    src={
                                                        pacchetto.nome ===
                                                        "Small"
                                                            ? small
                                                            : pacchetto.nome ===
                                                              "Medium"
                                                            ? medium
                                                            : large
                                                    }
                                                    alt=""
                                                    style={{
                                                        position: "absolute",
                                                        top: 0,
                                                        left: 0,
                                                        height: "100%",
                                                        width: "100%",
                                                        objectFit: "cover",
                                                        zIndex: 0,
                                                        borderRadius: "10px",
                                                    }}
                                                />
                                              <div
                                                  style={{
                                                      position: "absolute",
                                                      backgroundColor:  "rgba(0,0,0,0.5)",
                                                      top: 0,
                                                      left: 0,
                                                      width: "100%",
                                                      height: "100%",
                                                      objectFit: "cover",
                                                      zIndex: 1,
                                                      borderRadius: "10px",
                                                  }}
                                              />
                                              <div
                                                  className="card-body position-relative"
                                                  style={{ height: "100%" }}
                                              >
                                                  {pacchetto.in_evidenza ===
                                                      "1" && (
                                                      <img
                                                          src={bestSeller}
                                                          alt="best seller"
                                                          style={{
                                                              position:
                                                                  "absolute",
                                                              top: -50,
                                                              right: -26,
                                                              width: "40%",
                                                              zIndex: 9,
                                                          }}
                                                      />
                                                  )}
                                                  <div>
                                                      <h3 className="card-title text-uppercase text-center text-light m-0">
                                                          {pacchetto.nome}
                                                      </h3>
                                                      <p className="card-price text-center text-light m-0">
                                                          <span>
                                                              1° mese{" "}
                                                          </span>
                                                          <span className="fs-1 m-0">
                                                              {
                                                                  pacchetto.prezzo_offerta
                                                              }
                                                              €
                                                          </span>
                                                          <span className="fs-5 m-0">
                                                              {" "}
                                                              + IVA
                                                          </span>
                                                      </p>
                                                      <p className="text-center fs-6 text-light m-0">
                                                          rinnovo successivo
                                                      </p>
                                                      <p className="card-price text-center text-light m-0">
                                                          <span className="fs-5">
                                                              {
                                                                  pacchetto.prezzo
                                                              }
                                                              €
                                                          </span>
                                                          <span className="fs-5">
                                                              {" "}
                                                              + IVA
                                                          </span>
                                                      </p>
                                                  </div> 
                                                  <div style={{height:2, backgroundColor: 'gray' }} />
                                                  <div>
                                                      <h5 className="card-title text-uppercase text-center text-light fs-6">
                                                          <span style={{ fontSize: 25 }} >
                                                            { pacchetto.num_chiamate }
                                                          </span>{" "}
                                                          aziende contattate
                                                          ogni mese
                                                      </h5>
                                                  </div> 
                                                  <div style={{height:2, backgroundColor: 'gray' }} />
                                                  <div
                                                      className="d-flex flex-column justify-content-start card-title text-uppercase text-light"
                                                      style={{
                                                          fontSize: "12px",
                                                          gap: "8px",
                                                      }}
                                                  >
                                                      {pacchetto.features &&
                                                          features.map(
                                                              (
                                                                  feature,
                                                                  index
                                                              ) => {
                                                                  return (
                                                                      <span  key={ index } style={{flex:1}} className="text-center" >
                                                                          { feature }
                                                                      </span>
                                                                  );
                                                              }
                                                          )}
                                                  </div>
                                              </div>
                                            </div>  
                                        </div>
                                    </div>
                                );
                            })}
                    </div>
                      {alert && (
                        <Alert
                          style={{ fontSize: 16 }}
                          key={"danger"}
                          variant={"danger"}
                          className="mt-1 w-100"
                        >
                          {message}
                        </Alert>
                      )}
                    </Card.Body>
                    <div className="d-flex align-items-center flex-column"> 
                        <Button
                          className="mt-1 mb-1 myButton"
                          variant="contained"
                          onClick={handleRegister}
                        >
                          Registrati
                        </Button> 
                    </div>
                  </Card.Body>
                </Card> 
              </motion.div>
          </AnimatePresence> 
        </div>
      )}
    </div>
  );
}
