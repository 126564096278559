import React from "react";
import Cookies from "universal-cookie";
import { CircularProgress, Tooltip, TextField, Snackbar } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan } from "@fortawesome/free-solid-svg-icons";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import getCampagna from "../services/getCampagna";
import deleteCampagna from "../services/deleteCampagna";
import getUtenti from "../services/getUtenti";

const headCells = [
	{
		id: "nome",
		numeric: false,
		disablePadding: false,
		label: "Nome",
	},
	{
		id: "azioni",
		numeric: false,
		disablePadding: false,
		label: "Azioni",
	},
];

const cookie = new Cookies();
const type = cookie.get("tipo", { path: "/" });

let username = cookie.get("username", { path: "/" });
let token = cookie.get("token", { path: "/" });

export default class CampagneTable extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			rows: [],
			filteredRows: [],
			toastAlert: {
				open: false,
				message: "",
				severity: "success",
			},
			users: [],
		};
	}

	componentDidMount() {
		this.reload();
		this.getUsers();
	}

	componentDidUpdate(prevProps) {
		if (prevProps.selectedUser !== this.props.selectedUser) {
			this.reload();
		}
	}

	async reload() {
		const campagne = await getCampagna(username, token, this.props.selectedUser);
		if (campagne) {
			this.setState({
				rows: campagne.data,
				filteredRows: campagne.data,
				loading: false,
			});
		}
	}

	handleButtonClick = () => {
		const { history } = this.props;
		history.push("/nuova-campagna");
	};

	handleDelete = async id => {
		const res = await deleteCampagna(username, token, id);
		this.reload();
	};

	getUsers = async () => {
		const res = await getUtenti(username, token);
		if (res && res.profili && res.profili.length > 0) {
			this.setState({ users: res.profili });
		} else {
			console.log(res);
		}
	};

	render() {
		if (this.state.loading) {
			return (
				<div
					style={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						height: "100vh",
					}}
				>
					<CircularProgress />
				</div>
			);
		}

		const { filteredRows } = this.state;
		const currentRows = filteredRows;

		return (
			<div
				style={{
					width: "92%",
					marginInline: "auto",
					marginTop: "20px",
					marginBottom: "20px",
					maxHeight: "90%",
					overflowY: "hidden",
				}}
			>
				{/* TOAST ALERT */}
				<Snackbar
					anchorOrigin={{ vertical: "top", horizontal: "right" }}
					open={this.state.toastAlert.open}
					onClose={() =>
						this.setState({
							toastAlert: { open: false, message: "", severity: "success" },
						})
					}
					autoHideDuration={3000}
					key={"topright"}
				>
					<div
						style={{
							backgroundColor: this.state.toastAlert.severity === "success" ? "#4caf50" : "#f44336",
							padding: "6px 12px",
							borderRadius: "4px",
							color: "white",
						}}
					>
						{this.state.toastAlert.message}
					</div>
				</Snackbar>

				{/* SEARCH */}
				<div
					style={{
						display: "flex",
						justifyContent: "space-between",
						alignItems: "center",
					}}
				>
					<Button className="myButton" variant="contained" onClick={this.handleButtonClick}>
						Aggiungi Campagna
					</Button>
				</div>

				{/* TABLE */}
				<TableContainer component={Paper} sx={{ overflow: "auto", height: "80vh" }}>
					<Table s ped bordered hover responsive>
						<TableHead>
							<TableRow>
								{headCells.map(headCell => {
									if (headCell.admin && type !== "1") return null;
									return (
										<TableCell key={headCell.id} style={{ width: headCell.width + "px" }}>
											{headCell.label}
										</TableCell>
									);
								})}
							</TableRow>
						</TableHead>

						<TableBody>
							{currentRows.map((row, i) => (
								<TableRow key={row.id_campagna}>
									{/* NOME COMPLETO */}
									<TableCell
										sx={{ padding: "5px 10px", input: { cursor: "pointer" } }}
										onClick={() => {
											const { history } = this.props;
											history.push(`/modifica-campagna/${row.id_campagna}`);
										}}
									>
										<TextField
											InputProps={{
												disableUnderline: true,
												readOnly: true,
											}}
											sx={{ width: "fit-content" }}
											defaultValue={row.nome}
										/>
									</TableCell>

									{/* AZIONE */}
									<TableCell sx={{ padding: "5px 10px" }}>
										<Tooltip title="Elimina account" placement="top">
											<Button
												onClick={() =>
													window.confirm(
														`Sei sicuro di voler eliminare la campagna ${row.nome}?`
													)
														? this.handleDelete(row.id_campagna)
														: null
												}
												variant="text"
												color="inherit"
											>
												<FontAwesomeIcon size={"md"} icon={faTrashCan} />
											</Button>
										</Tooltip>
									</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			</div>
		);
	}
}
