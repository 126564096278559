import React, { useState } from "react";
import _debounce from "lodash/debounce";
import { Input, InputLabel } from "@mui/material";
import getMaps from '../../services/getMaps';
import Cookies from 'universal-cookie';

const cookies = new Cookies();
const username = cookies.get('username', { path: '/' });
const token = cookies.get('token', { path: '/' });

const MapsInput = ({ onClick, label }) => {
  const [locations, setLocations] = useState([]);
  const [value, setValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [debouncedGetLocations] = useState(() => _debounce(getLocations, 500));

  async function getLocations(address) {
    if (address.length < 3) {
      setLocations([]);
      return;
    }

    setLoading(true);

    const res = await getMaps(username, token, address);
    if (res) {
      setLocations(res);
    } else {
      setLocations([]);
    }


    setLoading(false);
  }

  function handleInputChange(e) {
    setValue(e.target.value);
    debouncedGetLocations(e.target.value);
  }

  function getDescription(location) {
    if(location.types && location.types.length > 0 && location.types[0].startsWith("administrative_area_level")) {
      return location.description + " (Area)";
    }

    return location.description;
  }

  return (
    <div className="position-relative mb-3">
      <InputLabel htmlFor="localita">
        {label}
      </InputLabel>
      <Input
          label={label}
          variant="standard"
          value={value}
          fullWidth
          onChange={handleInputChange}
      />
      <div className="position-absolute bg-white w-100 top-0 start-0 end-0" style={{ zIndex: 100, transform: "translateY(60px)" }}>
        {locations.length > 0 && locations.map((location, index) => (
          <div
            key={index}
            style={{
              padding: "10px",
              borderBottom: "1px solid #ccc",
              cursor: "pointer",
            }}
            onClick={() => {
              setValue("");
              setLocations([]);
              onClick(getDescription(location));
            }}
          >
            {getDescription(location)}
          </div>
        ))}
        {!loading && locations.length === 0 && value.length > 2 && (
          <div style={{ padding: "10px" }}>Nessun risultato</div>
        )}
      </div>
    </div>
  );
};

export default MapsInput;
