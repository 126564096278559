import React from "react";
import Cookies from "universal-cookie";

//MUI
import { Box, CircularProgress, DialogActions, FormControl, Grid, MenuItem, Tooltip } from "@material-ui/core";
import InputLabel from "@mui/material/InputLabel";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";

//API
import editList from "../../services/editList";
import deleteList from "../../services/deleteList";
import addLista from "../../services/addLista";
import filterList from "../../services/filterList";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTrashCan } from "@fortawesome/free-solid-svg-icons";

import { Alert, Select, Stack } from "@mui/material";
import { Snackbar, TextField } from "@material-ui/core";

import { Dialog, DialogContent, DialogContentText, DialogTitle, Slide } from "@mui/material";
import getUtenti from "../../services/getUtenti";

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

const cookie = new Cookies();
const username = cookie.get("username", { path: "/" });
const token = cookie.get("token", { path: "/" });

const headCells = [
	{
		id: "name",
		numeric: false,
		disablePadding: false,
		label: "Nome",
	},
	{
		id: "name_cliente",
		numeric: false,
		disablePadding: false,
		label: "Nome lista",
	},
	{
		id: "username",
		numeric: false,
		disablePadding: false,
		label: "Utente",
	},
	{
		id: "stato",
		numeric: false,
		disablePadding: false,
		label: "stato",
	},
	{
		id: "actions",
		numeric: false,
		disablePadding: false,
		label: "Azioni",
	},
];

const inputPadding = { style: { padding: 8, width: "fit-content", minWidth: "200px" } };

export default class ListTable extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			rows: [],
			page: 1,
			total: 0,
			filter: "",
			modal: {
				show: false,
				fullName: "",
				id: undefined,
			},
			modalText: {
				show: false,
				id: 0,
				content: "",
			},
			toastAlert: {
				open: false,
				message: "",
				severity: "",
			},
			utenti: [],
			utente: 0,
			modalNuovaLista: false,
		};
	}

	componentDidMount() {
		this.reload();
	}

	getUsers = async () => {
		const username = cookie.get("username", { path: "/" });
		const token = cookie.get("token", { path: "/" });
		const res = await getUtenti(username, token);
		console.log("ciao", res);
		if (res && res.profili && res.profili.length > 0) {
			this.setState({ utenti: res.profili });
		} else {
			console.log(res);
		}
	};

	async reload() {
		await filterList(username, token, this.state.filter, this.props.selectedUser).then(res => {
			if (res["result"] === "KO") {
				this.setState({
					toastAlert: {
						open: true,
						message: "Errore durante il caricamento della lista",
						severity: "error",
					},
				});
				return;
			}

			this.setState({ rows: res.liste });
		});
		this.getUsers();
	}

	handleAddLista = () => {
		this.setState({
			modalNuovaLista: true,
			new_list: {
				name: "",
				name_cliente: "",
				user_id: 0,
				stato: 0,
			},
		});
	};

	cancel = () => {
		let modal = this.state.modal;
		modal.show = false;
		this.setState({ modal });
	};

	handleFilter = async filter => {
		let res = await filterList(username, token, filter, this.props.selectedUser);
		this.setState({ rows: res.liste, filter });
	};

	handleDelete = async id => {
		let res = await deleteList(username, token, id);
		if (res.result === "ok") {
			await filterList(username, token, this.state.filter, this.props.selectedUser).then(res => {
				if (res["result"] === "KO") {
					this.setState({
						toastAlert: {
							open: true,
							message: "Errore durante il caricamento della lista",
							severity: "error",
						},
					});
					return;
				} else {
					this.setState({
						toastAlert: {
							open: true,
							message: "Lista eliminata con successo",
							severity: "success",
						},
						rows: res.liste,
					});
				}
			});
		}
	};

	handleSave = async () => {
		const valuesArray = Object.values(this.state.new_list);
		const hasEmptyValue = valuesArray
			.map(value => {
				return value === "";
			})
			.includes(true);

		if (hasEmptyValue) {
			this.setState({
				toastAlert: {
					open: true,
					message: "Compila tutti i campi",
					severity: "error",
				},
				modalNuovaLista: false,
			});
			return;
		}

		let res = await addLista(
			username,
			token,
			this.state.new_list.name,
			this.state.new_list.name_cliente,
			this.state.new_list.user_id,
			this.state.new_list.stato
		);
		if (res.result === "ok") {
			await filterList(username, token, this.state.filter, this.props.selectedUser).then(res => {
				if (res["result"] === "KO") {
					this.setState({
						toastAlert: {
							open: true,
							message: "Errore durante il caricamento della lista",
							severity: "error",
						},
					});
					return;
				} else {
					this.reload();
					this.setState({
						toastAlert: {
							open: true,
							message: "Lista aggiunta con successo",
							severity: "success",
						},
						modalNuovaLista: false,
					});
				}
			});
		} else {
			this.setState({
				toastAlert: {
					open: true,
					message: "Errore durante il caricamento della lista.",
					severity: "error",
				},
			});
		}
	};

	componentDidUpdate(prevProps) {
		if (this.props.selectedUser !== prevProps.selectedUser) {
			this.handleFilter(this.state.filter, this.props.selectedUser);
		}
	}

	render() {
		if (this.state.loading) {
			return (
				<div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
					<CircularProgress />
				</div>
			);
		}

		return (
			<div
				style={{
					width: "96%",
					marginInline: "auto",
					marginTop: "20px",
					marginBottom: "20px",
					maxHeight: "90%",
					overflow: "hidden",
				}}
			>
				<Stack spacing={2} sx={{ width: "100%" }}>
					<Snackbar
						open={this.state.toastAlert.open}
						autoHideDuration={6000}
						onClose={this.handleCloseAlert}
						anchorOrigin={{ horizontal: "right", vertical: "top" }}
					>
						<Alert
							onClose={this.handleCloseAlert}
							severity={this.state.toastAlert.severity}
							sx={{ width: "100%" }}
						>
							{this.state.toastAlert.message}
						</Alert>
					</Snackbar>
				</Stack>
				{this.state.modalNuovaLista && (
					<Dialog
						maxWidth="lg"
						fullWidth={true}
						open={this.state.modalNuovaLista}
						TransitionComponent={Transition}
						keepMounted
						onClose={() => this.setState({ modalNuovaLista: false })}
						aria-describedby="alert-dialog-slide-description"
					>
						<DialogTitle>Aggiungi una nuova lista</DialogTitle>
						<DialogContent>
							<DialogContentText id="alert-dialog-slide-description">
								Compila tutti i campi per aggiungere una nuova lista
							</DialogContentText>
						</DialogContent>
						<Grid container spacing={2}>
							<Grid item xs={12} lg={6}>
								<Box paddingX={4} paddingY={1}>
									<TextField
										label="Nome Lista"
										variant="standard"
										value={this.state.new_list.name}
										onChange={event => {
											let new_list = this.state.new_list;
											new_list.name = event.target.value;
											this.setState({ new_list });
										}}
										fullWidth
									/>
								</Box>
							</Grid>
							<Grid item xs={12} lg={6}>
								<Box paddingX={4} paddingY={1}>
									<TextField
										label="Nome Cliente"
										variant="standard"
										value={this.state.new_list.name_cliente}
										onChange={event => {
											let new_list = this.state.new_list;
											new_list.name_cliente = event.target.value;
											this.setState({ new_list });
										}}
										fullWidth
									/>
								</Box>
							</Grid>
							<Grid item xs={12} lg={6}>
								<Box paddingX={4} paddingY={1}>
									<FormControl fullWidth>
										<InputLabel>Seleziona utente</InputLabel>
										<Select
											label="Utente"
											variant="standard"
											value={this.state.new_list.user_id}
											onChange={event => {
												let new_list = this.state.new_list;
												new_list.user_id = event.target.value;
												this.setState({ new_list });
											}}
											fullWidth
										>
											{this.state.utenti.map((user, i) => (
												<MenuItem value={user.id}>
													{user.nome_azienda} - {user.nome} {user.cognome}
												</MenuItem>
											))}
										</Select>
									</FormControl>
								</Box>
							</Grid>
							<Grid item xs={12} lg={6}>
								<Box paddingX={4} paddingY={1}>
									<FormControl fullWidth>
										<InputLabel>Seleziona stato della lista</InputLabel>
										<Select
											label="Stato"
											variant="standard"
											value={this.state.new_list.stato}
											onChange={event => {
												let new_list = this.state.new_list;
												new_list.stato = event.target.value;
												this.setState({ new_list });
											}}
											fullWidth
										>
											<MenuItem value={"Caricato"}>Caricato</MenuItem>
											<MenuItem value={"Non caricato"}>Non caricato</MenuItem>
											<MenuItem value={"Errore"}>Errore</MenuItem>
										</Select>
									</FormControl>
								</Box>
							</Grid>
						</Grid>
						<DialogActions>
							<Button variant="contained" onClick={() => this.setState({ modalNuovaLista: false })}>
								Chiudi
							</Button>
							<Button variant="contained" className="myButton" onClick={this.handleSave}>
								Salva
							</Button>
						</DialogActions>
					</Dialog>
				)}

				{/* HEADER */}
				<div className="d-flex align-items-center justify-content-between">
					<div className="d-flex align-items-center">
						<TextField
							label="Cerca"
							variant="standard"
							className="p-0 me-2 mb-2"
							onChange={event => {
								this.setState({ filter: event.target.value });
							}}
							onKeyDown={event => {
								if (event.key === "Enter") {
									this.handleFilter(event.target.value, this.props.selectedUser);
								}
							}}
							onBlur={event => {
								this.handleFilter(event.target.value, this.props.selectedUser);
							}}
						/>
					</div>
					<Button variant="contained" className="myButton" onClick={this.handleAddLista}>
						<FontAwesomeIcon icon={faPlus} style={{ marginRight: "0.5rem" }} />
						Aggiungi Lista
					</Button>
				</div>
				{/* TABLE */}
				<TableContainer component={Paper} sx={{ overflow: "auto", height: "80vh" }}>
					<Table striped bordered hover responsive>
						<TableHead>
							<TableRow>
								{headCells.map(headCell => (
									<TableCell key={headCell.id}>{headCell.label}</TableCell>
								))}
							</TableRow>
						</TableHead>
						<TableBody>
							{this.state.rows.length > 0 &&
								this.state.rows.map((row, i) => (
									<TableRow key={row.id}>
										<TableCell>
											<TextField
												{...inputPadding}
												value={row.name}
												onChange={event => {
													let rows = this.state.rows;
													rows[i].name = event.target.value;
													this.setState({ rows });
												}}
												onBlur={() => {
													editList(username, token, row).then(res => {
														if (res.result === "ok") {
															this.setState({
																toastAlert: {
																	open: true,
																	message: "Lista modificata con successo",
																	severity: "success",
																},
															});
														} else {
															this.setState({
																toastAlert: {
																	open: true,
																	message: "Errore durante la modifica della lista",
																	severity: "error",
																},
															});
														}
													});
												}}
												onKeyPress={event => {
													if (event.key === "Enter") {
														editList(username, token, row).then(res => {
															if (res.result === "ok") {
																this.setState({
																	toastAlert: {
																		open: true,
																		message: "Lista modificata con successo",
																		severity: "success",
																	},
																});
															} else {
																this.setState({
																	toastAlert: {
																		open: true,
																		message:
																			"Errore durante la modifica della lista",
																		severity: "error",
																	},
																});
															}
														});
													}
												}}
											/>
										</TableCell>
										<TableCell>
											<TextField
												{...inputPadding}
												value={row.name_cliente}
												onChange={event => {
													let rows = this.state.rows;
													rows[i].name_cliente = event.target.value;
													this.setState({ rows });
												}}
												onBlur={() => {
													editList(username, token, row).then(res => {
														if (res.result === "ok") {
															this.setState({
																toastAlert: {
																	open: true,
																	message: "Lista modificata con successo",
																	severity: "success",
																},
															});
														} else {
															this.setState({
																toastAlert: {
																	open: true,
																	message: "Errore durante la modifica della lista",
																	severity: "error",
																},
															});
														}
													});
												}}
												onKeyPress={event => {
													if (event.key === "Enter") {
														editList(username, token, row).then(res => {
															if (res.result === "ok") {
																this.setState({
																	toastAlert: {
																		open: true,
																		message: "Lista modificata con successo",
																		severity: "success",
																	},
																});
															} else {
																this.setState({
																	toastAlert: {
																		open: true,
																		message:
																			"Errore durante la modifica della lista",
																		severity: "error",
																	},
																});
															}
														});
													}
												}}
											/>
										</TableCell>
										<TableCell>{row.username}</TableCell>
										<TableCell>
											<Select
												label="Stato"
												variant="standard"
												value={row.stato}
												onChange={event => {
													let rows = this.state.rows;
													rows[i].stato = event.target.value;
													this.setState({ rows });
													editList(username, token, rows[i]).then(res => {
														if (res.result === "ok") {
															this.setState({
																toastAlert: {
																	open: true,
																	message: "Lista modificata con successo",
																	severity: "success",
																},
															});
														} else {
															this.setState({
																toastAlert: {
																	open: true,
																	message: "Errore durante la modifica della lista",
																	severity: "error",
																},
															});
														}
													});
												}}
											>
												<MenuItem value={"Caricato"}>Caricato</MenuItem>
												<MenuItem value={"Non caricato"}>Non caricato</MenuItem>
												<MenuItem value={"Errore"}>Errore</MenuItem>
											</Select>
										</TableCell>
										<TableCell>
											<Tooltip title="Elimina utente">
												<Button
													onClick={() =>
														window.confirm(
															`Sei sicuro di voler eliminare la lista ${row.name} ?`
														)
															? this.handleDelete(row.id)
															: null
													}
													color="inherit"
													variant="text"
												>
													<FontAwesomeIcon size={"lg"} icon={faTrashCan} />
												</Button>
											</Tooltip>
										</TableCell>
									</TableRow>
								))}
						</TableBody>
					</Table>
				</TableContainer>
			</div>
		);
	}
}
