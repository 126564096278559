import Constants from '../utils/consts';

var http = Constants.http;
var qs = require('querystring');


export default async function deleteAccount(uname, token, id) {
    return new Promise(function (resolve, reject) {

        var options = {
        'method': 'POST',
        'hostname': Constants.ipAddress,
        'path': Constants.apisFolder + 'delete/account/interni/id/',
        'headers': {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Cookie': 'uid=e02675a1a7516909792f6850dffcd5fe'
        },
        'maxRedirects': 20
        };

        var req = http.request(options, function (res) {
        var chunks = [];

        res.on("data", function (chunk) {
            chunks.push(chunk);
        });

        res.on("end", function (chunk) {
            var body = Buffer.concat(chunks);
            const resp = JSON.parse(body.toString());
            resolve(resp);
        });

        res.on("error", function (error) {
            console.error(error);
        });
        });

        var postData = qs.stringify({
            'uname': uname.trim(),
            'token': token.trim(),
            'id': id
        });
        req.write(postData);

        req.end();

        
    });
}
