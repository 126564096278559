import React from "react";
import Cookies from "universal-cookie";
import {
	CircularProgress,
	Tooltip,
	TextField,
	Snackbar,
	Select,
	MenuItem,
	FormControl,
	Input,
} from "@material-ui/core";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import StopCircleIcon from "@mui/icons-material/StopCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import getAccountSearch from "../../services/getAccountSearch";
import deleteAccount from "../../services/deleteAccount";
import addAccount from "../../services/addAccount";
import editAccount from "../../services/editAccount";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Icon from "@mui/material/Icon";
import editDeploy from "../../services/editDeploy";
import editDeployAll from "../../services/editDeployAll";
import getIP from "../../services/getIP";
import getCampagna from "../../services/getCampagna";
import moment from "moment";

const headCells = [
	{
		id: "limite_settimanale",
		numeric: false,
		disablePadding: false,
		label: "Limite Settimanale",
	},
	{
		id: "nome_completo",
		numeric: false,
		disablePadding: false,
		label: "Nome",
	},
	{
		id: "email",
		numeric: false,
		disablePadding: false,
		label: "Email",
	},
	{
		id: "password_account",
		numeric: false,
		disablePadding: false,
		label: "Password Account",
	},
	{
		id: "campagne",
		numeric: false,
		disablePadding: false,
		label: "Campagne",
	},
	{
		id: "max_contatti",
		numeric: false,
		disablePadding: false,
		label: "Max Contatti/Day",
	},
	{
		id: "ip_proxy",
		numeric: false,
		disablePadding: false,
		label: "IP Proxy",
		admin: true,
	},
	{
		id: "proxy_auth",
		numeric: false,
		disablePadding: false,
		label: "Proxy Auth",
		admin: true,
	},
	{
		id: "password_gmail_yahoo",
		numeric: false,
		disablePadding: false,
		label: "Password Gmail/Yahoo",
		admin: true,
	},
	{
		id: "isRunning",
		numeric: false,
		disablePadding: false,
		label: "Stato",
	},
	{
		id: "azione",
		numeric: false,
		disablePadding: false,
		label: "Azione",
	},
	{
		id: "needs_deploy",
		numeric: false,
		disablePadding: false,
		label: "Deploy",
		admin: true,
	},
	{
		id: "last_keepalive",
		numeric: false,
		disablePadding: false,
		label: "Last Timestamp",
		admin: true,
		width: 150,
	},
];

const cookie = new Cookies();
const username = cookie.get("username", { path: "/" });
const token = cookie.get("token", { path: "/" });
const type = cookie.get("tipo", { path: "/" });

export default class AccountTable extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			modalMessaggi: {
				show: false,
				id: undefined,
			},
			rows: [],
			campagne: [],
			filteredRows: [],
			modalUpdate: {
				show: false,
				id: undefined,
			},
			toastAlert: {
				open: false,
				message: "",
				severity: "success",
			},
			modal: {
				show: false,
				fullName: "",
				id: undefined,
			},
			filter: "",
			page: 1,
			maxPage: 1,
			stats: {},
			usersCall: [],
			filterStato: "Tutti",
			filterFollowUp: "Tutti",
			fromSelect: false,
			user: 0,
			ip_address: [],
			isFiltering: false,
		};
	}

	componentDidMount() {
		this.reload();
	}

	async reload() {
		await getIP(username, token).then(res => {
			if (res["result"] === "KO") {
				this.setState({
					toastAlert: {
						open: true,
						message: "Errore durante il caricamento degli IP",
						severity: "error",
					},
				});
				return;
			}
			this.setState({ ip_address: res });
		});
		await getCampagna(username, token, this.props.selectedUser).then(res => {
			if (res["result"] === "KO") {
				this.setState({
					toastAlert: {
						open: true,
						message: "Errore durante il caricamento delle campagne",
						severity: "error",
					},
				});
				return;
			} 
			this.setState({ campagne: res.data });
		});

		await getAccountSearch(username, token, this.state.filter, this.props.selectedUser).then(res => {
			if (res["result"] === "KO") {
				this.setState({
					toastAlert: {
						open: true,
						message: "Errore durante la ricerca degli account",
						severity: "error",
					},
				});
				return;
			}
			this.setState({ rows: res.data, filteredRows: res.data /*, maxPage*/ });
		});
	}

	handleSearch = filter => {
		this.setState({ filter: filter, isFiltering: true });
		getAccountSearch(username, token, filter, this.props.selectedUser).then(res => {
			if (res["result"] === "KO") {
				this.setState({
					toastAlert: {
						open: true,
						message: "Errore durante la ricerca degli account",
						severity: "error",
					},
				});
				return;
			}
			// const maxPage = Math.ceil(res.data.length / ROWS_PER_PAGE);
			this.setState({ rows: res.data, filteredRows: res.data /*, maxPage*/ });
		});
	};

	async handleDeploy(id) {
		const res = await editDeploy(username, token, this.props.selectedUser);
		if (res["result"] === "KO") {
			this.setState({
				toastAlert: {
					open: true,
					message: "Errore durante il deploy dell'account",
					severity: "error",
				},
			});
			return;
		} else {
			this.setState({
				toastAlert: {
					open: true,
					message: "Account deployato con successo",
					severity: "success",
				},
			});
		}
		this.reload();
	}

	async handleDeployAll() {
		editDeployAll(username, token).then(res => {
			if (res["result"] === "KO") {
				this.setState({
					toastAlert: {
						open: true,
						message: "Errore durante il deploy degli account",
						severity: "error",
					},
				});
				return;
			} else {
				this.setState({
					toastAlert: {
						open: true,
						message: "Accounts deployati con successo",
						severity: "success",
					},
				});
			}
			this.reload();
		});
	}

	handleChangePage = page => {
		this.setState({ page });
	};

	handleDeleteAccount = id => {
		deleteAccount(username, token, id).then(res => {
			if (res["result"] === "ok") {
				this.reload();
			} else {
				this.setState({
					toastAlert: {
						open: true,
						message: "Errore durante l'eliminazione dell'account",
						severity: "error",
					},
				});
			}
		});
	};

	handleSaveAccount = id => {
		let account = this.state.rows.filter(row => row.id === id)[0];

		if (!account.richieste_attive) {
			account.richieste_attive = 0;
		}

		if (!account.max_contatti) {
			account.max_contatti = 0;
		}

		account.isClienti = "1";

		addAccount(username, token, account)
			.then(res => {
				if (res["result"] === "ok") {
					this.reload();
					this.setState({
						toastAlert: {
							open: true,
							message: "Account salvato con successo",
							severity: "success",
						},
					});
				} else {
					this.setState({
						toastAlert: {
							open: true,
							message: "Errore durante il salvataggio dell'account",
							severity: "error",
						},
					});
				}
			})
			.catch(err => {
				console.log(err);
			});
	};

	handleAddRow = () => {
		const { rows } = this.state;
		const id = rows.map(row => row.id).reduce((max, cur) => Math.max(max, cur), 0) + 1;
		const newRow = {
			id: id,
			nome_completo: "",
			sigla: "",
			email: "",
			isRunning: "",
			weekly_limit: "",
			id_campagna: "",
			location_fallback: "",
			ruolo_fallback: "",
			password_account: "",
			ip_proxy: "",
			proxy_auth: "",
			login_cookie: "",
			password_gmail_yahoo: "",
			tipologia: "",
			richieste_attive: "",
			max_contatti: "",
			new: true,
		};
		this.setState({ rows: [newRow, ...rows], filteredRows: [newRow, ...rows] });
	};

	handleEditAccount = async id => {
		let account = this.state.rows.filter(row => row.id === id)[0];

		if (!account.richieste_attive) {
			account.richieste_attive = 0;
		}

		if (!account.max_contatti) {
			account.max_contatti = 0;
		}

		await editAccount(username, token, account)
			.then(res => {
				if (res["result"] === "ok") {
					this.reload();
					this.setState({
						toastAlert: {
							open: true,
							message: "Account salvato con successo",
							severity: "success",
						},
					});
				} else {
					this.setState({
						toastAlert: {
							open: true,
							message: "Errore durante il salvataggio dell'account",
							severity: "error",
						},
					});
				}
			})
			.catch(err => {
				console.log(err);
			});
	};

	componentDidUpdate(prevProps) {
		if (prevProps.selectedUser !== this.props.selectedUser) {
			this.reload();
		}
	}

	render() {
		if (this.state.loading) {
			return (
				<div
					style={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						height: "100vh",
					}}
				>
					<CircularProgress />
				</div>
			);
		}

		const { filteredRows } = this.state;
		const currentRows = filteredRows;

		console.log(currentRows);

		return (
			<div
				style={{
					width: "92%",
					marginInline: "auto",
					marginTop: "20px",
					marginBottom: "20px",
					maxHeight: "90%", 
				}}
			>
				{/* TOAST ALERT */}
				<Snackbar
					anchorOrigin={{ vertical: "top", horizontal: "right" }}
					open={this.state.toastAlert.open}
					onClose={() =>
						this.setState({
							toastAlert: { open: false, message: "", severity: "success" },
						})
					}
					autoHideDuration={3000}
					key={"topright"}
				>
					<div
						style={{
							backgroundColor: this.state.toastAlert.severity === "success" ? "#4caf50" : "#f44336",
							padding: "6px 12px",
							borderRadius: "4px",
							color: "white",
						}}
					>
						{this.state.toastAlert.message}
					</div>
				</Snackbar>

				{/* SEARCH */}
				<div
					style={{
						display: "flex",
						justifyContent: "space-between",
						alignItems: "center",
					}}
				>
					<div className="d-flex">
						<TextField
							label="Cerca per nome/email"
							style={{ marginBottom: "20px" }}
							onChange={e => this.handleSearch(e.target.value.toLowerCase())}
						/>
					</div>
					<Button className="myButton" variant="contained" onClick={this.handleAddRow}>
						Aggiungi account
					</Button>
				</div>

				{/* TABLE */}
				<TableContainer component={Paper} sx={{  height: "80vh" }}>
					<Table s ped bordered hover responsive>
						<TableHead>
							<TableRow>
								{headCells.map(headCell => {
									console.log(headCell)
									if (headCell.admin && type !== "1") return null;
									return (
										<TableCell key={headCell.id} style={{ width: headCell.width + "px" }}>
											{headCell.label}
										</TableCell>
									);
								})}
							</TableRow>
						</TableHead>

						<TableBody>
							{currentRows.map((row, i) => (
								<TableRow key={row.id}>
									{/* limite connessioni */}
									<TableCell>
										<Tooltip
											title={
												row.weekly_limit !== null
													? "Raggiunto il limite delle connessioni settimanali"
													: "Connessioni settimanali disponibili"
											}
											className="d-flex justify-content-center align-items-center"
										>
											<Icon style={{ color: row.weekly_limit !== null ? "red" : "green" }}>
												{row.weekly_limit !== null ? <StopCircleIcon /> : <CheckCircleIcon />}
											</Icon>
										</Tooltip>
									</TableCell>

									{/* NOME COMPLETO */}
									<TableCell sx={{ padding: "5px 10px" }}>
										<TextField
											InputProps={{
												disableUnderline: true,
											}}
											sx={{ width: "fit-content" }}
											defaultValue={row.nome_completo}
											onChange={e => {
												this.setState({
													rows: this.state.rows.map(r => {
														if (r.id === row.id) {
															r.nome_completo = e.target.value;
														}
														return r;
													}),
												});
											}}
											onBlur={async () => {
												if (!row.new) {
													await this.handleEditAccount(row.id);
												}
											}}
											onKeyPress={async e => {
												if (e.key === "Enter" && !row.new) {
													await this.handleEditAccount(row.id);
												}
											}}
										/>
									</TableCell>

									{/* EMAIL */}
									<TableCell sx={{ padding: "5px 10px" }}>
										<TextField
											InputProps={{
												disableUnderline: true,
											}}
											defaultValue={row.email}
											onChange={e => {
												this.setState({
													rows: this.state.rows.map(r => {
														if (r.id === row.id) {
															r.email = e.target.value;
														}
														return r;
													}),
												});
											}}
											onBlur={async () => {
												if (!row.new) {
													await this.handleEditAccount(row.id);
												}
											}}
											onKeyPress={async e => {
												if (e.key === "Enter" && !row.new) {
													await this.handleEditAccount(row.id);
												}
											}}
										/>
									</TableCell>

									{/* PASSWORD ACCOUNT */}
									<TableCell sx={{ padding: "5px 10px" }}>
										<FormControl sx={{ m: 1, width: "25ch" }} variant="standard">
											<Input
												id="standard-adornment-password"
												inputProps={{
													disableUnderline: true,
												}}
												defaultValue={row.password_account}
												onChange={e => {
													this.setState({
														rows: this.state.rows.map(r => {
															if (r.id === row.id) {
																r.password_account = e.target.value;
															}
															return r;
														}),
													});
												}}
												onBlur={async () => {
													if (!row.new) {
														await this.handleEditAccount(row.id);
													}
												}}
												onKeyPress={async e => {
													if (e.key === "Enter" && !row.new) {
														await this.handleEditAccount(row.id);
													}
												}}
												type={row.inputType ? row.inputType : "password"}
												endAdornment={
													<InputAdornment position="end">
														<IconButton
															aria-label="toggle password visibility"
															onClick={() => {
																this.setState({
																	rows: this.state.rows.map(r => {
																		if (r.id === row.id) {
																			r.inputType =
																				r.inputType === "text"
																					? "password"
																					: "text";
																		}
																		return r;
																	}),
																});
															}}
														>
															{row.inputType === "text" ? (
																<Visibility />
															) : (
																<VisibilityOff />
															)}
														</IconButton>
													</InputAdornment>
												}
											/>
										</FormControl>
									</TableCell>

									{/* CAMPAGNE */}
									<TableCell sx={{ padding: "5px 10px" }}>
										<Select
											InputProps={{
												disableUnderline: true,
											}}
											readOnly={false}
											value={row.id_campagna === null ? "Non selezionato" : row.id_campagna}
											onChange={async e => {
												this.setState({
													rows: this.state.rows.map(r => {
														if (r.id === row.id) {
															r.id_campagna = e.target.value;
														}
														return r;
													}),
												});
												if (!row.new) {
													await this.handleEditAccount(row.id);
												}
											}}
										>
											{this.state.campagne.map((type, index) => {
												const isAssigned = currentRows.some(
													r => r.id_campagna === type.id_campagna
												);
												if (!isAssigned || type.id_campagna === row.id_campagna) {
													return (
														<MenuItem
															key={type.lead_list + " " + index}
															value={type.id_campagna}
														>
															{type.nome}
														</MenuItem>
													);
												} else {
													return null;
												}
											})}
											<MenuItem value={"Non selezionato"}>Non selezionato</MenuItem>
											<MenuItem value={0}>Tutte le campagne</MenuItem>
										</Select>
									</TableCell>

									<TableCell sx={{ padding: "5px 10px" }}>
										<TextField
											type="number"
											InputProps={{
												disableUnderline: true,
											}}
											defaultValue={row.max_contatti}
											onChange={e => {
												this.setState({
													rows: this.state.rows.map(r => {
														if (r.id === row.id) {
															r.max_contatti = e.target.value;
														}
														return r;
													}),
												});
											}}
											onBlur={async () => {
												if (!row.new) {
													await this.handleEditAccount(row.id);
												}
											}}
											onKeyPress={async e => {
												if (e.key === "Enter" && !row.new) {
													await this.handleEditAccount(row.id);
												}
											}}
										/>
									</TableCell>

									{type === "1" && (
										<>
											{/* MAX CONTATTI */}
											{/* IP PROXY */}
											<TableCell sx={{ padding: "5px 10px" }}>
												<Select
													defaultValue={row.ip_proxy}
													onChange={e => {
														this.setState({
															rows: this.state.rows.map(r => {
																if (r.id === row.id) {
																	r.ip_proxy = e.target.value;
																}
																return r;
															}),
														});
													}}
													onBlur={async () => {
														if (!row.new) {
															await this.handleEditAccount(row.id);
														}
													}}
													onKeyPress={async e => {
														if (e.key === "Enter" && !row.new) {
															await this.handleEditAccount(row.id);
														}
													}}
													InputProps={{
														disableUnderline: true,
													}}
												>
													<MenuItem key="na" value="NULL">
														N/A
													</MenuItem>
													{this.state.ip_address.map((ip, index) => {
														const isAssigned = currentRows.some(
															account => account.ip_proxy === ip.ip_address
														);

														if (!isAssigned || ip.ip_address === row.ip_proxy) {
															return (
																<MenuItem key={ip.id} value={ip.ip_address}>
																	{ip.ip_address}
																</MenuItem>
															);
														} else {
															return null;
														}
													})}
												</Select>
											</TableCell>

											{/* PROXY AUTH */}
											<TableCell sx={{ padding: "5px 10px" }}>
												<TextField
													InputProps={{
														disableUnderline: true,
													}}
													defaultValue={row.proxy_auth}
													onChange={e => {
														this.setState({
															rows: this.state.rows.map(r => {
																if (r.id === row.id) {
																	r.proxy_auth = e.target.value;
																}
																return r;
															}),
														});
													}}
													onBlur={async () => {
														if (!row.new) {
															await this.handleEditAccount(row.id);
														}
													}}
													onKeyPress={async e => {
														if (e.key === "Enter" && !row.new) {
															await this.handleEditAccount(row.id);
														}
													}}
												/>
											</TableCell>

											{/* PASSWORD GMAIL/YAHOO */}
											<TableCell sx={{ padding: "5px 10px" }}>
												<TextField
													InputProps={{
														disableUnderline: true,
													}}
													defaultValue={row.password_gmail_yahoo}
													onChange={e => {
														this.setState({
															rows: this.state.rows.map(r => {
																if (r.id === row.id) {
																	r.password_gmail_yahoo = e.target.value;
																}
																return r;
															}),
														});
													}}
													onBlur={async () => {
														if (!row.new) {
															await this.handleEditAccount(row.id);
														}
													}}
													onKeyPress={async e => {
														if (e.key === "Enter" && !row.new) {
															await this.handleEditAccount(row.id);
														}
													}}
												/>
											</TableCell>
										</>
									)}

									{/* ISRUNNING */}
									<TableCell>
										<Icon
											style={{ color: row.isRunning ? "green" : "red" }}
											className="d-flex justify-content-center align-items-center"
										>
											{row.isRunning ? <CheckCircleIcon /> : <CancelIcon />}
										</Icon>
									</TableCell>

									{/* AZIONE */}
									{!row.new ? (
										<TableCell sx={{ padding: "5px 10px" }}>
											<Tooltip title="Elimina account" placement="top">
												<Button
													onClick={() =>
														window.confirm(
															`Sei sicuro di voler eliminare l'account ${row.nome_completo}?`
														)
															? this.handleDeleteAccount(row.id)
															: null
													}
													variant="text"
													color="inherit"
												>
													<FontAwesomeIcon size={"md"} icon={faTrashCan} />
												</Button>
											</Tooltip>
										</TableCell>
									) : (
										<TableCell className="">
											<Tooltip title="Salva account" placement="top">
												<Button
													onClick={() =>
														window.confirm(
															`Sei sicuro di voler salvare l'account ${row.nome_completo}?`
														)
															? this.handleSaveAccount(row.id)
															: null
													}
													variant="contained"
													color="success"
													style={{ color: "white", marginRight: "0.6rem" }}
												>
													<FontAwesomeIcon size={"xs"} icon={faCheck} />
												</Button>
											</Tooltip>
											<Tooltip title="Elimina account" placement="top">
												<Button
													onClick={() =>
														this.setState({
															rows: this.state.rows.filter(r => r.id !== row.id),
															filteredRows: this.state.filteredRows.filter(
																r => r.id !== row.id
															),
														})
													}
													variant="contained"
													color="error"
													style={{ color: "white", marginRight: "0.6rem" }}
												>
													<FontAwesomeIcon size={"xs"} icon={faTrashCan} />
												</Button>
											</Tooltip>
										</TableCell>
									)}

									{/* needs_deploy */}
									{type === "1" && (
										<>
											<TableCell sx={{ padding: "5px 10px" }}>
												<Button
													onClick={() => {
														this.setState({ isFiltering: false });
														this.handleDeploy(row.id);
													}}
													variant="text"
													color="inherit"
												>
													<Icon
														style={{
															color:
																row.needs_deploy === "1"
																	? "green"
																	: row.needs_deploy === "0"
																	? "red"
																	: null,
														}}
														className="d-flex justify-content-center align-items-center"
													>
														{row.needs_deploy == "1" ? (
															<CheckCircleIcon />
														) : row.needs_deploy === "0" ? (
															<CancelIcon />
														) : null}
													</Icon>
												</Button>
											</TableCell>
										</>
									)}
									
									{/* last keep alive */}
									<TableCell sx={{ padding: "5px 10px" }}>
										<TextField
											InputProps={{
												disableUnderline: true,
												readOnly: true,
											}}
											defaultValue={row.last_keepalive ? moment(row.last_keepalive).format('DD-MM-YY HH:MM') : "N/A"}  
										/>
									</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			</div>
		);
	}
}
