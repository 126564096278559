import React, { useContext } from "react";
import AccountTable from "../components/admin/AccountTable";
import HeBLayout from "../components/HeBLayout";
import NotifyContext from "../context/NotifyContext";

const GestioneBot = () => {
	const { selectedUser } = useContext(NotifyContext);
	return (
		<HeBLayout>
			<AccountTable selectedUser={selectedUser} />
		</HeBLayout>
	);
};

export default GestioneBot;
