/* eslint-disable */
import React, { useEffect } from "react";
import logo from "../images/h&bdark.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons"; 

const Navbar = (props) => { 
    const [isMobile, setIsMobile] = React.useState(false);
    const [toggle, setToggle] = React.useState(false);
    const [isScroll, setIsScroll] = React.useState(false); 
    const [mainUrl, setMainUrl] = React.useState("");

    useEffect(() => {
        window.addEventListener("resize", () => handleResize());
        const url = window.location.href.split("/")[2]; 
        window.addEventListener("scroll", handleScroll);
        handleResize();
        setMainUrl(url);
        return () => {
            window.removeEventListener("resize", () => handleResize()); 
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    const handleComeFunzionaClick = () => {
        if (window.location.href === `http://${mainUrl}/`) {
            props.comeFunzionaRef.current.scrollIntoView({ behavior: "smooth" });
        } else {
            window.location.href = `http://${mainUrl}/?ref=come-funziona`; 
        }
        if (toggle && isMobile) setToggle(!toggle);
    };
    const handleContattaciClick = () => {
        if (window.location.href === `http://${mainUrl}/`) {
            props.contattaciRef.current.scrollIntoView({ behavior: "smooth" });
        } else {
            window.location.href = `http://${mainUrl}/?ref=contattaci`; 
        }
        if (toggle && isMobile) setToggle(!toggle);
    }; 
    const handleBannerClick = () => {
        if (window.location.href === `http://${mainUrl}/`) {
            props.bannerRef.current.scrollIntoView({ behavior: "smooth" });
        } else {
            window.location.href = `http://${mainUrl}`; 
        }
        if (toggle && isMobile) setToggle(!toggle);
    };
    const handlePrezziClick = () => {
        if (window.location.href === `http://${mainUrl}/`) {
            props.prezziRef.current.scrollIntoView({ behavior: "smooth" });
        } else {
            window.location.href = `http://${mainUrl}/?ref=prezzi`;
        }
        if (toggle && isMobile) setToggle(!toggle);
    };

    const handleResize = () => {
        if (window.innerWidth < 992) {
            setIsMobile(true);
        } else {
            setIsMobile(false);
        }
    };

    const handleScroll = () => {
        let element = document.getElementById("nav"); 
        if (window.scrollY === 0 && toggle) return element.classList.add("is-sticky"); 
        if (window.scrollY === 0 ) {
            setIsScroll(false);
            element.classList.remove("is-sticky");
            return
        }
        if (window.scrollY > 85) {
            setIsScroll(true);
            element.classList.add("is-sticky");
            return
        }
    }; 

    return (
        <navbar style={{ position: 'fixed', top: 0, left: 0, width: '100%', zIndex: 10}}>
            <nav id="nav" onScroll={handleScroll} className="d-flex justify-content-around align-items-center">
                <a href="/">
                    <img src={logo} alt="Appius Logo" width={200} height="auto" />
                </a>

                {isMobile && (
                    <FontAwesomeIcon
                        style={{
                            fontSize: 25,
                            color: "black",
                            cursor: "pointer",
                        }}
                        icon={faBars}
                        onClick={() => {
                            let element = document.getElementById("nav"); 
                            if(!toggle){ 
                                element.classList.add("is-sticky");
                            } else if (!isScroll && toggle){
                                element.classList.remove("is-sticky");
                            }
                            setToggle(!toggle);
                            
                        }}
                    /> ) 
                }
                {(!isMobile || toggle ) && (
                    <div className="nav-links navbar align-items-center">
                        <a
                            onClick={handleBannerClick}
                            className="textNavbar me-3 text-decoration-none"
                        >
                            Homepage
                        </a> 
                        <a
                            onClick={handleComeFunzionaClick}
                            className="textNavbar me-3 text-decoration-none"
                        >
                            Come funziona
                        </a>
                        <a
                            onClick={handlePrezziClick}
                            className="textNavbar me-3 text-decoration-none"
                        >
                            Prezzi
                        </a>
                        <a
                            onClick={handleContattaciClick}
                            className="textNavbar me-3 text-decoration-none"
                        >
                            Contattaci
                        </a>
                        <a
                            href="/blog"
                            className="textNavbar me-3 text-decoration-none"
                        >
                            Blog
                        </a> 
                        {
                            isMobile && (
                                <>
                                    <a
                                        onClick={()=> window.location.href='/register'}
                                        className="textNavbar me-3 text-decoration-none"
                                    >
                                        Registrati
                                    </a> 
                                    <a
                                        href="/login"
                                        className="textNavbar me-3 text-decoration-none"
                                    >
                                        Login
                                    </a>
                                </> 
                            )
                        } 
                    </div> 
                )
                }
                {
                    !isMobile && <div className="d-flex">
                        <div className="prenota">
                            <a
                                onClick={()=> window.location.href='/register'}
                                className="textNavbar text-decoration-none"
                            >
                                Registrati
                            </a>
                        </div> 
                        <div className="login"> 
                            <a
                                href="/login"
                                className="textNavbar text-decoration-none"
                            >
                                Login
                            </a>
                        </div>
                    </div>
                }
            </nav>
        </navbar>
    );
};
export default Navbar;
