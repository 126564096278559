import React from "react";
import UsersTable from "../components/admin/UsersTable";
import HeBLayout from "../components/HeBLayout";

const Admin = () => {
	return (
		<HeBLayout>
			<div style={{ width: '96%', marginInline: 'auto', marginTop: '20px', marginBottom: '20px', maxHeight: '90%' }}>
				<UsersTable />
			</div>
		</HeBLayout>
	)
}

export default Admin;
