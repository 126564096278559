import React from 'react' 
import ArrowRight from '../../images/arrow-right.png'
import ArrowLeft from '../../images/arrow-left.png'

const outs = [
    {
        "title": "Analizziamo il tuo business",
        "text": "Capiamo insieme quali caratteristiche hanno i tuoi clienti ideali. Quali sono il settore, la dimensione, dove si trovano, ecc…",
        "img": "analysis.png"
    },
    {
        "title": "Stiliamo una lista dei possibili clienti",
        "text": "Una volta costruita la Buyer Persona andiamo a stilare una lista dei Decision Maker che potrebbero essere interessati al tuo prodotto o servizio",
        "img": "value.png"
    },
    {
        "title": "Definiamo una strategia di approccio",
        "text": "E’ meglio un approccio formale o casual? Dritti al sodo o con cautela? Costruiamo un funnel per un contatto ottimale con il prospect.",
        "img": "planning.png"
    },
    {
        "title": "Contattiamo i prospect per te",
        "text": "Applichiamo la strategia costruita insieme: contattiamo i potenziali clienti seguendo la strategia condivisa e cominciamo a conoscerli.",
        "img": "conversation.png"
    },
    {
        "title": "Fissiamo le call",
        "text": "I prospect che manifestano interesse per il tuo prodotto o servizio vengono invitati a fissare una call con te in uno slot comodo per entrambi.",
        "img": "calendar.png"
    }
]

export default class ComeFunziona extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            mobile: false,
        }
    }

    async componentDidMount() {
        this.resize();
        window.addEventListener("resize", this.resize.bind(this));
        this.setState({loading: false});
    }

    componentWillUnmount(){
        window.removeEventListener("resize", this.resize.bind(this));
    }

    resize(){
        if (window.innerWidth < 768 && !this.state.mobile) {
            this.setState({mobile: true});
        } else if (this.state.mobile) {
            this.setState({mobile:false})
        }
    }

    render() {
        return (
            <>
                <div ref={this.props.comeFunzionaRef} style={{height:'100%'}} className="overflow-hidden sezione-100percent profileHome position-relative d-flex flex-column align-items-center">
                    <div className="row">
                        <div className="col">
                            <h2 className='text-uppercase text-color mb-4 HeeboBold'>Come Funziona</h2>
                        </div>
                    </div>
                    <div style={{width:"80%"}} className="row justify-content-center">
                        <div className="col-12">
                            {
                                outs.map((item, index) => {
                                    if (index % 2 === 0) {
                                        return(
                                            <div key={index} className="row mt-2">
                                                <div className="col-lg-6 col-md-6 d-flex justify-content-start">
                                                    <div className='service-item'>
                                                        <img width={50} height={50} src={require(`../../images/${item.img}`)} alt="" />
                                                        <div className="service-item-inner"> 
                                                            <div className="service-content">
                                                                <h4 className='text-color text-uppercase'>{item.title}</h4>
                                                                <p>{item.text}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-md-6 position-relative">
                                                { 
                                                    index !== outs.length - 1 ? (
                                                        <img style={{height:'91%', width:"47%"}} className='position-absolute bottom-0 start-0'  src={ArrowRight} alt="" />
                                                    ) : null
                                                }
                                                </div>
                                            </div> 
                                        )
                                    } else {
                                        return(
                                            <div key={index} className="row mt-2">
                                                <div className="col-lg-6 col-md-6 position-relative">
                                                { 
                                                    index !== outs.length - 1 ? (
                                                    <img style={{height:'91%', width:"47%"}} className=' position-absolute bottom-0 end-0' src={ArrowLeft} alt="" />
                                                    ) : null
                                                }
                                                </div> 
                                                <div className="col-lg-6 col-md-6 d-flex justify-content-end">
                                                    <div className='service-item'>
                                                        <img width={50} height={50} src={require(`../../images/${item.img}`)} alt="" />
                                                        <div className="service-item-inner"> 
                                                            <div className="service-content">
                                                                <h4 className='text-color text-uppercase'>{item.title}</h4>
                                                                <p>{item.text}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> 
                                        )
                                    }
                                })
                            } 
                        </div>
                    </div>
                </div>
            </>
        )
    }
}