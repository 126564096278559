import Constants from "../utils/consts";
var http = Constants.http;

var qs = require("querystring");

export default async function editListCreate(uname, token, list) {
	return new Promise(function(resolve, reject) {
		var options = {
			method: "POST",
			hostname: Constants.ipAddress,
			path: Constants.apisFolder + "edit/lead_list/create/",
			headers: {
				"Content-Type": "application/x-www-form-urlencoded",
				Cookie: "uid=e02675a1a7516909792f6850dffcd5fe",
			},
			maxRedirects: 20,
		};

		var req = http.request(options, function(res) {
			var chunks = [];

			res.on("data", function(chunk) {
				chunks.push(chunk);
			});

			res.on("end", function(chunk) {
				var body = Buffer.concat(chunks);
				const resp = JSON.parse(body.toString());
				resolve(resp);
			});

			res.on("error", function(error) {
				console.error(error);
			});
		});

		var postData = qs.stringify({
			uname: uname,
			token: token,
			id: list.id,
			name_cliente: list.name_cliente,
			stato_creazione: list.stato_creazione,
			localita: list.localita,
			localita_da_escludere: list.localita_da_escludere,
			funzioni: list.funzioni,
			numero_dipendenti: list.numero_dipendenti,
			parole_chiave: list.parole_chiave,
			anni_di_esperienza: list.anni_di_esperienza,
			settore: list.settore,
			lingua: list.lingua,
		});

		req.write(postData);

		req.end();
	});
}
