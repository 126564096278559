import React from "react";
import Filter from "./_consulenza/Filter";

export default class Header extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            title: "",
            
        }
    }

    componentDidMount() {
        this.checkPath()
        this.listener = window.addEventListener("hashchange", () => this.checkPath())
    }
    componentWillUnmount(){
        window.removeEventListener("hashchange", () => this.checkPath())
    }
    async checkPath() {
        let path = window.location.href.split("/")[3];
        if (path.includes("?")) {
            path = path.split("?")[0]
        }
        
        switch (path) {
            case "preferiti":
                this.setState({title: "I tuoi preferiti"})
                break;
            case "settings":
                this.setState({title: "Impostazioni"})
                break;
            case "admin":
                this.setState({title: "Admin"})
                break;
            case "nuovo-profilo":
                this.setState({title: "Aggiungi profilo"})
                break;
            case "modifica-profilo":
                this.setState({title: "Modifica profilo"})
                break;
            case "ricerche":
                this.setState({title: "Ricerche"})
                break;
            case "prenotazioni":
                this.setState({title: "Prenotazioni"})
                break;
            case "statistiche":
                this.setState({title: "Statistiche"})
                break;
            case "gestione-bot":
                this.setState({title: "Gestione Bot"})
                break;
            case "bot-monitor":
                this.setState({title: "Bot Monitor"})
                break;
            case "contatti":
                this.setState({title: "Contatti"})
                break;
            case "chat":
                this.setState({title: "Chat"})
                break;
            case "callDone":
                this.setState({title: "Call Done"})
                break;
            case "callToDo":
                this.setState({title: "Call To Do"})
                break;
            case "calls":
                this.setState({title: "Calls"})
                break;
            case "accounts":
                this.setState({title: "Accounts"})
                break;
            case "prospects":
                this.setState({title: "Prospects"})
                break;
            case "payment-success":
                this.setState({title: "Payment Success"})
                break;
            case "payment-error":
                this.setState({title: "Payment Error"})
                break;
            default:
                this.setState({title: "Home"})
                break;
        }
    }

    render() {
        return (
            <></>
        )
    }
}