import React, { useContext } from "react";

import HeBLayout from "../components/HeBLayout";
import PageViewsTable from "../components/admin/PageViewsTable";
import NotifyContext from "../context/NotifyContext";

const PageViews = () => {
	const { selectedUser } = useContext(NotifyContext);
	return (
		<HeBLayout>
			<PageViewsTable selectedUser={selectedUser} />
		</HeBLayout>
	);
};

export default PageViews;
