import { Button } from "@mui/material";
import React from "react";
import { Modal } from "react-bootstrap";

export default class DeleteModal extends React.Component {

    deleted = () =>{
        this.props.onConfirmedDelete(this.props.modal.id)
    }

    canceled = () => {
        this.props.onCancel()
    }
    
    render() {
        return (<>
            <Modal show={this.props.modal.show}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Header>
                    Sei sicuro di voler eliminare: {this.props.modal.fullName} <strong>ID# {this.props.modal.id}</strong>
                </Modal.Header>
                <Modal.Footer>
                    <Button onClick={this.canceled} variant='contained' className="myButton" >Annulla</Button>
                    <Button onClick={this.deleted} variant='contained' className="myButton">Elimina</Button>
                </Modal.Footer>
            </Modal>
        </>
        )
    }
}