import React, { useContext } from "react";
import LeadsListTable from "../components/admin/LeadsListTable";
import HeBLayout from "../components/HeBLayout";
import NotifyContext from "../context/NotifyContext";

const LeadsList = () => {
	const { selectedUser } = useContext(NotifyContext);
	return (
		<HeBLayout>
			<div
				style={{
					width: "96%",
					marginInline: "auto",
					marginTop: "20px",
					marginBottom: "20px",
					maxHeight: "90%",
				}}
			>
				<LeadsListTable selectedUser={selectedUser} />
			</div>
		</HeBLayout>
	);
};

export default LeadsList;
