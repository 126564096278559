import React from 'react'
import ReactLoading from 'react-loading';
import img from "../../images/HB.png"; 
export default class Banner extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            mobile: false,
        }
    }

    async componentDidMount() {
        this.resize();
        window.addEventListener("resize", this.resize.bind(this));
        this.setState({loading: false});
    }

    componentWillUnmount(){
        window.removeEventListener("resize", this.resize.bind(this));
    }

    resize(){
        if (window.innerWidth < 768 && !this.state.mobile) {
            this.setState({mobile: true});
        } else if (this.state.mobile) {
            this.setState({mobile:false})
        }
    }

    render() {
        if (this.state.loading) return (
            <div className="d-flex justify-content-center h-100">
                <ReactLoading type={'bubbles'} color={'orange'} height={'10%'} width={'10%'} className="align-self-center " />
            </div>
        ) 
        else return (
            <div ref={this.props.bannerRef} style={{width: '100wh', height:"100vh"}} className='overflow-hidden sezione position-relative'>
                <img src={img} alt="" style={{position: 'absolute', top: 0, left: 0, height: '100%', width: '100%', objectFit: 'cover', zIndex: -2 }}/>
                <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', objectFit: 'cover', zIndex: -1 }} />
                <div className="container-fluid" style={{ overflow: 'hidden' }}>
                    <div className="row h-100 justify-content-center">
                        <div className="col-lg-12" >
                            <div className="hero-content">
                                <h1 style={{marginBottom:0, color: 'rgb(24 7 113)'}}>{"Lead Generation per il tuo Business"}</h1> 
                                <span className='outsourcing HeeboThin'>{"Dolce come un cucchiaino di miele"}</span> 
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}