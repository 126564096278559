
import React, { useEffect } from "react"; 
import * as Icon from 'react-feather';
import { Link } from 'react-router-dom';
import BlogSidebar from '../../components/Blog/BlogSidebar.js'; 
import Blog from '../../utils/JsonBlog.js';
import PageBanner from "../../components/PageBanner.js";

const articleData = {
    "id": 4,
    "title": "L'Importanza della Lead Generation nel Contesto del Marketing Digitale",
    "desc": "",
    "text": "",
    "img": "/blog-image/marketingLG.png", 
    "page": "marketing-lead-generation",
    "data": "24 Nov 2023",
    "author": "Staff", 
}

const LeadGeneration = () => {
    const [isMobile, setMobile] = React.useState(false);

    const handleResize = () => {
        if (window.innerWidth < 1275) {
            setMobile(true);
        } else {
            setMobile(false);
        }
    }

    useEffect(() => {
        window.addEventListener("resize", handleResize);
        handleResize();

        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return( 
        <>
            <PageBanner pageTitle={articleData.title} /> 
            <div className="blog-details-area ptb-80">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 col-md-12">
                            <div className="blog-details-desc">
                                <div className="article-image">
                                    <img className="w-100" src={require('../../images' + articleData.img)} alt="imge" />
                                </div>

                                <div className="article-content">
                                    <div className="entry-meta">
                                        <ul>
                                            <li>
                                                <Icon.Clock /> {articleData.data}
                                            </li>
                                            <li>
                                                <Icon.User /> <Link className="text-decoration-none" to="#">{articleData.author}</Link>
                                            </li>
                                        </ul>
                                    </div>  
                                    <p>La Lead Generation non solo rappresenta un modo per acquisire contatti, ma si configura come un processo strategico che permette alle aziende di identificare e catturare opportunità di business di elevato valore.</p>  
                                    <p>La qualità delle lead diventa il fulcro di questa strategia, contrapponendosi alla mera quantità. Concentrandosi su prospettive più promettenti, la lead generation ottimizza l'efficienza delle risorse di vendita, permettendo alle aziende di indirizzare gli sforzi verso contatti più qualificati.</p>
                                    <p>Un aspetto distintivo di questo approccio è la sua capacità di guidare l'intero ciclo di vita della lead. Dall'identificazione iniziale fino alla conversione in cliente effettivo, mantenere un coinvolgimento costante in ogni fase risulta cruciale per assicurare la vitalità della pipeline di vendita.</p>
                                    <p>Nell'era digitale, l'impiego di strumenti avanzati come il Sales Navigator su piattaforme come LinkedIn riveste un ruolo chiave nella lead generation. Questi strumenti consentono alle aziende di identificare con precisione prospettive di qualità, stabilire connessioni significative e alimentare la pipeline di vendita con lead altamente qualificate.</p>
                                    <p>La dinamicità caratterizza la lead generation come pratica in evoluzione continua. Misurare le performance e ottimizzare costantemente le strategie diventano aspetti essenziali di questa pratica. Attraverso l'analisi delle metriche di conversione e l'adattamento alle mutevoli dinamiche del mercato, le aziende possono perfezionare le proprie strategie nel tempo, garantendo una pipeline di vendita sempre resiliente.</p>
                                    <p>La lead generation si configura come un pilastro indispensabile nel contesto digitale del marketing moderno. Investire in questa strategia non solo alimenta la crescita aziendale ma stabilisce anche un fondamentale vantaggio competitivo nell'ambito sempre più digitalizzato del marketing.</p>
                                </div> 
                                <div style={{ marginTop: 30 }} className="startp-post-navigation"> 
                                {
                                    Blog().filter(a => a.id !== articleData.id).slice(0, 2).map(article => {
                                        return <div className="prev-link-wrapper">
                                        <div className="info-prev-link-wrapper">
                                            <Link to={"/" + article.page}>
                                                <span className="image-prev">
                                                    <img className="w-100" src={require(`../../images${article.img}`)} alt="imge" />
                                                    <span className="post-nav-title">VAI</span>
                                                </span>
            
                                                <span className="prev-link-info-wrapper">
                                                    <span className="prev-title">{article.title}</span>
                                                    <span className="meta-wrapper">
                                                        <span className="date-post">{article.data}</span>
                                                    </span>
                                                </span>
                                            </Link>
                                        </div>
                                    </div>
                                    })
                                }
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-12">
                            <BlogSidebar  id={articleData.id}/>
                        </div>
                    </div>
                </div>
            </div> 
        </>
    )
}
export default LeadGeneration;