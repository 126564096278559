import React, { useEffect } from "react";
import "./App.css";
import Sidebar from "./Sidebar";
import Header from "./Header";
import Cookies from "universal-cookie";
import checkToken from "../services/checkToken";
import StatusModal from "./admin/StatusModal";
import acceptConditionService from "../services/acceptConditionService";
import logout from "../services/logout";

const condizioniServizioText = (
    <>
        <strong>1. Accettazione delle Condizioni</strong>
        <br />
        <br />
        <strong>1.1 Accordo Vincolante</strong>
        <br />
        Queste Condizioni generali di Servizio e di Utilizzo ('Condizioni')
        costituiscono un accordo legalmente vincolante tra l'utente (sia esso un
        individuo o un'entità) e Honey&Bees. Utilizzando o accedendo a qualsiasi
        parte del servizio Honey&Bees, l'utente accetta di essere vincolato da
        queste Condizioni. Se l'utente non accetta queste Condizioni, non è
        autorizzato ad utilizzare il servizio.
        <br />
        <strong>1.2 Modifiche alle Condizioni</strong>
        <br />
        Honey&Bees si riserva il diritto di modificare o rivedere queste
        Condizioni in qualsiasi momento. Qualsiasi modifica entrerà in vigore
        immediatamente dopo la pubblicazione sul sito web di Honey&Bees o dopo
        la notifica agli utenti. L'uso continuato del servizio da parte degli
        utenti dopo tali modifiche costituisce accettazione delle nuove
        Condizioni.
        <br />
        <strong>1.3 Capacità di Accettare le Condizioni</strong>
        <br />
        L'utilizzo del servizio Honey&Bees è inteso solo per soggetti che hanno
        la capacità legale di stipulare contratti vincolanti. Con l'accettazione
        di queste Condizioni, l'utente dichiara di avere la capacità di essere
        vincolato da un contratto.
        <br />
        <strong>1.4 Utilizzo da Parte di Minori</strong>
        <br />
        Il servizio Honey&Bees non è destinato all'uso da parte di minori. Se
        l'utente è un minore, non è autorizzato ad utilizzare il servizio.
        <br />
        <strong>1.5 Accettazione delle Politiche Aggiuntive</strong>
        <br />
        Utilizzando Honey&Bees, l'utente accetta anche di essere vincolato da
        altre politiche di Honey&Bees, come la Policy sulla Privacy e qualsiasi
        altra Policy che potrebbe essere introdotta e comunicata agli utenti.
        <br />
        <br />
        <strong>2. Descrizione del Servizio</strong>
        <br />
        Honey&Bees offre servizi avanzati per la crescita aziendale, per
        l'analisi del business dei suoi clienti, per l'identificazione dei loro
        clienti ideali e per la definizione di strategie di marketing mirate. Il
        nostro servizio si distingue per l'utilizzo di LinkedIn come piattaforma
        principale per la generazione di leads.
        <br />
        Attraverso Honey&Bees, gli utenti possono:
        <br />
        Analizzare il proprio business: Identificare le caratteristiche
        distintive del proprio modello di business, comprese le specificità del
        settore, la dimensione dell'azienda, la localizzazione geografica, e
        altri fattori critici.
        <br />
        Creare Buyer Personas: Definire chiaramente i clienti ideali attraverso
        un'analisi approfondita, basandosi sui dati e sugli insight raccolti.
        <br />
        Generare Liste di Prospect: Utilizzare gli account LinkedIn degli utenti
        per identificare e compilare elenchi di decision makers e influencer
        rilevanti che potrebbero essere interessati ai prodotti o servizi
        offerti dall'utente.
        <br />
        Sviluppare Strategie di Approccio Personalizzate: Scegliere tra approcci
        formali o casuali, diretti o cautelativi, per ottimizzare l'interazione
        con i potenziali clienti.
        <br />
        Contattare i Prospect per conto degli Utenti: Applicare le strategie di
        approccio personalizzate per contattare e interagire con i prospect
        attraverso LinkedIn, facendo avanzare il processo di vendita.
        <br />
        Organizzare Call e Incontri: I prospect che mostrano interesse vengono
        invitati a fissare call o incontri con gli utenti, facilitando lo
        sviluppo di relazioni commerciali dirette.
        <br />
        Il nostro software si integra strettamente con LinkedIn, sfruttando
        questa piattaforma per massimizzare l'efficacia della generazione di
        leads. È importante notare che, nonostante il nostro software
        interagisca con LinkedIn, gli account LinkedIn rimangono di proprietà
        degli utenti, i quali hanno il pieno controllo e possono interrompere
        l'uso della nostra piattaforma in qualsiasi momento.
        <br />
        <br />
        <strong>3. Accesso e Utilizzo del Servizio</strong>
        <br />
        <br />
        <strong>3.1 Eleggibilità e Registrazione</strong>
        <br />
        Il servizio offerto da Honey&Bees è esclusivamente destinato a
        professionisti e aziende che desiderano espandere il proprio business.
        Per accedere al servizio, gli utenti devono completare un processo di
        registrazione, fornendo informazioni accurate e aggiornate. È
        responsabilità dell'utente mantenere la riservatezza delle proprie
        credenziali di accesso e garantire che non vengano utilizzate
        impropriamente.
        <br />
        <strong>3.2 Utilizzo Conforme alle Leggi</strong>
        <br />
        L'utilizzo del servizio deve essere conforme a tutte le leggi e
        regolamentazioni applicabili. E’ proibito per gli utenti l’utilizzo di
        Honey&Bees per qualsiasi scopo illecito o per promuovere attività
        illegali, inclusa, ma non limitata a, la violazione di diritti di
        proprietà intellettuale, frode, diffamazione o spam.
        <br />
        <strong>3.3 Responsabilità dell'Utente</strong>
        <br />
        Gli utenti sono responsabili di ogni azione intrapresa attraverso i loro
        account. Questo include, ma non è limitato a, le interazioni tramite
        LinkedIn e la gestione dei leads generati. Honey&Bees non è responsabile
        per le azioni degli utenti o per eventuali violazioni delle condizioni
        d'uso di LinkedIn.
        <br />
        <strong>3.4 Sicurezza degli accounts</strong>
        <br />
        Gli utenti devono prendere misure appropriate per proteggere la
        sicurezza e l'integrità dei propri account, inclusa la scelta di
        password forti e la modifica periodica delle stesse. In caso di sospetto
        uso non autorizzato o violazione della sicurezza, gli utenti devono
        informare immediatamente Honey&Bees.
        <br />
        <strong>3.5 Limitazioni all'Accesso</strong>
        <br />
        Honey&Bees si riserva il diritto di limitare, sospendere o terminare
        l'accesso di un utente al servizio in caso di violazioni di queste
        condizioni o per qualsiasi altro comportamento che Honey&Bees ritenga
        inappropriato o dannoso per il servizio o per altri utenti.
        <br />
        <br />
        <strong>4. Proprietà Intellettuale</strong>
        <br />
        <br />
        <strong>4.1 Proprietà del Servizio Honey&Bees</strong>
        <br />
        Tutto il software, le tecnologie, i marchi, i testi, le grafiche, le
        interfacce utente e qualsiasi altro contenuto fornito da Honey&Bees sono
        di proprietà esclusiva di APPIUS SRLS o dei suoi licenzianti. Questi
        elementi sono protetti dalle leggi sulla proprietà intellettuale e altri
        diritti di proprietà. L'accesso al servizio Honey&Bees non conferisce
        agli utenti alcun diritto o licenza, implicita o esplicita, sui suddetti
        elementi, ad eccezione di un uso limitato come espressamente autorizzato
        in queste Condizioni di Servizio.
        <br />
        <strong>4.2 Uso dei Marchi e dei Loghi</strong>
        <br />
        I marchi, i loghi e i nomi di servizio utilizzati e visualizzati su
        Honey&Bees sono marchi registrati o non registrati di Honey&Bees o di
        terze parti. Gli utenti non sono autorizzati a usare questi marchi senza
        il previo consenso scritto di Honey&Bees o del terzo titolare dei
        marchi.
        <br />
        <strong>4.3 Proprietà dei Dati Generati</strong>
        <br />
        Mentre Honey&Bees assiste gli utenti nella generazione di leads tramite
        LinkedIn, i dati raccolti e utilizzati per questo scopo rimangono di
        proprietà dell'utente. Honey&Bees non rivendica la proprietà dei dati
        generati o raccolti dagli utenti durante l'utilizzo del servizio.
        Tuttavia, l'utilizzo di questi dati deve essere conforme alle leggi
        applicabili, inclusa la normativa sulla privacy e la protezione dei
        dati.
        <br />
        <strong>4.4 Limitazioni all'Uso dei Contenuti</strong>
        <br />
        Gli utenti non possono riprodurre, distribuire, modificare, creare opere
        derivate, esporre pubblicamente, eseguire pubblicamente, ripubblicare,
        scaricare, memorizzare o trasmettere alcun materiale di Honey&Bees,
        eccetto quanto espressamente permesso da queste Condizioni di Servizio o
        come autorizzato per iscritto da Honey&Bees.
        <br />
        <strong>4.5 Feedback e Suggerimenti</strong>
        <br />
        Qualsiasi feedback, commento, idea, miglioramento o suggerimento
        (collettivamente, 'Feedback') fornito dagli utenti a Honey&Bees riguardo
        al servizio sarà di proprietà esclusiva di Honey&Bees. Honey&Bees sarà
        libera di utilizzare, copiare, modificare, pubblicare o distribuire il
        Feedback per qualsiasi scopo, senza compensazione o obbligo nei
        confronti dell'utente.
        <br />
        <br />
        <strong>5. Limitazioni di Uso</strong>
        <br />
        <br />
        <strong>5.1 Uso Conforme alle Leggi</strong>
        <br />
        Gli utenti di Honey&Bees si impegnano ad utilizzare il servizio in modo
        conforme a tutte le leggi, regolamenti e ordinanze locali, statali,
        nazionali e internazionali applicabili. Ciò include, ma non è limitato
        a, le leggi relative alla privacy, alla protezione dei dati, alla
        proprietà intellettuale, all'antispam e alla concorrenza.
        <br />
        <strong>5.2 Divieto di Uso Illecito</strong>
        <br />
        È espressamente vietato utilizzare Honey&Bees per scopi illeciti o per
        promuovere attività illegali. Questo include, senza limitazioni, l'uso
        del servizio per diffondere contenuti diffamatori, ingannevoli,
        offensivi o in violazione di diritti di proprietà intellettuale di
        terzi.
        <br />
        <strong>5.3 Limitazioni nell'Uso di LinkedIn</strong>
        <br />
        Considerando che Honey&Bees sfrutta LinkedIn per la generazione di
        leads, gli utenti devono rispettare le condizioni d'uso, le politiche
        sulla privacy e qualsiasi altra regolamentazione imposta da LinkedIn.
        Honey&Bees non è responsabile per violazioni delle politiche di LinkedIn
        commesse dagli utenti.
        <br />
        <strong>5.4 Proibizione di Attività Dannose</strong>
        <br />
        Gli utenti non devono intraprendere attività che potrebbero danneggiare,
        sovraccaricare o compromettere il funzionamento di Honey&Bees o la
        sicurezza degli altri utenti. Ciò include tentativi di accesso non
        autorizzato al servizio o ai sistemi correlati, la distribuzione di
        virus o malware, o qualsiasi altro comportamento potenzialmente dannoso.
        <br />
        <strong>5.5 Utilizzo Responsabile delle Risorse</strong>
        <br />
        Honey&Bees richiede agli utenti di utilizzare le risorse della
        piattaforma, compresi i dati e le funzionalità di contatto, in modo
        responsabile e rispettoso. Gli abusi, come l'invio di spam o la
        realizzazione di campagne di marketing aggressive o ingannevoli, sono
        severamente proibiti.
        <br />
        <br />
        <strong>6. Pagamenti e Rimborsi</strong>
        <br />
        <br />
        <strong>6.1 Modalità di Pagamento</strong>
        <br />
        I pagamenti per l'utilizzo dei servizi Honey&Bees vengono gestiti
        tramite Stripe, ritenuta una piattaforma di pagamento online sicura e
        affidabile. Gli utenti possono acquistare pacchetti di chiamate secondo
        le tariffe indicate sul sito web o nelle comunicazioni di Honey&Bees.
        Tutti i pagamenti devono essere effettuati in anticipo e in conformità
        con le istruzioni fornite durante il processo di acquisto.
        <br />
        <strong>6.2 Acquisto di Pacchetti di Chiamate</strong>
        <br />
        I pacchetti di chiamate offerti da Honey&Bees consentono agli utenti di
        accedere a un numero predeterminato di chiamate o sessioni di
        interazione con i prospect. Ogni pacchetto ha specifiche condizioni
        d'uso, che potrebbero includere la durata di validità e il numero
        massimo di chiamate disponibili.
        <br />
        <strong>6.3 Politica di Rimborso</strong>
        <br />
        Gli utenti hanno diritto a richiedere il rimborso del pacchetto
        acquistato solo prima dell'inizio del suo utilizzo. Una volta iniziato
        l'uso del pacchetto di chiamate, non è più possibile ottenere rimborsi.
        Le richieste di rimborso devono essere inviate a Honey&Bees tramite i
        canali di comunicazione ufficiali e saranno soggette a verifica e
        approvazione da parte di Honey&Bees.
        <br />
        <strong>6.4 Modifiche ai Prezzi e ai Pacchetti</strong>
        <br />
        Honey&Bees si riserva il diritto di modificare i prezzi e i dettagli dei
        pacchetti di chiamate. Tali modifiche verranno comunicate agli utenti
        tramite il sito web o attraverso notifiche dirette. Le modifiche non
        influenzeranno i pacchetti già acquistati prima dell'annuncio delle
        modifiche.
        <br />
        <strong>6.5 Mancato Utilizzo dei Servizi</strong>
        <br />
        Nel caso in cui un pacchetto di chiamate acquistato non venga utilizzato
        entro la scadenza indicata, non saranno previsti rimborsi o crediti per
        l'utilizzo futuro. È responsabilità dell'utente utilizzare i servizi
        acquistati entro i termini previsti.
        <br />
        <br />
        <strong>7. Modifiche al Servizio e al Contratto</strong>
        <br />
        <br />
        <strong>7.1 Aggiornamenti e Modifiche al Servizio</strong>
        <br />
        Honey&Bees si impegna a migliorare continuamente e aggiornare i suoi
        servizi per soddisfare le esigenze degli utenti. Di conseguenza,
        Honey&Bees si riserva il diritto di modificare, aggiornare o
        interrompere qualsiasi aspetto del servizio, inclusi, ma non limitati a,
        funzionalità, interfaccia utente, e disponibilità di specifiche opzioni,
        in qualsiasi momento e senza preavviso.
        <br />
        <strong>7.2 Notifica delle Modifiche</strong>
        <br />
        Qualora Honey&Bees apporti modifiche significative ai servizi, gli
        utenti verranno informati tramite comunicazioni via email o tramite
        annunci sul sito web di Honey&Bees. È responsabilità degli utenti
        controllare regolarmente queste comunicazioni per essere aggiornati su
        eventuali cambiamenti.
        <br />
        <strong>
            7.3 Effetti delle Modifiche sulle Condizioni di Servizio
        </strong>
        <br />
        Le modifiche ai servizi possono richiedere aggiornamenti alle presenti
        Condizioni di Servizio. In caso di cambiamenti significativi a queste
        condizioni, Honey&Bees fornirà un preavviso ragionevole e la possibilità
        per gli utenti di rivedere e accettare i nuovi termini. L'uso continuato
        del servizio dopo tali modifiche implica l'accettazione dei termini
        aggiornati.
        <br />
        <strong>7.4 Diritto di Recesso</strong>
        <br />
        In caso di modifiche unilaterali significative ai servizi da parte di
        Honey&Bees che non soddisfano l'utente, questi ha il diritto di recedere
        dal contratto sottoscritto. In tale eventualità, l'utente può richiedere
        un rimborso per i servizi non ancora utilizzati, soggetto alle
        condizioni specificate nella sezione 6.3 relativa ai rimborsi.
        <br />
        <strong>7.5 Responsabilità per Modifiche</strong>
        <br />
        Honey&Bees non è responsabile per eventuali perdite o danni derivanti da
        modifiche al servizio, salvo quanto diversamente specificato in altre
        sezioni di queste Condizioni di Servizio.
        <br />
        <br />
        Ecco l'espansione del punto 8, che riguarda la terminazione del servizio
        da parte di Honey&Bees e degli utenti:
        <br />
        <br />
        <strong>8. Terminazione del Servizio</strong>
        <br />
        <br />
        <strong>8.1 Terminazione da Parte dell'Utente</strong>
        <br />
        Gli utenti di Honey&Bees hanno il diritto di terminare l'utilizzo del
        servizio in qualsiasi momento. Per farlo, è necessario notificare
        Honey&Bees attraverso i canali di comunicazione ufficiali. La cessazione
        diventerà effettiva immediatamente dopo la ricezione della notifica da
        parte di Honey&Bees. Eventuali pacchetti di chiamate parzialmente
        utilizzati non saranno soggetti a rimborso, come specificato nella
        sezione 6.3 delle presenti Condizioni di Servizio.
        <br />
        <strong>8.2 Terminazione da Parte di Honey&Bees</strong>
        <br />
        Honey&Bees si riserva il diritto di terminare o sospenderere l'accesso
        al servizio per un utente in qualsiasi momento e senza preavviso, in
        caso di violazione di queste Condizioni di Servizio o per qualsiasi
        altro motivo ritenuto valido da Honey&Bees, inclusi comportamenti che
        possano danneggiare Honey&Bees o altri utenti del servizio.
        <br />
        <strong>8.3 Conseguenze della Terminazione</strong>
        <br />
        Alla terminazione del servizio, sia da parte dell'utente che di
        Honey&Bees, l'utente perderà l'accesso a tutte le funzionalità del
        servizio, inclusi i dati e le informazioni associate al proprio account.
        Honey&Bees non è responsabile per la perdita di tali informazioni a
        seguito della terminazione.
        <br />
        <strong>8.4 Salvataggio dei Dati dopo la Terminazione</strong>
        <br />
        Gli utenti sono incoraggiati a salvare o esportare i dati rilevanti dal
        loro account prima di richiedere la terminazione del servizio.
        Honey&Bees non è obbligata a mantenere o fornire una copia dei dati o
        delle informazioni post-terminazione, a meno che non sia richiesto dalla
        legge applicabile.
        <br />
        <strong>8.5 Effetti Legalmente Vincolanti</strong>
        <br />
        La terminazione del servizio non influisce sui diritti e obbligazioni
        legalmente vincolanti che sono sopravvissuti alla terminazione, inclusi,
        ma non limitati a, le disposizioni sulla proprietà intellettuale, le
        limitazioni di responsabilità e le disposizioni di risoluzione delle
        dispute.
        <br />
        <br />
        <strong>9. Dispute e Legge Applicabile</strong>
        <br />
        <br />
        <strong>9.1 Risoluzione delle Controversie</strong>
        <br />
        Honey&Bees si impegna a fornire un servizio di alta qualità e desidera
        assicurarsi che eventuali problemi siano risolti in modo rapido ed equo.
        Se dovessero sorgere controversie relative all'uso del servizio,
        Honey&Bees incoraggia gli utenti a contattare il servizio clienti per
        esplorare soluzioni amichevoli.
        <br />
        <strong>9.2 Procedura di Reclamo</strong>
        <br />
        In caso di reclamo, gli utenti sono invitati a presentare il loro caso
        dettagliato al servizio clienti di Honey&Bees. Honey&Bees esaminerà il
        reclamo e farà ogni sforzo ragionevole per rispondere e risolvere la
        questione entro un tempo ragionevole.
        <br />
        <strong>9.3 Mediazione</strong>
        <br />
        Prima di intraprendere qualsiasi azione legale, Honey&Bees e l'utente si
        impegnano a tentare di risolvere la disputa attraverso la negoziazione
        informale o la mediazione, coinvolgendo un mediatore neutrale, se
        necessario.
        <br />
        <strong>9.4 Giurisdizione e Legge Applicabile</strong>
        <br />
        Qualsiasi controversia che non possa essere risolta amichevolmente sarà
        soggetta alla giurisdizione esclusiva del tribunale di Modena, Italia.
        Le presenti Condizioni di Servizio sono regolate e interpretate secondo
        le leggi italiane, senza dare effetto a qualsiasi scelta o conflitto di
        principi legali che potrebbero richiedere l'applicazione delle leggi di
        un'altra giurisdizione.
        <br />
        <strong>9.5 Limitazioni alle Azioni Legali</strong>
        <br />
        Qualsiasi causa di azione o reclamo che l'utente possa avere con
        riferimento al servizio deve essere iniziato entro un anno dopo che la
        causa dell'azione sorge, altrimenti tale causa di azione o reclamo è
        permanentemente precluso.
        <br />
        <strong>9.6 Accordi sul foro di competenza</strong>
        <br />
        Gli utenti accettano che qualsiasi disputa che richieda l'intervento di
        un tribunale sarà condotta esclusivamente del Tribunale di Modena,
        Italia. Inoltre, gli utenti rinunciano a qualsiasi diritto di contestare
        la giurisdizione o la sede di tale tribunale.
        <br />
        <strong>9.7 Equità Legale</strong>
        <br />
        Niente nelle presenti Condizioni di Servizio preclude ad Honey&Bees la
        possibilità di cercare ingiunzioni o altri rimedi per la violazione
        delle presenti Condizioni di Servizio, in particolare in relazione alla
        violazione dei diritti di proprietà intellettuale.
        <br />
        <br />
        <strong>10. Limitazioni di Responsabilità</strong>
        <br />
        <br />
        <strong>10.1 Responsabilità Generale</strong>
        <br />
        Honey&Bees non sarà in alcun modo responsabile per danni diretti,
        indiretti, incidentali, speciali, consequenziali o punitivi, inclusi, ma
        non limitati a, perdite di profitti, dati o altre perdite intangibili,
        derivanti dall'uso o dall'impossibilità di utilizzare il servizio, anche
        se Honey&Bees è stata avvisata della possibilità di tali danni.
        <br />
        <strong>10.2 Limitazioni Specifiche</strong>
        <br />
        Perdita di Dati: Honey&Bees non è responsabile per la perdita o il
        danneggiamento dei dati degli utenti a causa di fattori al di fuori del
        controllo diretto di Honey&Bees.
        <br />
        Malfunzionamenti del Software: Mentre Honey&Bees si impegna a mantenere
        il suo software funzionante in modo ottimale, non garantisce che il
        servizio sarà sempre privo di errori o interruzioni. Dipendenza da Terze
        Parti: Data la natura del servizio, che sfrutta piattaforme terze come
        LinkedIn, Honey&Bees non è responsabile per eventuali modifiche,
        politiche, o malfunzionamenti di tali piattaforme che potrebbero
        influenzare l'uso del servizio. Abuso del Servizio: Honey&Bees non è
        responsabile per qualsiasi perdita o danno derivante dall'uso improprio
        del servizio da parte degli utenti o da violazioni delle presenti
        Condizioni di Servizio.
        <br />
        <strong>10.3 Misure di Sicurezza</strong>
        <br />
        Honey&Bees adotta misure ragionevoli per proteggere la sicurezza e
        l'integrità del servizio, ma non può garantire assoluta sicurezza contro
        tutte le possibili violazioni o attacchi.
        <br />
        <strong>10.4 Esclusione di Garanzie</strong>
        <br />
        Il servizio Honey&Bees viene fornito "così come è" e "come disponibile".
        Honey&Bees esclude tutte le garanzie, espresse o implicite, incluse, ma
        non limitate a, le garanzie implicite di commerciabilità, idoneità per
        uno scopo particolare e non violazione.
        <br />
        <strong>10.5 Limitazione Massima</strong>
        <br />
        In nessun caso la responsabilità complessiva di Honey&Bees nei confronti
        dell'utente per tutti i danni, le perdite e le cause di azione (sia per
        contratto, torto, inclusa la negligenza, o altrimenti) supererà
        l'importo pagato dall'utente per accedere al servizio nei 12 mesi
        precedenti l'evento che ha dato origine alla responsabilità.
        <br />
        <strong>10.6 Applicabilità delle Limitazioni</strong>
        <br />
        Queste limitazioni di responsabilità si applicano nella massima misura
        consentita dalla legge applicabile e sopravvivranno alla terminazione o
        alla scadenza di queste Condizioni di Servizio o dell'uso del servizio
        da parte dell'utente.
    </>
);

const cookies = new Cookies();

const HeBLayout = ({ children }) => {
    const username = cookies.get("username", { path: "/" });
    const token = cookies.get("token", { path: "/" });
    const path = window.location.href.split("/");
    const [modal, setModal] = React.useState(false);
    const [auth, setAuth] = React.useState(false);
    const [isMobile, setIsMobile] = React.useState(false);

    useEffect(() => {
        const validateToken = async () => {
            if (!username || !token) {
                if (path.length !== 3) {
                    window.location.href = "/";
                }
            } else {
                setAuth(true);
                let res = await checkToken(username, token);
                if (res.condizioni_servizio_approvate === "0") {
                    setModal(true);
                }
                if (res !== null && res.username !== null) {
                } else {
                    cookies.remove("username", { path: "/" });
                    cookies.remove("token", { path: "/" });
                    cookies.remove("id", { path: "/" });
                    cookies.remove("tipo", { path: "/" });
                    if (path.length !== 3) {
                        window.location.href = "/";
                    }
                }
            }
        };
        validateToken();
    }, [username, token, path.length]);

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth <= 768) {
                setIsMobile(true);
            } else {
                setIsMobile(false);
            }
        };

        handleResize();
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const accept = async () => {
        const res = await acceptConditionService(username, token);
        if (res.result === "ok") {
            setModal(false);
        }
    };

    const handleLogout = async () => {
        await logout(username, token);
        cookies.remove("username");
        cookies.remove("token");
        cookies.remove("tipo");
        cookies.remove("id");
        window.location.href = "/";
    };

    if (!auth) {
        return <div />;
    }

    return (
        <div className="">
            <>
                <Header
                    onFilterHB={(element) => {
                        window.sessionStorage.setItem("search", element, {
                            path: "/",
                        });
                    }}
                />
                <Sidebar isMobile={isMobile} />
                {modal && (
                    <StatusModal
                        title={"Condizioni di Servizio"}
                        message={condizioniServizioText}
                        show={modal}
                        onHide={accept}
                        buttonText={"Accetta"}
                        onLogout={handleLogout}
                    />
                )}
                <div className="d-flex overflow-hidden vh-100 w-100 bg-hb">
                    <div
                        style={{
                            marginTop: 22.5,
                            overflowX: "hidden",
                            width: `calc(100% - ${
                                isMobile ? "62px" : "240px"
                            })`,
                            marginLeft: isMobile ? 62 : 240,
                            overflowY:
                                window.location.href.split("/")[4] ===
                                "curriculum"
                                    ? "hidden"
                                    : "auto",
                        }}
                    >
                        {modal ? "" : children}
                    </div>
                </div>
            </>
        </div>
    );
};

export default HeBLayout;
