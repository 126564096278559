import React, { useEffect, useState } from 'react';  
import Blog from '../../utils/JsonBlog.js';
import { Link } from 'react-router-dom';


const BlogSidebar = (props) => {
    const [hasNav, setNav] = useState(false);

    useEffect(() => {
        setNav(document !== null);
    }, []);

    return (
        <>
            { hasNav && ( 
                <div className="widget-area" id="secondary">
                    <div className="widget widget_startp_posts_thumb">
                        <h3 className="widget-title">ULTIMI ARTICOLI</h3>
                        {
                            Blog().filter(a => a.id !== props.id).sort((a, b) => b.id - a.id).map(article => {
                                return <article className="item" style={{cursor:"pointer"}}>
                                <Link to={"/blogPost/" + article.page} className="thumb">
                                    <span className="fullimage cover" role="img" style={{backgroundImage: `url("${require('../../images' + article.img)}")`}}></span>
                                </Link>
            
                                <div className="info">
                                    <time>{article.data}</time>
                                    <h4 className="title usmall HeeboBold">
                                        <Link to={"/blogPost/" + article.page}>
                                            {article.title}
                                        </Link>
                                    </h4>
                                </div>
            
                                <div className="clear"></div>
                            </article>

                            })
                        }
                    </div>
                </div>
            )}
        </>
    )
}

export default BlogSidebar;  