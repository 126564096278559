import React from "react";
import ReactLoading from "react-loading";
import video from "../../videos/video.mp4";
export default class VideoHomepage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            mobile: false,
        };
    }

    async componentDidMount() {
        this.resize();
        window.addEventListener("resize", this.resize.bind(this));
        this.setState({ loading: false });
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.resize.bind(this));
    }

    resize() {
        if (window.innerWidth < 768 && !this.state.mobile) {
            this.setState({ mobile: true });
        } else if (this.state.mobile) {
            this.setState({ mobile: false });
        }
    }

    render() {
        if (this.state.loading)
            return (
                <div className="d-flex justify-content-center h-100">
                    <ReactLoading
                        type={"bubbles"}
                        color={"orange"}
                        height={"10%"}
                        width={"10%"}
                        className="align-self-center "
                    />
                </div>
            );
        else
            return (
                <div
                    style={{
                        width: "100wh",
                        height: this.state.mobile ? "30vh" : "100vh",
                        backgroundColor: "#ffffff",
                        display: "flex", 
                        justifyContent: "center",
                    }}
                >
                    <div
                        ref={this.props.bannerRef}
                        style={{
                            width: "80%", 
                        }}
                        className="overflow-hidden sezione position-relative"
                    >
                        <video
                            className="pt-5"
                            style={{
                                position: "absolute",
                                top: 0,
                                left: 0,
                                height: this.state.mobile ? "30vh" : "100vh",
                                width: "100%",
                                objectFit: "contain",
                                zIndex: 1,
                            }}
                            src={video}
                            controls
                            muted
                            loop
                            playsInline
                        />
                    </div>
                </div>
            );
    }
}
