import React from "react";
import { Button, FloatingLabel, Form, Modal } from "react-bootstrap";
import Cookies from "universal-cookie";
import updateTelefonoClienti from "../services/updateTelefonoClienti";
import updateTelefono from "../services/updateTelefono";

const cookies = new Cookies();
const username = cookies.get('username', { path: "/" })
const token = cookies.get('token', { path: "/" })
export default class UpdateTelefonoModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            telefono: "",
            edit: false,
            alert: false
        }
    }
    componentDidMount() {
        this.setState({ telefono: this.props.modal.telefono })
    }

    canceled = () => {
        this.props.onCancel();
    };

    render() {
        return (
            <>
                <Modal
                    show={this.props.modal.show}
                    onHide={this.canceled}
                    size="sm"
                    aria-labelledby="example-modal-sizes-title-lg"
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            Numero di Telefono
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div style={{ padding: "5px 16px 16px 16px" }}>
                            <FloatingLabel
                                controlId="floatingTextarea2"
                                label="Numero di Telefono"
                                key={"fix"}
                            >
                                <Form.Control
                                    value={this.state.telefono}
                                    onChange={(value) => {
                                        this.setState({ telefono: value.target.value, alert: false });
                                    }}
                                    autoFocus
                                    as="textarea"
                                    placeholder="Numero di Telefono"
                                    style={{ marginTop: 15, height: "70px", borderColor: this.state.alert ? "red" : "" }}
                                />
                            </FloatingLabel>
                            <div className="d-flex justify-content-end mt-2">
                                <Button variant="outline-dark"
                                    onClick={async () => {
                                        if (this.props.modal.path === "chat") {
                                            updateTelefonoClienti(username, token, this.state.telefono, this.props.modal.id).then(res => {
                                                if (res.result === "ok") {
                                                    this.canceled();
                                                }
                                            })
                                        } else {
                                            updateTelefono(username, token, this.state.telefono, this.props.modal.id).then(res => {
                                                if (res.result === "ok") {
                                                    this.canceled();
                                                }
                                            })
                                        }
                                    }}
                                >{this.props.telefono !== "" ? "Modifica" : "Inserisci"}</Button>
                            </div>
                        </div>
                    </Modal.Body>

                </Modal>

            </>
        );
    }
}