import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import Login from "../pages/Login";
import VerifyEmail from "../pages/VerifyEmail";
import "./App.css";
import Cookies from "universal-cookie";
import checkToken from "../services/checkToken";
import RegistrazioneAccount from "../pages/RegistrazioneAccount";
import Homepage from "../pages/Homepage";
import Blog from "../pages/Blog";
import BlogPost from "../pages/BlogPost";
import { CondizioniServizio } from "../pages/CondizioniServizio";
import Contatti from "../pages/Contatti";
import Liste from "../pages/Liste";
import Chat from "../pages/Chat";
import GestioneBot from "../pages/GestioneBot";
import AppuntiClienti from "../pages/AppuntiClienti";
import Calls from "../pages/Calls";
import Settings from "../pages/Settings";
import Dashboard from "../pages/Dashboard";
import Admin from "../pages/Admin";
import List from "../pages/List";
import VerificaEmail from "../pages/VerificaEmail";
import LeadsList from "../pages/LeadsList";
import ChatTest from "../pages/ChatTest";
import Campagne from "../pages/Campagne";
import CampagneForm from "../pages/CampagneForm";
import VM from "../pages/VM";
import CampagneEdit from "../pages/CampagneEdit";
import Notifiche from "../pages/Notifiche";
import UniqueCode from "../pages/UniqueCode";
import PageViews from "../pages/PageViews";
import Emails from "../pages/Emails";

const cookies = new Cookies();

function App() {
	const [logged, setLogged] = useState(null);

	const username = cookies.get("username", { path: "/" });
	const token = cookies.get("token", { path: "/" });

	useEffect(() => {
		const check = async () => {
			let res = await checkToken(username, token);
			if (res !== null && res.username !== null) {
				setLogged(true);
			} else {
				cookies.remove("username", { path: "/" });
				cookies.remove("token", { path: "/" });
				cookies.remove("id", { path: "/" });
				cookies.remove("tipo", { path: "/" });

				let path = window.location.href.split("/");
				if (path.length !== 3) {
					setLogged(false);
				}
				return;
			}
		};

		let path = window.location.href.split("/");
		if (!username || !token) {
			if (path.length !== 3) {
				setLogged(false);
			}
		} else {
			check();
		}
	}, [username, token]);

	function PrivateRoute({ children, ...rest }) {
		return (
			<Route
				{...rest}
				render={() => {
					return cookies.get("tipo", { path: "/" }) === "1" ? children : <Redirect to="/calls" />;
				}}
			/>
		);
	}

	return (
		<Router>
			<Switch>
				<Route exact path="/" render={() => (logged ? <Redirect to="/dashboard" /> : <Homepage />)} />
				<Route path="/login" component={Login} />
				<Route path="/register" component={RegistrazioneAccount} />
				<Route path="/verify" component={VerifyEmail} />
				<Route path="/blog" component={Blog} />
				<Route path="/conferma-registrazione" component={VerificaEmail} />
				<Route path="/blogPost/:page" component={BlogPost} />
				<Route path="/condizioni-di-servizio" component={CondizioniServizio} />
				<Route path="/chat" component={Chat} />
				<Route path="/info" component={Settings} />
				<Route path="/dashboard" component={Dashboard} />
				<Route path="/notify" component={Notifiche} />
				<Route path="/contatti" component={Contatti} />
				<Route path="/emails" component={Emails} />
				<Route path="/page-views" component={PageViews} />
				<Route path="/l/:uniqueCode" component={UniqueCode} />
				<PrivateRoute>
					<Route path="/admin" component={Admin} />
					<Route path="/liste" component={List} />
					<Route path="/virtual-machine" component={VM} />
					<Route path="/chat-test" component={ChatTest} />
					<Route path="/campagne" component={Campagne} />
					<Route path="/nuova-campagna" component={CampagneForm} />
					<Route path="/modifica-campagna/:id" component={CampagneEdit} />
					<Route path="/leads-list" component={LeadsList} />
					<Route path="/calls" component={Calls} />
					<Route path="/appunti-clienti" component={AppuntiClienti} />
					<Route path="/accounts" component={GestioneBot} />
					<Route path="/prospects" component={Liste} />
				</PrivateRoute>
			</Switch>
		</Router>
	);
}

export default App;
