import React from "react";
import { Button, FloatingLabel, Form, Modal } from "react-bootstrap";
import Cookies from "universal-cookie";
import getNoteRicerca from "../services/getNoteRicerca";
import updateNoteRicercaContatto from "../services/updateNoteRicercaContatto";


const cookies = new Cookies();
const username = cookies.get('username', { path: "/" })
const token = cookies.get('token', { path: "/" })
export default class AggiornamentoModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            note: "",
        }
    }
    componentDidMount() {
        getNoteRicerca(username, token, this.props.modal.id).then(res => {
            this.setState({ note: res.note_ricerca })
        })

    }

    canceled = () => {
        this.props.onCancel();
    };

    render() {
        return (
            <>
                <Modal
                    show={this.props.modal.show}
                    onHide={this.canceled}
                    size="sm"
                    aria-labelledby="example-modal-sizes-title-lg"
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            Note di ricerca
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div style={{ padding: "5px 16px 16px 16px" }}>
                            <FloatingLabel
                                controlId="floatingTextarea2"
                                label="Note di Ricerca"
                                key={"fix"}
                            >
                                <Form.Control
                                    value={this.state.note}
                                    onChange={(value) => {
                                        this.setState({ note: value.target.value, alert: false });
                                    }}
                                    autoFocus
                                    as="textarea"
                                    placeholder="Note"
                                    style={{ marginTop: 15, height: "70px", borderColor: this.state.alert ? "red" : "" }}
                                />
                            </FloatingLabel>
                            <div className="d-flex justify-content-end mt-2">
                                <Button variant="outline-dark"
                                    onClick={async () => {
                                        updateNoteRicercaContatto(username, token, this.state.note, this.props.modal.id).then(res => {
                                            if (res.result === "ok") this.canceled();
                                        })
                                    }}
                                >Inserisci</Button>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </>
        );
    }
}