import React from "react";
import { Button, Card, FloatingLabel, Form, Modal } from "react-bootstrap";
import Cookies from "universal-cookie";
import getAggiornamentiClienti from "../../services/getAggiornamentiClienti";
import getAggiornamentoClienti from "../../services/getAggiornamentoClienti";
import addAggiornamentoClienti from "../../services/addAggiornamentoClienti";
import deleteAggiornamentoClienti from "../../services/deleteAggiornamentoClienti";
import editAggiornamentoClienti from "../../services/editAggiornamentoClienti";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark, faPenToSquare } from "@fortawesome/free-regular-svg-icons";


const cookie = new Cookies();
const username = cookie.get("username", { path: "/" });
const token = cookie.get("token", { path: "/" });

export default class AggiornamentoModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            aggiornamenti: [],
            descrizione: "",
            post_id: null,
            edit: false,
            alert: false
        }
    }
    componentDidMount() {
        this.getUpdate();
    }

    async getUpdate() {
        var aggiornamenti = await getAggiornamentiClienti(username, token, this.props.modal.id);
        this.setState({ username, token, aggiornamenti });
    }

    canceled = () => {
        this.props.onCancel();
    };

    async edit(id_post) {
        getAggiornamentoClienti(this.state.username, this.state.token, this.props.modal.id, id_post).then(res => {
            this.setState({ descrizione: res.descrizione });
        })
    }

    render() {
        return (
            <>
                <Modal
                    scrollable
                    show={this.props.modal.show}
                    onHide={this.canceled}
                    size="lg"
                    aria-labelledby="example-modal-sizes-title-lg"
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            Scrivi un aggiornamento
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        {/* CARDS */}
                        <div className="mt-4">
                            {
                                this.state.aggiornamenti.length > 0 && (
                                    this.state.aggiornamenti.map((a, i) => {
                                        return (
                                            <Card key={i} className="mt-4 position-relative">
                                                <FontAwesomeIcon style={{ position: "absolute", top: -15, right: 0, color: "red", cursor: "pointer" }} icon={faCircleXmark} onClick={() => {
                                                    deleteAggiornamentoClienti(this.state.username, this.state.token, a.id).then((res) => {
                                                        if (res.result === "ok") {
                                                            this.getUpdate();
                                                        }
                                                    })
                                                }} />
                                                <FontAwesomeIcon style={{ position: "absolute", top: -15, right: 20, cursor: "pointer" }} icon={faPenToSquare}
                                                    onClick={() => {
                                                        this.edit(a.id);
                                                        this.setState({ edit: true, post_id: a.id });
                                                    }}
                                                />
                                                <Card.Header className="d-flex justify-content-between">
                                                    <div>
                                                        {a.user}
                                                    </div>
                                                    <div>
                                                        {a.data}
                                                    </div>
                                                </Card.Header>
                                                <Card.Body>
                                                    <blockquote className="blockquote mb-0">
                                                        <p>
                                                            {a.descrizione}
                                                        </p>
                                                    </blockquote>
                                                </Card.Body>
                                            </Card>
                                        )
                                    })
                                )
                            }
                        </div>
                    </Modal.Body>

                    {/* INPUT */}
                    <div style={{ padding: "5px 16px 16px 16px" }}>
                        <FloatingLabel
                            controlId="floatingTextarea2"
                            label="Scrivi un aggiornamento"
                            key={"fix"}
                        >
                            <Form.Control
                                value={this.state.descrizione}
                                onChange={(value) => {
                                    this.setState({ descrizione: value.target.value, alert: false });
                                }}
                                autoFocus
                                as="textarea"
                                placeholder="Scrivi un aggiornamento"
                                style={{ marginTop: 15, height: "70px", borderColor: this.state.alert ? "red" : "" }}
                            />
                        </FloatingLabel>
                        <div className="d-flex justify-content-end mt-2">
                            <Button variant="outline-dark"
                                onClick={() => {
                                    if (this.state.descrizione.trim().length !== 0) {
                                        this.state.edit ? (
                                            editAggiornamentoClienti(this.state.username, this.state.token, this.state.descrizione, this.state.post_id).then((res) => {
                                                if (res.result === "ok") {
                                                    this.setState({ descrizione: "", edit: false });
                                                    this.getUpdate();
                                                }
                                            })
                                        ) : (
                                            addAggiornamentoClienti(this.state.username, this.state.token, this.props.modal.id, this.state.descrizione).then(res => {
                                                if (res.result === "ok") {
                                                    this.setState({ descrizione: "" });
                                                    this.getUpdate();
                                                    this.props.onReload();
                                                }
                                            })
                                        )
                                    } else {
                                        this.setState({ alert: true })
                                    }
                                }}
                            >{this.state.edit ? "Modifica" : "Inserisci"}</Button>
                        </div>
                    </div>
                </Modal>
            </>
        );
    }
}