import React from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";


export default class Filter extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            search: "",
            arrayProfili: [],
            checkbox: [],
            checkboxTime: [],
        }
        this.profiles = [];
    }

    componentDidMount() {
        let times = window.sessionStorage.getItem("time");
        let array = window.sessionStorage.getItem("badges");
        let badges = array ? JSON.parse(array) : [];
        let badgesTime = times ? JSON.parse(times):[];
        this.setState({checkbox: badges, checkboxTime: badgesTime});
    }

    componentDidUpdate() {
        if (this.props.fullArray !== undefined && this.props.fullArray !== this.state.arrayProfili) {
            this.profiles = this.props.fullArray;
            this.setState({ arrayProfili: this.props.fullArray })
        }
    }

    render() {

        return (
            <>
                <div className="me-4">
                    <div className="row">
                        <div style={{paddingTop: 7}} className="col-8 d-flex align-items-center justify-content-end">
                            <div className="form-check form-check-inline">
                                <input checked={this.state.checkbox.includes("junior")} className="form-check-input checkbox-round" type="checkbox" id="inlineCheckbox1" value="junior" onChange={ event => {
                                    let x = this.state.checkbox;
                                    if (x.includes(event.target.value)) {
                                        x.indexOf(event.target.value);
                                        x.splice(x.indexOf(event.target.value), 1);
                                        window.sessionStorage.setItem("badges", JSON.stringify(x));
                                        this.setState({checkbox: x})
                                        this.props.onFilter("");
                                    } else {
                                        x.push(event.target.value);
                                        window.sessionStorage.setItem("badges", JSON.stringify(x));
                                        this.setState({checkbox: x})
                                        this.props.onFilter("");
                                    }
                                }}/>
                                <label className="form-check-label text-light" style={{marginTop:1}} htmlFor="inlineCheckbox1">Junior</label>
                            </div>
                            <div className="form-check form-check-inline">
                                <input checked={this.state.checkbox.includes("middle")} className="form-check-input checkbox-round" type="checkbox" id="inlineCheckbox3" value="middle" onChange={ event => {
                                    let x = this.state.checkbox;
                                    if (x.includes(event.target.value)) {
                                        x.indexOf(event.target.value);
                                        x.splice(x.indexOf(event.target.value), 1);
                                        window.sessionStorage.setItem("badges", JSON.stringify(x));
                                        this.setState({checkbox: x})
                                        this.props.onFilter("");
                                    } else {
                                        x.push(event.target.value);
                                        window.sessionStorage.setItem("badges", JSON.stringify(x));
                                        this.setState({checkbox: x})
                                        this.props.onFilter("");
                                    }
                                }}/>
                                <label className="form-check-label text-light" style={{marginTop:1}} htmlFor="inlineCheckbox3">Middle</label>
                            </div>
                            <div className="form-check form-check-inline">
                                <input checked={this.state.checkbox.includes("senior")}  className="form-check-input checkbox-round" type="checkbox" id="inlineCheckbox2" value="senior"  onChange={ event => {
                                    let x = this.state.checkbox;
                                    if (x.includes(event.target.value)) {
                                        x.indexOf(event.target.value);
                                        x.splice(x.indexOf(event.target.value), 1);
                                        window.sessionStorage.setItem("badges", JSON.stringify(x));
                                        this.setState({checkbox: x})
                                        this.props.onFilter("");
                                    } else {
                                        x.push(event.target.value);
                                        window.sessionStorage.setItem("badges", JSON.stringify(x));
                                        this.setState({checkbox: x})
                                        this.props.onFilter("");
                                    }
                                }} />
                                <label className="form-check-label text-light" style={{marginTop:1}} htmlFor="inlineCheckbox2">Senior</label>
                            </div>
                            <div style={{width: 1, height: 30, backgroundColor:"white", opacity:0.5, marginRight:15, marginBottom: 5}}></div>
                            <div className="form-check form-check-inline">
                                <input checked={this.state.checkboxTime.includes("full_time")}  className="form-check-input checkbox-round" type="checkbox" id="inlineCheckbox4" value="full_time"  onChange={ event => {
                                    let x = this.state.checkboxTime;
                                    if (x.includes(event.target.value)) {
                                        x.indexOf(event.target.value);
                                        x.splice(x.indexOf(event.target.value), 1);
                                        window.sessionStorage.setItem("time", JSON.stringify(x));
                                        this.setState({checkboxTime: x})
                                        this.props.onFilter("");
                                    } else {
                                        x.push(event.target.value);
                                        window.sessionStorage.setItem("time", JSON.stringify(x));
                                        this.setState({checkboxTime: x})
                                        this.props.onFilter("");
                                    }
                                }} />
                                <label className="form-check-label text-light" style={{marginTop:1}} htmlFor="inlineCheckbox4">Full-Time</label>
                            </div>
                            <div className="form-check form-check-inline">
                                <input checked={this.state.checkboxTime.includes("part_time")}  className="form-check-input checkbox-round" type="checkbox" id="inlineCheckbox5" value="part_time"  onChange={ event => {
                                    let x = this.state.checkboxTime;
                                    if (x.includes(event.target.value)) {
                                        x.indexOf(event.target.value);
                                        x.splice(x.indexOf(event.target.value), 1);
                                        window.sessionStorage.setItem("time", JSON.stringify(x));
                                        this.setState({checkboxTime: x})
                                        this.props.onFilter("");
                                    } else {
                                        x.push(event.target.value);
                                        window.sessionStorage.setItem("time", JSON.stringify(x));
                                        this.setState({checkboxTime: x})
                                        this.props.onFilter("");
                                    }
                                }} />
                                <label className="form-check-label text-light" style={{marginTop:1}} htmlFor="inlineCheckbox5">Part-Time</label>
                            </div>
                        </div>
                        <div className='col-4' style={{paddingTop: "0.17rem"}}>
                            <div className='d-flex flex-wrap justify-content-center'>
                                <div style={{paddingLeft:10, paddingRight:16, borderRadius: 10, border: "1px solid #D2D2D2", color: 'black', backgroundColor:"white" }}>
                                    <FontAwesomeIcon icon={faMagnifyingGlass} />
                                    <input type="text" style={{paddingTop:8, paddingBottom:8, paddingLeft:16, paddingRight:16, borderRadius: 10, border:0, color: 'black', outline: 'none' }} id="search" placeholder="Ricerca" 
                                    onChange={(event) => {
                                        this.setState({search: event.target.value});
                                        
                                        this.props.onFilter(event.target.value)
                                    }}/>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </>
        )
    }
}