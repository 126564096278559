import Constants from "../utils/consts";

var http = Constants.http;
var qs = require("querystring");

export default async function editAccount(uname, token, account) {
	return new Promise(function(resolve, reject) {
		var options = {
			method: "POST",
			hostname: Constants.ipAddress,
			path: Constants.apisFolder + "edit/account/interni/",
			headers: {
				"Content-Type": "application/x-www-form-urlencoded",
				Cookie: "uid=e02675a1a7516909792f6850dffcd5fe",
			},
			maxRedirects: 20,
		};

		var req = http.request(options, function(res) {
			var chunks = [];

			res.on("data", function(chunk) {
				chunks.push(chunk);
			});

			res.on("end", function(chunk) {
				var body = Buffer.concat(chunks);
				const resp = JSON.parse(body.toString());
				resolve(resp);
			});

			res.on("error", function(error) {
				console.error(error);
			});
		});

		var postData = qs.stringify({
			uname: uname,
			token: token,
			nome_completo: account?.nome_completo,
			sigla: account?.sigla,
			email: account?.email,
			location: account?.location_fallback,
			ruolo: account?.ruolo_fallback,
			password_account: account?.password_account,
			ip_proxy: account?.ip_proxy,
			proxy_auth: account?.proxy_auth,
			login_cookie: account?.login_cookie,
			password_gmail_yahoo: account?.password_gmail_yahoo,
			tipologia: account.tipologia,
			richieste_attive: account?.richieste_attive,
			max_contatti: account?.max_contatti,
			id: account.id,
			messaggio_fallback: account?.messaggio_fallback,
			parole_chiave: account?.parole_chiave,
			parole_escluse: account?.parole_escluse,
			parole_obbligatorie: account?.parole_obbligatorie,
			id_campagna: account.id_campagna === null ? "Non selezionato" : account.id_campagna,
		});

		req.write(postData);

		req.end();
	});
}
