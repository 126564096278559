/* eslint-disable react/react-in-jsx-scope */
import React from 'react'; 
import LeadGeneration from '../pages/blog/LeadGeneration';
import PipelineLeadGeneration from '../pages/blog/PipelineLeadGeneration';
import RuoloLeadGeneration from '../pages/blog/RuoloLeadGeneration';
import MarketingLeadGeneration from '../pages/blog/MarketingLeadGeneration';

const JsonBlog = () => { 
    const articles = [
        {
            "id": 1,
            "title": "Lead Generation",
            "desc": "Importanza e Principi Fondamentali",
            "text": "",
            "img": "/blog-image/leadGeneration.png", 
            "page": "lead-generation",
            "data": "20 Nov 2023",
            "author": "Staff",
            "component": <LeadGeneration />
        },
        {
            "id": 2,
            "title": "Il Ruolo Fondamentale della Lead Generation nel Successo Aziendale",
            "desc": "",
            "text": "",
            "img": "/blog-image/ruoloLG.png", 
            "page": "ruolo-lead-generation",
            "data": "22 Nov 2023",
            "author": "Staff",
            "component": <RuoloLeadGeneration />
        },
        {
            "id": 3,
            "title": "Lead Generation",
            "desc": "La Chiave per Mantenere una Pipeline di Vendita Salutare",
            "text": "",
            "img": "/blog-image/pipelineLG.png", 
            "page": "pipeline-lead-generation",
            "data": "23 Nov 2023",
            "author": "Staff",
            "component": <PipelineLeadGeneration />
        }, 
        {
            "id": 4,
            "title": "L'Importanza della Lead Generation nel Contesto del Marketing Digitale",
            "desc": "",
            "text": "La Lead Generation non solo rappresenta un modo per acquisire contatti, ma si configura come un processo strategico che permette alle aziende di identificare e catturare opportunità di business di elevato valore.",
            "img": "/blog-image/marketingLG.png", 
            "page": "marketing-lead-generation",
            "data": "24 Nov 2023",
            "author": "Staff",
            "component": <MarketingLeadGeneration />
        },
    ] 
    return articles;
}

export default JsonBlog; 