import React from "react";
import Cookies from "universal-cookie";

//BOOTSTRAP
import Pagination from "react-bootstrap/Pagination";

//MUI
import { Box, CircularProgress, DialogActions, Grid, Tooltip } from "@material-ui/core";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";

//API
import getUtenti from "../../services/getUtenti";
import editUtente from "../../services/editUtente";
import addUtente from "../../services/addUtente";
import deleteUser from "../../services/deleteUser";
import searchUserByName from "../../services/searchUserByName";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTrashCan } from "@fortawesome/free-solid-svg-icons";

import { Alert, FormControlLabel, Stack, Switch, Typography } from "@mui/material";
import { Snackbar, TextField } from "@material-ui/core";

import { Dialog, DialogContent, DialogContentText, DialogTitle, Slide } from "@mui/material";

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

const cookie = new Cookies();
const username = cookie.get("username", { path: "/" });
const token = cookie.get("token", { path: "/" });

const headCells = [
	{
		id: "nome",
		numeric: false,
		disablePadding: false,
		label: "Nome",
	},
	{
		id: "cognome",
		numeric: false,
		disablePadding: false,
		label: "Cognome",
	},
	{
		id: "nome_azienda",
		numeric: false,
		disablePadding: false,
		label: "Nome Azienda",
	},
	{
		id: "ragione_sociale",
		numeric: false,
		disablePadding: false,
		label: "R. Sociale",
	},
	{
		id: "telefono",
		numeric: false,
		disablePadding: false,
		label: "Telefono",
	},
	{
		id: "p_iva",
		numeric: false,
		disablePadding: false,
		label: "P. IVA",
	},
	{
		id: "username",
		numeric: false,
		disablePadding: false,
		label: "Username",
	},
	{
		id: "email",
		numeric: false,
		disablePadding: false,
		label: "Email",
	},
	{
		id: "indirizzo",
		numeric: false,
		disablePadding: false,
		label: "Indirizzo",
	},
	{
		id: "cap",
		numeric: false,
		disablePadding: false,
		label: "Cap",
	},
	{
		id: "city",
		numeric: false,
		disablePadding: false,
		label: "Città",
	},
	{
		id: "provincia",
		numeric: false,
		disablePadding: false,
		label: "Provincia",
	},
	{
		id: "codice_univoco",
		numeric: false,
		disablePadding: false,
		label: "Codice Univoco",
	},
	{
		id: "balance",
		numeric: false,
		disablePadding: false,
		label: "Chiamate residue",
	},
	{
		id: "link_calendly",
		numeric: false,
		disablePadding: false,
		label: "Link Calendly",
	},
	{
		id: "email_calendly",
		numeric: false,
		disablePadding: false,
		label: "Email Calendly",
	},
	{
		id: "link_info_page",
		numeric: false,
		disablePadding: false,
		label: "Link Info Page",
	},
	{
		id: "token_calendly",
		numeric: false,
		disablePadding: false,
		label: "Token Calendly",
	},
	{
		id: "parole_da_escludere",
		numeric: false,
		disablePadding: false,
		label: "Parole da escludere",
	},
	{
		id: "actions",
		numeric: false,
		disablePadding: false,
		label: "Azioni",
	},
];

const inputPadding = { style: { padding: 8, width: "fit-content", minWidth: "200px" } };

export default class ListeTable extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			rows: [],
			// page: 1,
			// maxPage: 1,
			total: 0,
			filter: "",
			modal: {
				show: false,
				fullName: "",
				id: undefined,
			},
			modalText: {
				show: false,
				id: 0,
				content: "",
			},
			toastAlert: {
				open: false,
				message: "",
				severity: "",
			},
			modalNuovoUser: false, 
		};
	}

	componentDidMount() {
		this.reload();
	}

	componentDidUpdate() {
		if (this.state.toastAlert.open) {
			setTimeout(() => {
				this.setState({
					toastAlert: {
						open: false,
						message: "",
						severity: "",
					},
				});
			}, 5000);
		}
	}

	async reload() {
		await getUtenti(username, token /*, this.state.page*/).then(res => {
			if (res["result"] === "KO") {
				this.setState({
					toastAlert: {
						open: true,
						message: "Errore durante il caricamento degli account",
						severity: "error",
					},
				});
				return;
			}

			this.setState({ rows: res.profili, /* maxPage: res.max_page,*/ total: res.total });
		});
	}

	handleAddUser = () => {
		this.setState({
			modalNuovoUser: true,
			new_user: {
				nome: "",
				cognome: "",
				telefono: "",
				nome_azienda: "",
				ragione_sociale: "",
				p_iva: "",
				username: "",
				email: "",
				password: "",
				balance: 0,
				cap: "",
				city: "",
				provincia: "",
				email_calendly: "",
				link_calendly: "",
				token_calendly: "",
				link_info_page: "",
				parole_da_escludere: "",
				linkedin_account: false,
				codice_fiscale: "",
				iban: "", 
			},
		});
	};

	cancel = () => {
		let modal = this.state.modal;
		modal.show = false;
		this.setState({ modal });
	};

	handleFilter = async filter => {
		let res = await searchUserByName(username, token, 1, filter);
		this.setState({ rows: res.profili, maxPage: res.max_page, filter, page: 1, total: res.total });
	};

	handleDelete = async id => {
		let res = await deleteUser(username, token, id);
		if (res.result === "ok") {
			await searchUserByName(username, token, this.state.page, this.state.filter).then(res => {
				if (res["result"] === "KO") {
					this.setState({
						toastAlert: {
							open: true,
							message: "Errore durante il caricamento degli account",
							severity: "error",
						},
					});
					return;
				} else {
					this.setState({
						toastAlert: {
							open: true,
							message: "Account eliminato con successo",
							severity: "success",
						},
						rows: res.profili,
						maxPage: res.max_page,
						total: res.total,
					});
				}
			});
		}
	};

	// handlePageChange = async (page) => {
	//     if(!this.state.filter) {
	//         await getUtenti(username, token, page).then(res => {
	//             if (res["result"] === "KO") {
	//                 this.setState({
	//                     toastAlert: {
	//                         open: true,
	//                         message: "Errore durante il caricamento degli account",
	//                         severity: "error",
	//                     },
	//                 });
	//                 return;
	//             }
	//             this.setState({ rows: res.profili, maxPage: res.max_page, page: page })
	//         });
	//     } else {
	//         let res = await searchUserByName(username, token, page, this.state.filter);
	//         this.setState({ rows: res.profili, maxPage: res.max_page, page: page })
	//     }
	// }

	handleSave = async () => {
		const emailRegex = new RegExp("^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$");
		const passwordRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})");

		if (!emailRegex.test(this.state.new_user.email)) {
			this.setState({
				toastAlert: {
					open: true,
					message: "Email non valida",
					severity: "error",
				},
			});
			return;
		}

		if (!passwordRegex.test(this.state.new_user.password)) {
			this.setState({
				toastAlert: {
					open: true,
					message:
						"La password deve contenere almeno 8 caratteri, una lettera maiuscola, una minuscola e un numero",
					severity: "error",
				},
			});
			return;
		}

		if (
			(!this.state.new_user.nome ||
			!this.state.new_user.cognome ||
			!this.state.new_user.telefono ||
			!this.state.new_user.nome_azienda ||
			!this.state.new_user.ragione_sociale ||
			!this.state.new_user.p_iva ||
			!this.state.new_user.username ||
			!this.state.new_user.email ||
			!this.state.new_user.password ||
			!this.state.new_user.indirizzo ||
			!this.state.new_user.cap ||
			!this.state.new_user.city ||
			!this.state.new_user.provincia ||
			!this.state.new_user.codice_univoco) && this.state.new_user.linkedin_account === false
		) {
			this.setState({
				toastAlert: {
					open: true,
					message: "Compila tutti i campi obbligatori",
					severity: "error",
				},
			});
			return;
		}

		if (this.state.new_user.linkedin_account === true && (
			!this.state.new_user.nome ||
			!this.state.new_user.cognome ||
			!this.state.new_user.username ||
			!this.state.new_user.email ||
			!this.state.new_user.password ||
			!this.state.new_user.indirizzo ||
			!this.state.new_user.cap ||
			!this.state.new_user.city ||
			!this.state.new_user.provincia ||
			!this.state.new_user.codice_fiscale ||
			!this.state.new_user.iban
		)) {
			this.setState({
				toastAlert: {
					open: true,
					message: "Compila tutti i campi obbligatori",
					severity: "error",
				},
			});
			return;
		}

		let res = await addUtente(
			username,
			token,
			this.state.new_user.nome,
			this.state.new_user.cognome,
			this.state.new_user.telefono,
			this.state.new_user.ragione_sociale,
			this.state.new_user.nome_azienda,
			this.state.new_user.p_iva,
			this.state.new_user.username,
			this.state.new_user.email,
			this.state.new_user.password,
			this.state.new_user.indirizzo,
			this.state.new_user.cap,
			this.state.new_user.city,
			this.state.new_user.provincia,
			this.state.new_user.codice_univoco,
			this.state.new_user.email_calendly,
			this.state.new_user.token_calendly,
			this.state.new_user.link_calendly,
			this.state.new_user.link_info_page,
			this.state.new_user.balance,
			this.state.new_user.parole_da_escludere,
			this.state.new_user.linkedin_account,
			this.state.new_user.codice_fiscale,
			this.state.new_user.iban
		);
		if (res.result === "ok") {
			await searchUserByName(username, token, this.state.page, this.state.filter).then(res => {
				if (res["result"] === "KO") {
					this.setState({
						toastAlert: {
							open: true,
							message: "Errore durante il caricamento degli account",
							severity: "error",
						},
					});
					return;
				} else {
					this.reload();
					this.setState({
						toastAlert: {
							open: true,
							message: "Account aggiunto con successo",
							severity: "success",
						},
						modalNuovoUser: false,
					});
				}
			});
		} else {
			switch (res.description) {
				case "email error":
					this.setState({
						toastAlert: {
							open: true,
							message: "Email già in uso",
							severity: "error",
						},
					});
					break;
				case "username error":
					this.setState({
						toastAlert: {
							open: true,
							message: "Username già in uso",
							severity: "error",
						},
					});
					break;
				default:
					this.setState({
						toastAlert: {
							open: true,
							message: "Errore durante l'aggiunta dell'account",
							severity: "error",
						},
					});
					break;
			}
		}
	};

	render() {
		if (this.state.loading) {
			return (
				<div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
					<CircularProgress />
				</div>
			);
		}

		//PAGINATON
		// let items = []
		// if (this.state.page !== 1) {
		//     items.push(<Pagination.First key='first' onClick={() => this.handlePageChange(1)} />)
		//     items.push(<Pagination.Prev key='prev' onClick={() => this.handlePageChange(this.state.page - 1)} />)
		// }
		// if (this.state.maxPage <= 5) {
		//     for (let number = 1; number <= this.state.maxPage; number++) {
		//         items.push(
		//             <Pagination.Item key={number} active={number === this.state.page} onClick={() => this.handlePageChange(number)}>
		//                 {number}
		//             </Pagination.Item>,
		//         )
		//     }
		// } else {
		//     if (this.state.page > 1) { items.push(<Pagination.Ellipsis key='elipsis1' />) }

		//     for (let number = this.state.page - 3; number <= this.state.page + 3; number++) {
		//         if (number > 0 && number <= this.state.maxPage) {
		//             items.push(
		//                 <Pagination.Item key={number} active={number === this.state.page} onClick={() => this.handlePageChange(number)}>
		//                     {number}
		//                 </Pagination.Item>,
		//             )
		//         }
		//     }

		//     if (this.state.page < this.state.maxPage) { items.push(<Pagination.Ellipsis key='elipsis2' />) }
		// }

		// if (this.state.page < this.state.maxPage) {
		//     items.push(<Pagination.Next key='next' onClick={() => this.handlePageChange(this.state.page + 1)} />)
		//     items.push(<Pagination.Last key='last' onClick={() => this.handlePageChange(this.state.maxPage)} />)
		// }

		return (
			<div
				style={{
					width: "96%",
					marginInline: "auto",
					marginTop: "20px",
					marginBottom: "20px",
					maxHeight: "90%",
					overflow: "hidden",
				}}
			>
				<Stack spacing={2} sx={{ width: "100%" }}>
					<Snackbar
						open={this.state.toastAlert.open}
						autoHideDuration={6000}
						onClose={this.handleCloseAlert}
						anchorOrigin={{ horizontal: "right", vertical: "top" }}
					>
						<Alert
							onClose={this.handleCloseAlert}
							severity={this.state.toastAlert.severity}
							sx={{ width: "100%" }}
						>
							{this.state.toastAlert.message}
						</Alert>
					</Snackbar>
				</Stack>
				{this.state.modalText.show && (
					<Dialog
						maxWidth="md"
						fullWidth={true}
						open={this.state.modalText.show}
						TransitionComponent={Transition}
						keepMounted
						onClose={() => this.setState({ modalText: { show: false } })}
						aria-describedby="alert-dialog-slide-description"
						
					>
						<Grid className="p-5">
							<TextField
								multiline
								rows={6}
								label={this.state.modalText.title}
								variant="standard"
								value={this.state.modalText.content}
								onChange={event => {
									const updatedRows = this.state.rows.map(row => {
										if (row.id === this.state.modalText.row.id) {
											const fieldName = this.state.modalText.fieldName;
											return { ...row, [fieldName]: event.target.value };
										}
										return row;
									});
									this.setState({
										modalText: { ...this.state.modalText, content: event.target.value },
										rows: updatedRows,
									});
								}}
								fullWidth
							/>
						</Grid>
						<DialogActions>
							<Button
								className="myButton"
								variant="contained"
								onClick={() => this.setState({ modalText: { show: false } })}
							>
								Chiudi
							</Button>
							<Button
								variant="contained"
								className="myButton"
								onClick={() => {
									this.state.rows.map(row => {
										if (row.id === this.state.modalText.id) {
											editUtente(username, token, row);
										}
										return false;
									});
									this.setState({
										modalText: {
											show: false,
										},
									});
								}}
							>
								Salva
							</Button>
						</DialogActions>
					</Dialog>
				)}
				{this.state.modalNuovoUser && (
					<Dialog
						maxWidth="xl"
						fullWidth={true}
						open={this.state.modalNuovoUser}
						TransitionComponent={Transition}
						keepMounted
						onClose={() => this.setState({ modalNuovoUser: false })}
						aria-describedby="alert-dialog-slide-description"
					>	
						<div className="d-flex justify-content-between flex-row">
							<div>
								<DialogTitle>Aggiungi un nuovo utente</DialogTitle>
								<DialogContent>
									<DialogContentText id="alert-dialog-slide-description">
										Compila i campi per aggiungere un nuovo utente
									</DialogContentText>
								</DialogContent>
							</div>
							<FormControlLabel control={<Switch onChange={() => {let new_user = this.state.new_user; new_user.linkedin_account = !new_user.linkedin_account; this.setState({ new_user });}} />} label="LinkedIn Account" />
						</div>
						<Grid container spacing={2}>
							<Grid item xs={12} lg={4}>
								<Box paddingX={4} paddingY={1}>
									<TextField
										label="Nome*"
										variant="standard"
										value={this.state.new_user.nome}
										onChange={event => {
											let new_user = this.state.new_user;
											new_user.nome = event.target.value;
											this.setState({ new_user });
										}}
										fullWidth
									/>
								</Box>
							</Grid>
							<Grid item xs={12} lg={4}>
								<Box paddingX={4} paddingY={1}>
									<TextField
										label="Cognome*"
										variant="standard"
										value={this.state.new_user.cognome}
										onChange={event => {
											let new_user = this.state.new_user;
											new_user.cognome = event.target.value;
											this.setState({ new_user });
										}}
										fullWidth
									/>
								</Box>
							</Grid>
							{
								this.state.new_user.linkedin_account === false && (
									<Grid item xs={12} lg={4}>
										<Box paddingX={4} paddingY={1}>
											<TextField
												label="Telefono*"
												variant="standard"
												value={this.state.new_user.telefono}
												onChange={event => {
													let new_user = this.state.new_user;
													new_user.telefono = event.target.value;
													this.setState({ new_user });
												}}
												fullWidth
											/>
										</Box>
									</Grid>
								)
							}
							{
								this.state.new_user.linkedin_account === true && (
									<>
										<Grid item xs={12} lg={4}>
											<Box paddingX={4} paddingY={1}>
												<TextField
													label="Codice Fiscale*"
													variant="standard"
													value={this.state.new_user.codice_fiscale}
													onChange={event => {
														let new_user = this.state.new_user;
														new_user.codice_fiscale = event.target.value;
														this.setState({ new_user });
													}}
													fullWidth
													inputProps={{ style: { textTransform: "uppercase" } }}
												/>
											</Box>
										</Grid>
										<Grid item xs={12} lg={4}>
											<Box paddingX={4} paddingY={1}>
												<TextField
													label="IBAN*"
													variant="standard"
													value={this.state.new_user.iban}
													onChange={event => {
														let new_user = this.state.new_user;
														new_user.iban = event.target.value;
														this.setState({ new_user });
													}}
													fullWidth
													inputProps={{ style: { textTransform: "uppercase" } }}
												/>
											</Box>
										</Grid>
									</>
								)
							}
							<Grid item xs={12} lg={4}>
								<Box paddingX={4} paddingY={1}>
									<TextField
										label="Username*"
										variant="standard"
										value={this.state.new_user.username}
										onChange={event => {
											let new_user = this.state.new_user;
											new_user.username = event.target.value;
											this.setState({ new_user });
										}}
										fullWidth
									/>
								</Box>
							</Grid>
							<Grid item xs={12} lg={4}>
								<Box paddingX={4} paddingY={1}>
									<TextField
										label="Email*"
										variant="standard"
										value={this.state.new_user.email}
										onChange={event => {
											let new_user = this.state.new_user;
											new_user.email = event.target.value;
											this.setState({ new_user });
										}}
										fullWidth
									/>
								</Box>
							</Grid> 
							<Grid item xs={12} lg={4}>
								<Box paddingX={4} paddingY={1}>
									<TextField
										label="Password*"
										variant="standard"
										value={this.state.new_user.password}
										onChange={event => {
											let new_user = this.state.new_user;
											new_user.password = event.target.value;
											this.setState({ new_user });
										}}
										fullWidth
									/>
								</Box>
							</Grid>
							{
								this.state.new_user.linkedin_account === false && (
									<> 
										<Grid item xs={12} lg={4}>
											<Box paddingX={4} paddingY={2}>
												<TextField
													label="Nome Azienda*"
													variant="standard"
													value={this.state.new_user.nome_azienda}
													onChange={event => {
														let new_user = this.state.new_user;
														new_user.nome_azienda = event.target.value;
														this.setState({ new_user });
													}}
													fullWidth
												/>
											</Box>
										</Grid>
										<Grid item xs={12} lg={4}>
											<Box paddingX={4} paddingY={1}>
												<TextField
													label="Ragione Sociale*"
													variant="standard"
													value={this.state.new_user.ragione_sociale}
													onChange={event => {
														let new_user = this.state.new_user;
														new_user.ragione_sociale = event.target.value;
														this.setState({ new_user });
													}}
													fullWidth
												/>
											</Box>
										</Grid>
										<Grid item xs={12} lg={4}>
											<Box paddingX={4} paddingY={1}>
												<TextField
													label="Partita IVA*"
													variant="standard"
													value={this.state.new_user.p_iva}
													onChange={event => {
														let new_user = this.state.new_user;
														new_user.p_iva = event.target.value;
														this.setState({ new_user });
													}}
													fullWidth
												/>
											</Box>
										</Grid>
										<Grid item xs={12} lg={4}>
											<Box paddingX={4} paddingY={1}>
												<TextField
													label="Codice Univoco*"
													variant="standard"
													value={this.state.new_user.codice_univoco}
													onChange={event => {
														let new_user = this.state.new_user;
														new_user.codice_univoco = event.target.value;
														this.setState({ new_user });
													}}
													fullWidth
												/>
											</Box>
										</Grid>
									</>
								)
							}
							
							<Grid item xs={12} lg={4}>
								<Box paddingX={4} paddingY={1}>
									<TextField
										label="Indirizzo*"
										variant="standard"
										value={this.state.new_user.indirizzo}
										onChange={event => {
											let new_user = this.state.new_user;
											new_user.indirizzo = event.target.value;
											this.setState({ new_user });
										}}
										fullWidth
									/>
								</Box>
							</Grid>
							<Grid item xs={12} lg={4}>
								<Box paddingX={4} paddingY={1}>
									<TextField
										label="Cap*"
										variant="standard"
										value={this.state.new_user.cap}
										onChange={event => {
											let new_user = this.state.new_user;
											new_user.cap = event.target.value;
											this.setState({ new_user });
										}}
										fullWidth
									/>
								</Box>
							</Grid>
							<Grid item xs={12} lg={4}>
								<Box paddingX={4} paddingY={1}>
									<TextField
										label=" Città*"
										variant="standard"
										value={this.state.new_user.city}
										onChange={event => {
											let new_user = this.state.new_user;
											new_user.city = event.target.value;
											this.setState({ new_user });
										}}
										fullWidth
									/>
								</Box>
							</Grid>
							<Grid item xs={12} lg={4}>
								<Box paddingX={4} paddingY={1}>
									<TextField
										label=" Provincia*"
										variant="standard"
										value={this.state.new_user.provincia}
										onChange={event => {
											let new_user = this.state.new_user;
											new_user.provincia = event.target.value;
											this.setState({ new_user });
										}}
										fullWidth
									/>
								</Box>
							</Grid>
							{
								this.state.new_user.linkedin_account === false && (
									<>
									<Grid item xs={12} lg={4}>
										<Box paddingX={4} paddingY={1}>
											<TextField
												label="Chiamate residue"
												variant="standard"
												type="number"
												value={this.state.new_user.balance}
												onChange={event => {
													let new_user = this.state.new_user;
													new_user.balance = event.target.value;
													this.setState({ new_user });
												}}
												fullWidth
											/>
										</Box>
									</Grid>
									<Grid item xs={12} lg={4}>
										<Box paddingX={4} paddingY={1}>
											<TextField
												label="Link Calendly"
												variant="standard"
												value={this.state.new_user.link_calendly}
												onChange={event => {
													let new_user = this.state.new_user;
													new_user.link_calendly = event.target.value;
													this.setState({ new_user });
												}}
												fullWidth
											/>
										</Box>
									</Grid>
									<Grid item xs={12} lg={4}>
										<Box paddingX={4} paddingY={1}>
											<TextField
												label="Email Calendly"
												variant="standard"
												value={this.state.new_user.email_calendly}
												onChange={event => {
													let new_user = this.state.new_user;
													new_user.email_calendly = event.target.value;
													this.setState({ new_user });
												}}
												fullWidth
											/>
										</Box>
									</Grid>
									<Grid item xs={12} lg={4}>
										<Box paddingX={4} paddingY={1}>
											<TextField
												label="Link Info Page"
												variant="standard"
												value={this.state.new_user.link_info_page}
												onChange={event => {
													let new_user = this.state.new_user;
													new_user.link_info_page = event.target.value;
													this.setState({ new_user });
												}}
												fullWidth
											/>
										</Box>
									</Grid>
									<Grid item xs={12}></Grid>
									<Grid item xs={12} lg={6}>
										<Box paddingX={4} paddingY={1}>
											<TextField
												multiline
												rows={6}
												label="Token Calendly"
												variant="standard"
												value={this.state.new_user.token_calendly}
												onChange={event => {
													let new_user = this.state.new_user;
													new_user.token_calendly = event.target.value;
													this.setState({ new_user });
												}}
												fullWidth
											/>
										</Box>
									</Grid>
									<Grid item xs={12} lg={6}>
										<Box paddingX={4} paddingY={1}>
											<TextField
												multiline
												rows={6}
												label="Parole da escludere"
												variant="standard"
												value={this.state.new_user.parole_da_escludere}
												onChange={event => {
													let new_user = this.state.new_user;
													new_user.parole_da_escludere = event.target.value;
													this.setState({ new_user });
												}}
												fullWidth
											/>
										</Box>
									</Grid>
									</>
								)
							}
						</Grid>
						<Box paddingX={4} paddingY={1}>
							<Typography>I campi contrassegnati con * sono obbligatori</Typography>
						</Box>
						<DialogActions>
							<Button
								className="myButton"
								variant="contained"
								onClick={() => this.setState({ modalNuovoUser: false })}
							>
								Chiudi
							</Button>
							<Button variant="contained" className="myButton" onClick={this.handleSave}>
								Salva
							</Button>
						</DialogActions>
					</Dialog>
				)}

				{/* HEADER */}
				<div className="d-flex align-items-center">
					<TextField
						label="Cerca"
						variant="standard"
						className="p-0 me-2 mb-2"
						onChange={event => {
							this.setState({ filter: event.target.value });
						}}
						onKeyDown={event => {
							if (event.key === "Enter") {
								this.handleFilter(event.target.value);
							}
						}}
						onBlur={event => {
							this.handleFilter(event.target.value);
						}}
					/>
					{/* <Pagination className='m-0 me-2 ms-auto'>
                        {items}
                    </Pagination> */}
					<Button variant="contained" className="myButton ms-auto" onClick={this.handleAddUser}>
						<FontAwesomeIcon icon={faPlus} style={{ marginRight: "0.5rem" }} />
						Aggiungi Utente
					</Button>
				</div>
				{/* TABLE */}
				<TableContainer component={Paper} sx={{ overflow: "auto", height: "80vh" }}>
					<Table striped bordered hover responsive>
						<TableHead>
							<TableRow>
								{headCells.map(headCell => (
									<TableCell key={headCell.id}>{headCell.label}</TableCell>
								))}
							</TableRow>
						</TableHead>
						<TableBody>
							{this.state.rows.length > 0 &&
								this.state.rows.map((row, i) => (
									<TableRow key={row.id}>
										<TableCell>
											<TextField
												{...inputPadding}
												value={row.nome}
												onChange={event => {
													let rows = this.state.rows;
													rows[i].nome = event.target.value;
													this.setState({ rows });
												}}
												onBlur={() => {
													editUtente(username, token, this.state.rows[i]);
												}}
												onKeyPress={event => {
													if (event.key === "Enter") {
														editUtente(username, token, this.state.rows[i]);
													}
												}}
											/>
										</TableCell>
										<TableCell>
											<TextField
												{...inputPadding}
												value={row.cognome}
												onChange={event => {
													let rows = this.state.rows;
													rows[i].cognome = event.target.value;
													this.setState({ rows });
												}}
												onBlur={() => {
													editUtente(username, token, this.state.rows[i]);
												}}
												onKeyPress={event => {
													if (event.key === "Enter") {
														editUtente(username, token, this.state.rows[i]);
													}
												}}
											/>
										</TableCell>
										<TableCell>
											<TextField
												{...inputPadding}
												value={row.nome_azienda}
												onChange={event => {
													let rows = this.state.rows;
													rows[i].nome_azienda = event.target.value;
													this.setState({ rows });
												}}
												onBlur={() => {
													editUtente(username, token, this.state.rows[i]);
												}}
												onKeyPress={event => {
													if (event.key === "Enter") {
														editUtente(username, token, this.state.rows[i]);
													}
												}}
											/>
										</TableCell>
										<TableCell>
											<TextField
												{...inputPadding}
												value={row.ragione_sociale}
												onChange={event => {
													let rows = this.state.rows;
													rows[i].ragione_sociale = event.target.value;
													this.setState({ rows });
												}}
												onBlur={() => {
													editUtente(username, token, this.state.rows[i]);
												}}
												onKeyPress={event => {
													if (event.key === "Enter") {
														editUtente(username, token, this.state.rows[i]);
													}
												}}
											/>
										</TableCell>
										<TableCell>
											<TextField
												{...inputPadding}
												value={row.telefono}
												onChange={event => {
													let rows = this.state.rows;
													rows[i].telefono = event.target.value;
													this.setState({ rows });
												}}
												onBlur={() => {
													editUtente(username, token, this.state.rows[i]);
												}}
												onKeyPress={event => {
													if (event.key === "Enter") {
														editUtente(username, token, this.state.rows[i]);
													}
												}}
											/>
										</TableCell>
										<TableCell>
											<TextField
												{...inputPadding}
												value={row.p_iva}
												onChange={event => {
													let rows = this.state.rows;
													rows[i].p_iva = event.target.value;
													this.setState({ rows });
												}}
												onBlur={() => {
													editUtente(username, token, this.state.rows[i]);
												}}
												onKeyPress={event => {
													if (event.key === "Enter") {
														editUtente(username, token, this.state.rows[i]);
													}
												}}
											/>
										</TableCell>
										<TableCell>
											<TextField
												{...inputPadding}
												value={row.username}
												onChange={event => {
													let rows = this.state.rows;
													rows[i].username = event.target.value;
													this.setState({ rows });
												}}
												onBlur={() => {
													editUtente(username, token, this.state.rows[i]);
												}}
												onKeyPress={event => {
													if (event.key === "Enter") {
														editUtente(username, token, this.state.rows[i]);
													}
												}}
											/>
										</TableCell>
										<TableCell>
											<TextField
												{...inputPadding}
												value={row.email}
												onChange={event => {
													let rows = this.state.rows;
													rows[i].email = event.target.value;
													this.setState({ rows });
												}}
												onBlur={() => {
													editUtente(username, token, this.state.rows[i]);
												}}
												onKeyPress={event => {
													if (event.key === "Enter") {
														editUtente(username, token, this.state.rows[i]);
													}
												}}
											/>
										</TableCell>
										<TableCell>
											<TextField
												{...inputPadding}
												value={row.indirizzo}
												onChange={event => {
													let rows = this.state.rows;
													rows[i].indirizzo = event.target.value;
													this.setState({ rows });
												}}
												onBlur={() => {
													editUtente(username, token, this.state.rows[i]);
												}}
												onKeyPress={event => {
													if (event.key === "Enter") {
														editUtente(username, token, this.state.rows[i]);
													}
												}}
											/>
										</TableCell>
										<TableCell>
											<TextField
												{...inputPadding}
												value={row.cap}
												onChange={event => {
													let rows = this.state.rows;
													rows[i].cap = event.target.value;
													this.setState({ rows });
												}}
												onBlur={() => {
													editUtente(username, token, this.state.rows[i]);
												}}
												onKeyPress={event => {
													if (event.key === "Enter") {
														editUtente(username, token, this.state.rows[i]);
													}
												}}
											/>
										</TableCell>
										<TableCell>
											<TextField
												{...inputPadding}
												value={row.city}
												onChange={event => {
													let rows = this.state.rows;
													rows[i].city = event.target.value;
													this.setState({ rows });
												}}
												onBlur={() => {
													editUtente(username, token, this.state.rows[i]);
												}}
												onKeyPress={event => {
													if (event.key === "Enter") {
														editUtente(username, token, this.state.rows[i]);
													}
												}}
											/>
										</TableCell>
										<TableCell>
											<TextField
												{...inputPadding}
												value={row.provincia}
												onChange={event => {
													let rows = this.state.rows;
													rows[i].provincia = event.target.value;
													this.setState({ rows });
												}}
												onBlur={() => {
													editUtente(username, token, this.state.rows[i]);
												}}
												onKeyPress={event => {
													if (event.key === "Enter") {
														editUtente(username, token, this.state.rows[i]);
													}
												}}
											/>
										</TableCell>
										<TableCell>
											<TextField
												{...inputPadding}
												value={row.codice_univoco}
												onChange={event => {
													let rows = this.state.rows;
													rows[i].codice_univoco = event.target.value;
													this.setState({ rows });
												}}
												onBlur={() => {
													editUtente(username, token, this.state.rows[i]);
												}}
												onKeyPress={event => {
													if (event.key === "Enter") {
														editUtente(username, token, this.state.rows[i]);
													}
												}}
											/>
										</TableCell>
										<TableCell>
											<TextField
												type="number"
												{...inputPadding}
												value={row.balance}
												onChange={event => {
													let rows = this.state.rows;
													rows[i].balance = event.target.value;
													this.setState({ rows });
												}}
												onBlur={() => {
													editUtente(username, token, this.state.rows[i]);
												}}
												onKeyPress={event => {
													if (event.key === "Enter") {
														editUtente(username, token, this.state.rows[i]);
													}
												}}
											/>
										</TableCell>
										<TableCell>
											<TextField
												{...inputPadding}
												value={row.link_calendly}
												onChange={event => {
													let rows = this.state.rows;
													rows[i].link_calendly = event.target.value;
													this.setState({ rows });
												}}
												onBlur={() => {
													editUtente(username, token, this.state.rows[i]);
												}}
												onKeyPress={event => {
													if (event.key === "Enter") {
														editUtente(username, token, this.state.rows[i]);
													}
												}}
											/>
										</TableCell>
										<TableCell>
											<TextField
												{...inputPadding}
												value={row.email_calendly}
												onChange={event => {
													let rows = this.state.rows;
													rows[i].email_calendly = event.target.value;
													this.setState({ rows });
												}}
												onBlur={() => {
													editUtente(username, token, this.state.rows[i]);
												}}
												onKeyPress={event => {
													if (event.key === "Enter") {
														editUtente(username, token, this.state.rows[i]);
													}
												}}
											/>
										</TableCell>
										<TableCell>
											<TextField
												{...inputPadding}
												value={row.link_info_page}
												onChange={event => {
													let rows = this.state.rows;
													rows[i].link_info_page = event.target.value;
													this.setState({ rows });
												}}
												onBlur={() => {
													editUtente(username, token, this.state.rows[i]);
												}}
												onKeyPress={event => {
													if (event.key === "Enter") {
														editUtente(username, token, this.state.rows[i]);
													}
												}}
											/>
										</TableCell>
										<TableCell>
											<Button
												onClick={() => {
													this.setState({
														modalText: {
															show: true,
															id: row.id,
															row: row,
															content: row.token_calendly,
															fieldName: "token_calendly",
															title: "Token Calendly",
														},
													});
												}}
											>
												Token Calendly
											</Button>
										</TableCell>
										<TableCell>
											<Button
												onClick={() => {
													this.setState({
														modalText: {
															show: true,
															id: row.id,
															row: row,
															content: row.parole_da_escludere,
															fieldName: "parole_da_escludere",
															title: "Parole da escludere",
														},
													});
												}}
											>
												Parole da escludere
											</Button>
										</TableCell>
										<TableCell>
											<Tooltip title="Elimina utente">
												<Button
													onClick={() =>
														window.confirm(
															`Sei sicuro di voler eliminare l'account di ${row.nome} ${row.cognome}? \nL'operazione è irreversibile e verranno cancellati tutti i dati relativi all'utente`
														)
															? this.handleDelete(row.id)
															: null
													}
													color="inherit"
													variant="text"
												>
													<FontAwesomeIcon size={"lg"} icon={faTrashCan} />
												</Button>
											</Tooltip>
										</TableCell>
									</TableRow>
								))}
						</TableBody>
					</Table>
				</TableContainer>
			</div>
		);
	}
}
