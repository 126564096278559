import { Button, Checkbox } from "@mui/material";
import React from "react";
import { Modal } from "react-bootstrap";

const StatusModal = (props) => {
    const [accept, setAccept] = React.useState(false);
    return (
        <>
            <Modal
                size={props.title === "Condizioni di Servizio" ? "lg" : "md"}
                show={props.show}
                onHide={
                    props.title === "Condizioni di Servizio" ? "" : props.onHide
                }
                centered
                style={props.title === "Condizioni di Servizio" ? {
                    height: 500,
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)", 
                    overflow: "visible"
                } : {}}
            >
                <Modal.Header closeButton>
                    <Modal.Title>{props.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body style={props.title === "Condizioni di Servizio" ? {height:400, overflow:'scroll'} : {}}>
                    <p className="HeeboRegular">{props.message}</p>
                </Modal.Body>
                <Modal.Footer className="justify-content-between">
                    {props.title === "Condizioni di Servizio" && (
                        <div>
                            <Checkbox
                                checked={accept}
                                onClick={() => setAccept(!accept)}
                                color="default"
                            />
                            <span>Accetta le Condizioni di Servizio</span>
                        </div>
                    )}
                    <Button
                        variant="contained"
                        className={props.title === "Condizioni di Servizio" && !accept ? "disabled" : "myButton"}
                        onClick={props.onHide}
                        disabled={
                            props.title === "Condizioni di Servizio" && !accept
                        }
                    >
                        {props.buttonText}
                    </Button>
                    {
                        props.title === "Condizioni di Servizio" && (
                            <Button
                                variant="contained"
                                className="myButton"
                                onClick={props.onLogout}
                            >
                                Logout
                            </Button>
                        )
                    }
                </Modal.Footer>
            </Modal>
        </>
    );
};
export default StatusModal;
