
import React, { useEffect } from "react"; 
import * as Icon from 'react-feather';
import { Link } from 'react-router-dom';
import BlogSidebar from '../../components/Blog/BlogSidebar.js'; 
import Blog from '../../utils/JsonBlog.js';
import PageBanner from "../../components/PageBanner.js";

const articleData = {
    "id": 2,
    "title": "Il Ruolo Fondamentale della Lead Generation nel Successo Aziendale",
    "desc": "",
    "text": "",
    "img": "/blog-image/ruoloLG.png", 
    "page": "ruolo-lead-generation",
    "data": "22 Nov 2023",
    "author": "Staff"
}

const LeadGeneration = () => {
    const [isMobile, setMobile] = React.useState(false);

    const handleResize = () => {
        if (window.innerWidth < 1275) {
            setMobile(true);
        } else {
            setMobile(false);
        }
    }

    useEffect(() => {
        window.addEventListener("resize", handleResize);
        handleResize();

        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return( 
        <>
            <PageBanner pageTitle={articleData.title} /> 
            <div className="blog-details-area ptb-80">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 col-md-12">
                            <div className="blog-details-desc">
                                <div className="article-image">
                                    <img className="w-100" src={require('../../images' + articleData.img)} alt="imge" />
                                </div>

                                <div className="article-content">
                                    <div className="entry-meta">
                                        <ul>
                                            <li>
                                                <Icon.Clock /> {articleData.data}
                                            </li>
                                            <li>
                                                <Icon.User /> <Link className="text-decoration-none" to="#">{articleData.author}</Link>
                                            </li>
                                        </ul>
                                    </div>  
                                    <p>
                                    La lead generation si configura come un elemento essenziale per la crescita aziendale, fungendo da catalizzatore che favorisce relazioni significative e posiziona le imprese in modo strategico nel mercato.
                                    </p>  
                                    <p>La capacità di identificare e catturare opportunità di business è al centro della lead generation. Questo processo consente alle aziende di essere proattive nel raggiungere potenziali clienti interessati, mirando a individuare coloro che sono più propensi a diventare clienti effettivi.</p>
                                    <p>Concentrandosi sulla qualità delle lead anziché sulla quantità, la lead generation ottimizza l'uso delle risorse di vendita. Questo approccio massimizza l'efficacia delle attività di vendita e riduce gli sprechi di tempo e risorse su lead poco qualificate.</p>
                                    <p>Uno degli aspetti distintivi della lead generation è la sua capacità di favorire la costruzione di relazioni durature. Attraverso approcci personalizzati e comunicazione mirata, le aziende possono instaurare un dialogo significativo con le lead, creando un legame di fiducia che va oltre la semplice transazione commerciale.</p>
                                    <p>Nell'era digitale, piattaforme come LinkedIn e strumenti avanzati come il Sales Navigator svolgono un ruolo cruciale nella lead generation. Consentono alle aziende di connettersi in modo mirato con professionisti interessati e di navigare attraverso un vasto mare di opportunità, trasformando la lead generation in una danza strategica sul palcoscenico digitale.</p>
                                    <p>La lead generation non si ferma alla generazione iniziale; include anche la misurazione delle performance e l'ottimizzazione continua. Attraverso l'analisi delle metriche di conversione e l'adattamento alle dinamiche del mercato, le aziende possono perfezionare le proprie strategie di lead generation nel tempo, garantendo una crescita costante.</p>
                                    <p>In definitiva, la lead generation rappresenta un elemento essenziale per il successo aziendale, guidando la crescita e creando una base solida per relazioni commerciali durature e proficue. Investire in una strategia di lead generation ben progettata non solo alimenta la crescita aziendale, ma consolida anche il terreno per un successo a lungo termine.</p>
                                </div> 
                                <div style={{ marginTop: 30 }} className="startp-post-navigation"> 
                                {
                                    Blog().filter(a => a.id !== articleData.id).slice(0, 2).map(article => {
                                        return <div className="prev-link-wrapper">
                                        <div className="info-prev-link-wrapper">
                                            <Link to={"/" + article.page}>
                                                <span className="image-prev">
                                                    <img className="w-100" src={require(`../../images${article.img}`)} alt="imge" />
                                                    <span className="post-nav-title">VAI</span>
                                                </span>
            
                                                <span className="prev-link-info-wrapper">
                                                    <span className="prev-title">{article.title}</span>
                                                    <span className="meta-wrapper">
                                                        <span className="date-post">{article.data}</span>
                                                    </span>
                                                </span>
                                            </Link>
                                        </div>
                                    </div>
                                    })
                                }
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-12">
                            <BlogSidebar  id={articleData.id}/>
                        </div>
                    </div>
                </div>
            </div> 
        </>
    )
}
export default LeadGeneration;