/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import img from "../images/h&bdark.png";
import phone from "../images/phone.png";
import world from "../images/online.png";

const Footer = (props) => {

    const [mainUrl, setMainUrl] = React.useState("");

    React.useEffect(() => {
        const url = window.location.href.split("/")[2]; 
        setMainUrl(url); 
    }, []);

    const handleComeFunzionaClick = () => {
        if (window.location.href === `http://${mainUrl}/`) {
            props.comeFunzionaRef.current.scrollIntoView({ behavior: "smooth" });
        } else {
            window.location.href = `http://${mainUrl}/?ref=come-funziona`; 
        } 
    }; 
    const handleBannerClick = () => {
        if (window.location.href === `http://${mainUrl}/`) {
            props.bannerRef.current.scrollIntoView({ behavior: "smooth" });
        } else {
            window.location.href = `http://${mainUrl}/`; 
        } 
    };

    const d = new Date();
    let year = d.getFullYear(); 

    return ( 
        <div className="footer overflow-hidden" >
            <div>
                <div className="row align-items-end h-100">
                    <div className="col-sm-12 col-md-4 col-xl-4 d-flex align-items-end">
                        <img src={img} className="footerLogo" alt="" />
                        <div className="barra"></div>
                        <div className="">
                            <div className="d-flex flex-column rightFooter">
                                <div className="d-flex align-items-end">
                                    <img src={phone} alt="" style={{marginLeft:3, width:10, height:17}}/>
                                    <span>+ 39 0536 1856617</span>
                                </div>
                                <div className="d-flex align-items-end mt-2">
                                    <img src={world} alt="" style={{width:17, height:17}} />
                                    <a style={{marginLeft:4, textDecoration: "none", color: "black"}} href="/">www.honeyandbees.cloud</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-8 col-xl-8">
                        <div className="endFooter">
                            <div className="row">
                                <div className="col-4">
                                    <div className="d-flex flex-column">
                                        <span className="titleFooter text-color">Honey & Bees</span>
                                        <ul>
                                            <li>
                                                <a className="HeeboRegular" onClick={handleBannerClick}>Homepage</a>
                                            </li>
                                            <li>
                                                <a className="HeeboRegular" onClick={handleComeFunzionaClick}>Come funziona</a>
                                            </li>
                                            <li>
                                                <a className="HeeboRegular" href="/blog">Blog</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-4">
                                    <div className="d-flex flex-column">
                                        <span className="titleFooter text-color">Servizi</span>
                                        <ul>
                                            <li>
                                                <a rel="noopener noreferrer" className="HeeboRegular" target="_blank" href="https://www.iubenda.com/privacy-policy/15534763/cookie-policy" >Cookie policy</a>
                                            </li>
                                            <li>
                                                <a rel="noopener noreferrer" className="HeeboRegular" target="_blank" href="https://www.iubenda.com/privacy-policy/15534763">Privacy policy</a>
                                            </li>
                                            <li>
                                                <a className="HeeboRegular" target="_blank" href="/condizioni-di-servizio">Condizioni di servizio</a>
                                            </li> 
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-4">
                                    <div className="d-flex flex-column">
                                        <span className="titleFooter text-color">Azioni</span>
                                        <ul>
                                            <li>
                                                <a className="HeeboRegular" href="/login">Login</a>
                                            </li>
                                            <li>
                                                <a className="HeeboRegular" href="/register">Registrati</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>      
                    </div>
                    <span className="text-center">
                        © {year} HBMS srls - Via Isonzo 52, 41042 Fiorano Modenese(MO) P.IVA 04131930366
                    </span>
                </div>
            </div>
        </div>
    ) 
}
export default Footer;