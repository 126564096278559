import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom"; // Aggiunto import per BrowserRouter
import { ThemeProvider } from "@material-ui/styles";
import { CssBaseline } from "@material-ui/core";
import "bootstrap/dist/css/bootstrap.min.css";
import Themes from "./themes";
import App from "./components/App";
import * as serviceWorker from "./serviceWorker";
import { LayoutProvider } from "./context/LayoutContext";
import { UserProvider } from "./context/UserContext";
import { NotifyProvider } from "./context/NotifyContext";

ReactDOM.render(
  <Router>
    <NotifyProvider>
      <LayoutProvider>
        <UserProvider>
          <ThemeProvider theme={Themes.default}>
            <CssBaseline />
            <App />
          </ThemeProvider>
        </UserProvider>
      </LayoutProvider>
    </NotifyProvider>
  </Router>,
  document.getElementById("root")
);

serviceWorker.unregister();
