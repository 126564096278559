import React, { useContext } from "react";
import ReactLoading from "react-loading";
import ContattiClientiTable from "../components/admin/ContattiClientiTable";
import Cookies from "universal-cookie";

import getTipologiaRicerca from "../services/getTipologiaRicerca";
import HeBLayout from "../components/HeBLayout";
import NotifyContext from "../context/NotifyContext";

const cookies = new Cookies();
let username = cookies.get("username", { path: "/" });
let token = cookies.get("token", { path: "/" });
let type = cookies.get("tipo", { path: "/" });

const Contatti = () => {
	const { selectedUser } = useContext(NotifyContext);
	const [loading, setLoading] = React.useState(true);
	const [tipologieRicerca, setTipologieRicerca] = React.useState([]);
	const [tipologiaRicerca, setTipologiaRicerca] = React.useState({
		id: 0,
		tag: "",
	});

	const getAndSetTipologieRicerca = async (username, token) => {
		try {
			const response = await getTipologiaRicerca(username, token);
			if (response.result === "ok") {
				setTipologieRicerca(response.data);
			}
		} catch (error) {
			console.error("Errore durante il recupero delle tipologie di ricerca:", error);
		}
	};

	React.useEffect(() => {
		getAndSetTipologieRicerca(username, token);
		setLoading(false);
	}, []);

	React.useEffect(() => {
		if (tipologieRicerca.length > 0) {
			const firstTagId = tipologieRicerca[0].id;
			const firstTag = tipologieRicerca[0].tag;
			setTipologiaRicerca({
				id: firstTagId,
				tag: firstTag,
			});
		}
	}, [tipologieRicerca]);

	if (loading) {
		return (
			<HeBLayout>
				<div className="d-flex justify-content-center vh-100">
					<ReactLoading
						type={"bubbles"}
						color={"orange"}
						height={"10%"}
						width={"10%"}
						className="align-self-center "
					/>
				</div>
			</HeBLayout>
		);
	} else {
		return (
			<HeBLayout>
				<ContattiClientiTable tipologiaRicerca={tipologiaRicerca} selectedUser={selectedUser} />
			</HeBLayout>
		);
	}
};

export default Contatti;
