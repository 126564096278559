import React, { useContext } from "react";
import ListTable from "../components/admin/ListTable";
import HeBLayout from "../components/HeBLayout";
import NotifyContext from "../context/NotifyContext";

const List = () => {
	const { selectedUser } = useContext(NotifyContext);
	return (
		<HeBLayout>
			<div
				style={{
					width: "96%",
					marginInline: "auto",
					marginTop: "20px",
					marginBottom: "20px",
					maxHeight: "90%",
				}}
			>
				<ListTable selectedUser={selectedUser} />
			</div>
		</HeBLayout>
	);
};

export default List;
