import Constants from "../utils/consts";

var http = Constants.http;
var qs = require("querystring");

export default async function addUtente(
	uname,
	token,
	nome,
	cognome,
	telefono,
	ragione_sociale,
	nome_azienda,
	p_iva,
	username,
	email,
	password,
	indirizzo,
	cap,
	city,
	provincia,
	codice_univoco,
	email_calendly,
	token_calendly,
	link_calendly,
	link_info_page,
	balance,
	parole_da_escludere,
	linkedin_account,
	codice_fiscale,
	iban
) {
	return new Promise(function(resolve, reject) {
		var options = {
			method: "POST",
			hostname: Constants.ipAddress,
			path: Constants.apisFolder + "add/user/",
			headers: {
				"Content-Type": "application/x-www-form-urlencoded",
				Cookie: "uid=e02675a1a7516909792f6850dffcd5fe",
			},
			maxRedirects: 20,
		};

		var req = http.request(options, function(res) {
			var chunks = [];

			res.on("data", function(chunk) {
				chunks.push(chunk);
			});

			res.on("end", function(chunk) {
				var body = Buffer.concat(chunks);
				const resp = JSON.parse(body.toString());
				resolve(resp);
			});

			res.on("error", function(error) {
				console.error(error);
			});
		});

		var postData = qs.stringify({
			uname: uname,
			token: token,
			nome: nome,
			cognome: cognome,
			telefono: telefono,
			ragione_sociale: ragione_sociale,
			nome_azienda: nome_azienda,
			p_iva: p_iva,
			username: username,
			email: email,
			password: password,
			email_token: null,
			email_calendly: email_calendly,
			token_calendly: token_calendly,
			link_calendly: link_calendly,
			link_info_page: link_info_page,
			indirizzo: indirizzo,
			cap: cap,
			city: city,
			provincia: provincia,
			codice_univoco: codice_univoco,
			balance: balance,
			parole_da_escludere: parole_da_escludere,
			linkedin_account: linkedin_account,
			codice_fiscale: codice_fiscale,
			iban: iban,
		});

		req.write(postData);

		req.end();
	});
}
