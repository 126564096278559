import React from "react";
import Navbar from "../components/Navbar";
import Banner from "../components/_homepage/Banner"; 
import ReactLoading from 'react-loading';
import './style.css';
import HomeDescription from "../components/_homepage/HomeDescription";
import Cookies from "universal-cookie"; 
import Footer from "../components/Footer";
import ComeFunziona from "../components/_homepage/ComeFunziona";
import Prezzi from "../components/_homepage/Prezzi";
import getPacchettiChiamate from "../services/getPacchettiChiamate";
import logo from "../images/Honey&bees3.png"
import Form from "../components/_homepage/Form";
import VideoHomepage from "../components/_homepage/VideoHomepage";
import { Button } from "react-bootstrap";


const cookies = new Cookies();
const username = cookies.get('username', { path: "/" });
const token = cookies.get('token', { path: "/" });

const workInProgress = true;

export default class Homepage extends React.Component {

    constructor(props) {
        super(props)
        this.homeDescriptionRef = React.createRef();
        this.comeFunzionaRef = React.createRef();
        this.contattaciRef = React.createRef();
        this.calendlyRef = React.createRef();
        this.bannerRef = React.createRef();
        this.prezziRef = React.createRef();

        this.state = {
            currentSection: 0,
            loading: true,
            mobile: false,
            scroll: 0,
            pacchetti: [],
        }
    }


    async reload (){
        getPacchettiChiamate().then(res => {
            this.setState({ pacchetti: res })
        });
    }

    async componentDidMount() { 
        this.reload();
        window.scrollTo(0, 0);
        this.resize();
        window.addEventListener("resize", this.resize.bind(this));
        this.setState({ loading: false, username, token });
        setTimeout(() => { 
            const ref = window.location.href.includes("?ref=") ? window.location.href.split("?ref=")[1] : "";
            if(ref) { 
                switch(ref) {
                    case "contattaci":  
                        this.contattaciRef.current.scrollIntoView({ behavior: 'smooth' });
                        break;
                    case "come-funziona": 
                        this.comeFunzionaRef.current.scrollIntoView({ behavior: 'smooth' });
                        break;
                    case "calendly": 
                        this.calendlyRef.current.scrollIntoView({ behavior: 'smooth' });
                        break; 
                    case "prezzi": 
                        this.prezziRef.current.scrollIntoView({ behavior: 'smooth' });
                        break;
                    default:
                }
            }
        }, 1000);
    }
    componentWillUnmount() {
        window.removeEventListener("resize", this.resize.bind(this));
    }

    resize() {
        if (window.innerWidth < 768 && !this.state.mobile) {
            this.setState({ mobile: true });
        } else if (this.state.mobile) {
            this.setState({ mobile: false })
        }
    }

    render() {
        if(workInProgress) {
            return (
                <div className="d-flex justify-content-center vh-100  bg-dark">
                    <div className="align-self-center text-center text-white">
                        <img src={logo} alt="work in progress" className="img-fluid mb-5" />
                        <h1>Sito in manutenzione</h1>
                        <h3>Stiamo lavorando per migliorare il nostro servizio</h3>
                        <i>Honey & Bees</i>
                    </div>
                </div>
            )
        }

        if (this.state.loading) return (
            <div className="d-flex justify-content-center vh-100">
                <ReactLoading type={'bubbles'} color={'orange'} height={'10%'} width={'10%'} className="align-self-center " />
            </div>
        )

        else return (
            <div className="myHome" onScroll={(e) => console.log(e.nativeEvent)} ref={this.container}>

                <Navbar prezziRef={this.prezziRef} bannerRef={this.bannerRef} comeFunzionaRef={this.comeFunzionaRef} contattaciRef={this.contattaciRef} calendlyRef={this.calendlyRef} />

                <Banner bannerRef={this.bannerRef} />

                <HomeDescription />

                <VideoHomepage /> 

                <div className="py-4 text-center" style={{backgroundColor:"#ffffffff"}}>
                    <Button className="btn btn-pricing mt-4" onClick={() => window.location.href='/register'} > Registrati Subito</Button>
                </div>
                <ComeFunziona comeFunzionaRef={this.comeFunzionaRef} />

                {
                    this.state.pacchetti.length === 3 && (
                        <Prezzi prezziRef={this.prezziRef} pacchetti={this.state.pacchetti} />
                    ) 
                } 
                <Form contattaciRef={this.contattaciRef} />
                <Footer bannerRef={this.bannerRef} comeFunzionaRef={this.comeFunzionaRef} testimonianzeRef={this.testimonianzeRef} calendlyRef={this.calendlyRef} />
            </div>
        )
    } 

}

