import React from 'react'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'

export const CondizioniServizio = () => {

    return (
    <>
        <Navbar />
        <div style={{width: "80%", margin:"150px auto"}}>
            <h1>Condizioni generali di servizio e di utilizzo</h1>
            <ol>
                <li>
                    <h3>Accettazione delle Condizioni</h3>
                    <ol>
                        <li> 
                            <h5>Accordo Vincolante</h5>
                            <p>Queste Condizioni generali di Servizio e di Utilizzo ("Condizioni") costituiscono un accordo legalmente vincolante tra l'utente (sia esso un individuo o un'entità) e Honey&Bees. Utilizzando o accedendo a qualsiasi parte del servizio Honey&Bees, l'utente accetta di essere vincolato da queste Condizioni. Se l'utente non accetta queste Condizioni, non è autorizzato ad utilizzare il servizio.</p>
                        </li>
                        <li>
                            <h5>Modifiche alle Condizioni</h5>
                            <p>Honey&Bees si riserva il diritto di modificare o rivedere queste Condizioni in qualsiasi momento. Qualsiasi modifica entrerà in vigore immediatamente dopo la pubblicazione sul sito web di Honey&Bees o dopo la notifica agli utenti. L'uso continuato del servizio da parte degli utenti dopo tali modifiche costituisce accettazione delle nuove Condizioni.</p>
                        </li>
                        <li>
                            <h5>Capacità di Accettare le Condizioni</h5>
                            <p>L'utilizzo del servizio Honey&Bees è inteso solo per soggetti che hanno la capacità legale di stipulare contratti vincolanti. Con l'accettazione di queste Condizioni, l'utente dichiara di avere la capacità di essere vincolato da un contratto.</p>
                        </li>
                        <li>
                            <h5>Utilizzo da Parte di Minori</h5>
                            <p>Il servizio Honey&Bees non è destinato all'uso da parte di minori. Se l'utente è un minore, non è autorizzato ad utilizzare il servizio.</p>
                        </li>
                        <li>
                            <h5>Accettazione delle Politiche Aggiuntive</h5>
                            <p>Utilizzando Honey&Bees, l'utente accetta anche di essere vincolato da altre politiche di Honey&Bees, come la Policy sulla Privacy e qualsiasi altra Policy che potrebbe essere introdotta e comunicata agli utenti.</p>
                        </li>
                    </ol>
                </li>
                <li>
                    <h3>Descrizione del Servizio</h3>
                    <p>Honey&Bees offre servizi avanzati per la crescita aziendale, per l'analisi del business dei suoi clienti, per l'identificazione dei loro clienti ideali e per la definizione di strategie di marketing mirate. Il nostro servizio si distingue per l'utilizzo di LinkedIn come piattaforma principale per la generazione di leads.</p>
                    <p>Attraverso Honey&Bees, gli utenti possono:</p>
                    <ul>
                        <li>
                            <p><b>Analizzare il proprio business</b>: Identificare le caratteristiche distintive del proprio modello di business, comprese le specificità del settore, la dimensione dell'azienda, la localizzazione geografica, e altri fattori critici.</p>
                        </li>
                        <li>
                            <p><b>Creare Buyer Personas</b>: Identificare le caratteristiche distintive del proprio modello di business, comprese le specificità del settore, la dimensione dell'azienda, la localizzazione geografica, e altri fattori critici.</p>
                        </li>
                        <li>
                            <p><b>Generare Liste di Prospect</b>: Utilizzare gli account LinkedIn degli utenti per identificare e compilare elenchi di decision makers e influencer rilevanti che potrebbero essere interessati ai prodotti o servizi offerti dall'utente.</p>
                        </li>
                        <li>
                            <p><b>Sviluppare Strategie di Approccio Personalizzate</b>: Scegliere tra approcci formali o casuali, diretti o cautelativi, per ottimizzare l'interazione con i potenziali clienti.</p>
                        </li>
                        <li>
                            <p><b>Contattare i Prospect per conto degli Utenti</b>: Applicare le strategie di approccio personalizzate per contattare e interagire con i prospect attraverso LinkedIn, facendo avanzare il processo di vendita.</p>
                        </li>
                        <li>
                            <p><b>Organizzare Call e Incontri</b>: I prospect che mostrano interesse vengono invitati a fissare call o incontri con gli utenti, facilitando lo sviluppo di relazioni commerciali dirette.</p>
                        </li>
                    </ul>
                    <p>Il nostro software si integra strettamente con LinkedIn, sfruttando questa piattaforma per massimizzare l'efficacia della generazione di leads. È importante notare che, nonostante il nostro software interagisca con LinkedIn, gli account LinkedIn rimangono di proprietà degli utenti, i quali hanno il pieno controllo e possono interrompere l'uso della nostra piattaforma in qualsiasi momento.</p>
                </li>
                <li>
                    <h3>Accesso e Utilizzo del Servizio</h3>
                    <ol>
                        <li>
                            <h5>Eleggibilità e Registrazione</h5>
                            <p>Il servizio offerto da Honey&Bees è esclusivamente destinato a professionisti e aziende che desiderano espandere il proprio business. Per accedere al servizio, gli utenti devono completare un processo di registrazione, fornendo informazioni accurate e aggiornate. È responsabilità dell'utente mantenere la riservatezza delle proprie credenziali di accesso e garantire che non vengano utilizzate impropriamente.</p>
                        </li>
                        <li>
                            <h5>Utilizzo Conforme alle Leggi</h5>
                            <p>L'utilizzo del servizio deve essere conforme a tutte le leggi e regolamentazioni applicabili. E’ proibito per gli utenti l’utilizzo di Honey&Bees per qualsiasi scopo illecito o per promuovere attività illegali, inclusa, ma non limitata a, la violazione di diritti di proprietà intellettuale, frode, diffamazione o spam.</p>
                        </li>
                        <li>
                            <h5>Responsabilità dell'Utente</h5>
                            <p>Gli utenti sono responsabili di ogni azione intrapresa attraverso i loro account. Questo include, ma non è limitato a, le interazioni tramite LinkedIn e la gestione dei leads generati. Honey&Bees non è responsabile per le azioni degli utenti o per eventuali violazioni delle condizioni d'uso di LinkedIn.</p>
                        </li>
                        <li>
                            <h5>Sicurezza degli accounts</h5>
                            <p>Gli utenti devono prendere misure appropriate per proteggere la sicurezza e l'integrità dei propri account, inclusa la scelta di password forti e la modifica periodica delle stesse. In caso di sospetto uso non autorizzato o violazione della sicurezza, gli utenti devono informare immediatamente Honey&Bees.</p>
                        </li>
                        <li>
                            <h5>Limitazioni all'Accesso</h5>
                            <p>Honey&Bees si riserva il diritto di limitare, sospendere o terminare l'accesso di un utente al servizio in caso di violazioni di queste condizioni o per qualsiasi altro comportamento che Honey&Bees ritenga inappropriato o dannoso per il servizio o per altri utenti.</p>
                        </li>
                    </ol>
                </li>
                <li>
                    <h3>Proprietà Intellettuale</h3>
                    <ol>
                        <li>
                            <h5>Proprietà del Servizio Honey&Bees</h5>
                            <p>Tutto il software, le tecnologie, i marchi, i testi, le grafiche, le interfacce utente e qualsiasi altro contenuto fornito da Honey&Bees sono di proprietà esclusiva di APPIUS SRLS  o dei suoi licenzianti. Questi elementi sono protetti dalle leggi sulla proprietà intellettuale e altri diritti di proprietà. L'accesso al servizio Honey&Bees non conferisce agli utenti alcun diritto o licenza, implicita o esplicita, sui suddetti elementi, ad eccezione di un uso limitato come espressamente autorizzato in queste Condizioni di Servizio.</p>
                        </li>
                        <li>
                            <h5>Uso dei Marchi e dei Loghi</h5>
                            <p>I marchi, i loghi e i nomi di servizio utilizzati e visualizzati su Honey&Bees sono marchi registrati o non registrati di Honey&Bees o di terze parti. Gli utenti non sono autorizzati a usare questi marchi senza il previo consenso scritto di Honey&Bees o del terzo titolare dei marchi.</p>
                        </li>
                        <li>
                            <h5>Proprietà dei Dati Generati</h5>
                            <p>Mentre Honey&Bees assiste gli utenti nella generazione di leads tramite LinkedIn, i dati raccolti e utilizzati per questo scopo rimangono di proprietà dell'utente. Honey&Bees non rivendica la proprietà dei dati generati o raccolti dagli utenti durante l'utilizzo del servizio. Tuttavia, l'utilizzo di questi dati deve essere conforme alle leggi applicabili, inclusa la normativa sulla privacy e la protezione dei dati.</p>
                        </li>
                        <li>
                            <h5>Limitazioni all'Uso dei Contenuti</h5>
                            <p>Gli utenti non possono riprodurre, distribuire, modificare, creare opere derivate, esporre pubblicamente, eseguire pubblicamente, ripubblicare, scaricare, memorizzare o trasmettere alcun materiale di Honey&Bees, eccetto quanto espressamente permesso da queste Condizioni di Servizio o come autorizzato per iscritto da Honey&Bees.</p>
                        </li>
                        <li>
                            <h5>Feedback e Suggerimenti</h5>
                            <p>Qualsiasi feedback, commento, idea, miglioramento o suggerimento (collettivamente, "Feedback") fornito dagli utenti a Honey&Bees riguardo al servizio sarà di proprietà esclusiva di Honey&Bees. Honey&Bees sarà libera di utilizzare, copiare, modificare, pubblicare o distribuire il Feedback per qualsiasi scopo, senza compensazione o obbligo nei confronti dell'utente.</p>
                        </li>
                    </ol>
                </li>
                <li>
                    <h3>Limitazioni di Uso</h3>
                    <ol>
                        <li>
                            <h5>Uso Conforme alle Leggi</h5>
                            <p>Gli utenti di Honey&Bees si impegnano ad utilizzare il servizio in modo conforme a tutte le leggi, regolamenti e ordinanze locali, statali, nazionali e internazionali applicabili. Ciò include, ma non è limitato a, le leggi relative alla privacy, alla protezione dei dati, alla proprietà intellettuale, all'antispam e alla concorrenza.</p>
                        </li>
                        <li>
                            <h5>Divieto di Uso Illecito</h5>
                            <p>È espressamente vietato utilizzare Honey&Bees per scopi illeciti o per promuovere attività illegali. Questo include, senza limitazioni, l'uso del servizio per diffondere contenuti diffamatori, ingannevoli, offensivi o in violazione di diritti di proprietà intellettuale di terzi.</p>
                        </li>
                        <li>
                            <h5>Limitazioni nell'Uso di LinkedIn</h5>
                            <p>Considerando che Honey&Bees sfrutta LinkedIn per la generazione di leads, gli utenti devono rispettare le condizioni d'uso, le politiche sulla privacy e qualsiasi altra regolamentazione imposta da LinkedIn. Honey&Bees non è responsabile per violazioni delle politiche di LinkedIn commesse dagli utenti.</p>
                        </li>
                        <li>
                            <h5>Proibizione di Attività Dannose</h5>
                            <p>Gli utenti non devono intraprendere attività che potrebbero danneggiare, sovraccaricare o compromettere il funzionamento di Honey&Bees o la sicurezza degli altri utenti. Ciò include tentativi di accesso non autorizzato al servizio o ai sistemi correlati, la distribuzione di virus o malware, o qualsiasi altro comportamento potenzialmente dannoso.</p>
                        </li>
                        <li>
                            <h5>Utilizzo Responsabile delle Risorse</h5>
                            <p>Honey&Bees richiede agli utenti di utilizzare le risorse della piattaforma, compresi i dati e le funzionalità di contatto, in modo responsabile e rispettoso. Gli abusi, come l'invio di spam o la realizzazione di campagne di marketing aggressive o ingannevoli, sono severamente proibiti.</p>
                        </li>
                    </ol>
                </li>
                <li>
                    <h5>Pagamenti e Rimborsi</h5>
                    <ol>
                        <li>
                            <h5>Modalità di Pagamento</h5>
                            <p>I pagamenti per l'utilizzo dei servizi Honey&Bees vengono gestiti tramite Stripe, ritenuta una piattaforma di pagamento online sicura e affidabile. Gli utenti possono acquistare pacchetti di chiamate secondo le tariffe indicate sul sito web o nelle comunicazioni di Honey&Bees. Tutti i pagamenti devono essere effettuati in anticipo e in conformità con le istruzioni fornite durante il processo di acquisto.</p>
                        </li>
                        <li>
                            <h5>Acquisto di Pacchetti di Chiamate</h5>
                            <p>I pacchetti di chiamate offerti da Honey&Bees consentono agli utenti di accedere a un numero predeterminato di chiamate o sessioni di interazione con i prospect. Ogni pacchetto ha specifiche condizioni d'uso, che potrebbero includere la durata di validità e il numero massimo di chiamate disponibili.</p>
                        </li>
                        <li>
                            <h5>Politica di Rimborso</h5>
                            <p>Gli utenti hanno diritto a richiedere il rimborso del pacchetto acquistato solo prima dell'inizio del suo utilizzo. Una volta iniziato l'uso del pacchetto di chiamate, non è più possibile ottenere rimborsi. Le richieste di rimborso devono essere inviate a Honey&Bees tramite i canali di comunicazione ufficiali e saranno soggette a verifica e approvazione da parte di Honey&Bees.</p>
                        </li>
                        <li>
                            <h5>Modifiche ai Prezzi e ai Pacchetti</h5>
                            <p>Honey&Bees si riserva il diritto di modificare i prezzi e i dettagli dei pacchetti di chiamate. Tali modifiche verranno comunicate agli utenti tramite il sito web o attraverso notifiche dirette. Le modifiche non influenzeranno i pacchetti già acquistati prima dell'annuncio delle modifiche.</p>
                        </li>
                        <li>
                            <h5>Mancato Utilizzo dei Servizi</h5>
                            <p>Nel caso in cui un pacchetto di chiamate acquistato non venga utilizzato entro la scadenza indicata, non saranno previsti rimborsi o crediti per l'utilizzo futuro. È responsabilità dell'utente utilizzare i servizi acquistati entro i termini previsti.</p>
                        </li>
                    </ol>
                </li>
                <li>
                    <h3>Modifiche al Servizio e al Contratto</h3>
                    <ol>
                        <li>
                            <h5>Aggiornamenti e Modifiche al Servizio</h5>
                            <p>Honey&Bees si impegna a migliorare continuamente e aggiornare i suoi servizi per soddisfare le esigenze degli utenti. Di conseguenza, Honey&Bees si riserva il diritto di modificare, aggiornare o interrompere qualsiasi aspetto del servizio, inclusi, ma non limitati a, funzionalità, interfaccia utente, e disponibilità di specifiche opzioni, in qualsiasi momento e senza preavviso.</p>
                        </li>
                        <li>
                            <h5>Notifica delle Modifiche</h5>
                            <p>Qualora Honey&Bees apporti modifiche significative ai servizi, gli utenti verranno informati tramite comunicazioni via email o tramite annunci sul sito web di Honey&Bees. È responsabilità degli utenti controllare regolarmente queste comunicazioni per essere aggiornati su eventuali cambiamenti.</p>
                        </li>
                        <li>
                            <h5>Effetti delle Modifiche sulle Condizioni di Servizio</h5>
                            <p>Le modifiche ai servizi possono richiedere aggiornamenti alle presenti Condizioni di Servizio. In caso di cambiamenti significativi a queste condizioni, Honey&Bees fornirà un preavviso ragionevole e la possibilità per gli utenti di rivedere e accettare i nuovi termini. L'uso continuato del servizio dopo tali modifiche implica l'accettazione dei termini aggiornati.</p>
                        </li>
                        <li>
                            <h5>Diritto di Recesso</h5>
                            <p>In caso di modifiche unilaterali significative ai servizi da parte di Honey&Bees che non soddisfano l'utente, questi ha il diritto di recedere dal contratto sottoscritto. In tale eventualità, l'utente può richiedere un rimborso per i servizi non ancora utilizzati, soggetto alle condizioni specificate nella sezione 6.3 relativa ai rimborsi.</p>
                        </li>
                        <li>
                            <h5>Responsabilità per Modifiche</h5>
                            <p>Honey&Bees non è responsabile per eventuali perdite o danni derivanti da modifiche al servizio, salvo quanto diversamente specificato in altre sezioni di queste Condizioni di Servizio.</p>
                        </li>
                    </ol>
                    <p>Ecco l'espansione del punto 8, che riguarda la terminazione del servizio da parte di Honey&Bees e degli utenti:</p>
                </li>
                <li>
                    <h3>Terminazione del Servizio</h3>
                    <ol>
                        <li>
                            <h5>Terminazione da Parte dell'Utente</h5>
                            <p>Gli utenti di Honey&Bees hanno il diritto di terminare l'utilizzo del servizio in qualsiasi momento. Per farlo, è necessario notificare Honey&Bees attraverso i canali di comunicazione ufficiali. La cessazione diventerà effettiva immediatamente dopo la ricezione della notifica da parte di Honey&Bees. Eventuali pacchetti di chiamate parzialmente utilizzati non saranno soggetti a rimborso, come specificato nella sezione 6.3 delle presenti Condizioni di Servizio.</p>
                        </li>
                        <li>
                            <h5>Terminazione da Parte di Honey&Bees</h5>
                            <p>Honey&Bees si riserva il diritto di terminare o sospenderere l'accesso al servizio per un utente in qualsiasi momento e senza preavviso, in caso di violazione di queste Condizioni di Servizio o per qualsiasi altro motivo ritenuto valido da Honey&Bees, inclusi comportamenti che possano danneggiare Honey&Bees o altri utenti del servizio.</p>
                        </li>
                        <li>
                            <h5>Conseguenze della Terminazione</h5>
                            <p>Alla terminazione del servizio, sia da parte dell'utente che di Honey&Bees, l'utente perderà l'accesso a tutte le funzionalità del servizio, inclusi i dati e le informazioni associate al proprio account. Honey&Bees non è responsabile per la perdita di tali informazioni a seguito della terminazione.</p>
                        </li>
                        <li>
                            <h5>Salvataggio dei Dati dopo la Terminazione</h5>
                            <p>Gli utenti sono incoraggiati a salvare o esportare i dati rilevanti dal loro account prima di richiedere la terminazione del servizio. Honey&Bees non è obbligata a mantenere o fornire una copia dei dati o delle informazioni post-terminazione, a meno che non sia richiesto dalla legge applicabile.</p>
                        </li>
                        <li>
                            <h5>Effetti Legalmente Vincolanti</h5>
                            <p>La terminazione del servizio non influisce sui diritti e obbligazioni legalmente vincolanti che sono sopravvissuti alla terminazione, inclusi, ma non limitati a, le disposizioni sulla proprietà intellettuale, le limitazioni di responsabilità e le disposizioni di risoluzione delle dispute.</p>
                        </li>
                    </ol>
                </li>
                <li>
                    <h3>Dispute e Legge Applicabile</h3>
                    <ol>
                        <li>
                            <h5>Risoluzione delle Controversie</h5>
                            <p>Honey&Bees si impegna a fornire un servizio di alta qualità e desidera assicurarsi che eventuali problemi siano risolti in modo rapido ed equo. Se dovessero sorgere controversie relative all'uso del servizio, Honey&Bees incoraggia gli utenti a contattare il servizio clienti per esplorare soluzioni amichevoli.</p>
                        </li>
                        <li>
                            <h5>Procedura di Reclamo</h5>
                            <p>In caso di reclamo, gli utenti sono invitati a presentare il loro caso dettagliato al servizio clienti di Honey&Bees. Honey&Bees esaminerà il reclamo e farà ogni sforzo ragionevole per rispondere e risolvere la questione entro un tempo ragionevole.</p>
                        </li>
                        <li>
                            <h5>Mediazione</h5>
                            <p>Prima di intraprendere qualsiasi azione legale, Honey&Bees e l'utente si impegnano a tentare di risolvere la disputa attraverso la negoziazione informale o la mediazione, coinvolgendo un mediatore neutrale, se necessario.</p>
                        </li>
                        <li>
                            <h5>Giurisdizione e Legge Applicabile</h5>
                            <p>Qualsiasi controversia che non possa essere risolta amichevolmente sarà soggetta alla giurisdizione esclusiva del tribunale di Modena, Italia. Le presenti Condizioni di Servizio sono regolate e interpretate secondo le leggi italiane, senza dare effetto a qualsiasi scelta o conflitto di principi legali che potrebbero richiedere l'applicazione delle leggi di un'altra giurisdizione.</p>
                        </li>
                        <li>
                            <h5>Limitazioni alle Azioni Legali</h5>
                            <p>Qualsiasi causa di azione o reclamo che l'utente possa avere con riferimento al servizio deve essere iniziato entro un anno dopo che la causa dell'azione sorge, altrimenti tale causa di azione o reclamo è permanentemente precluso.</p>
                        </li>
                        <li>
                            <h5>Accordi sul foro di competenza</h5>
                            <p>Gli utenti accettano che qualsiasi disputa che richieda l'intervento di un tribunale sarà condotta esclusivamente del Tribunale di Modena, Italia. Inoltre, gli utenti rinunciano a qualsiasi diritto di contestare la giurisdizione o la sede di tale tribunale.</p>
                        </li>
                        <li>
                            <h5>Equità Legale</h5>
                            <p>Niente nelle presenti Condizioni di Servizio preclude ad Honey&Bees la possibilità di cercare ingiunzioni o altri rimedi per la violazione delle presenti Condizioni di Servizio, in particolare in relazione alla violazione dei diritti di proprietà intellettuale.</p>
                        </li>
                    </ol>
                </li>
                <li>
                    <h3>Limitazioni di Responsabilità</h3>
                    <ol>
                        <li>
                            <h5>Responsabilità Generale</h5>
                            <p>Honey&Bees non sarà in alcun modo responsabile per danni diretti, indiretti, incidentali, speciali, consequenziali o punitivi, inclusi, ma non limitati a, perdite di profitti, dati o altre perdite intangibili, derivanti dall'uso o dall'impossibilità di utilizzare il servizio, anche se Honey&Bees è stata avvisata della possibilità di tali danni.</p>
                        </li>
                        <li>
                            <h5>Limitazioni Specifiche</h5>
                            <ul>
                                <li>
                                    <p><b>Perdita di Dati</b>: Honey&Bees non è responsabile per la perdita o il danneggiamento dei dati degli utenti a causa di fattori al di fuori del controllo diretto di Honey&Bees.</p>
                                </li>
                                <li>
                                    <p><b>Malfunzionamenti del Software</b>: Mentre Honey&Bees si impegna a mantenere il suo software funzionante in modo ottimale, non garantisce che il servizio sarà sempre privo di errori o interruzioni.</p>
                                </li>
                                <li>
                                    <p><b>Abuso del Servizio</b>: Honey&Bees non è responsabile per qualsiasi perdita o danno derivante dall'uso improprio del servizio da parte degli utenti o da violazioni delle presenti Condizioni di Servizio.</p>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <h5>Misure di Sicurezza</h5>
                            <p>Honey&Bees adotta misure ragionevoli per proteggere la sicurezza e l'integrità del servizio, ma non può garantire assoluta sicurezza contro tutte le possibili violazioni o attacchi.</p>
                        </li>
                        <li>
                            <h5>Esclusione di Garanzie</h5>
                            <p>Il servizio Honey&Bees viene fornito "così come è" e "come disponibile". Honey&Bees esclude tutte le garanzie, espresse o implicite, incluse, ma non limitate a, le garanzie implicite di commerciabilità, idoneità per uno scopo particolare e non violazione.</p>
                        </li>
                        <li>
                            <h5>Limitazione Massima</h5>
                            <p>In nessun caso la responsabilità complessiva di Honey&Bees nei confronti dell'utente per tutti i danni, le perdite e le cause di azione (sia per contratto, torto, inclusa la negligenza, o altrimenti) supererà l'importo pagato dall'utente per accedere al servizio nei 12 mesi precedenti l'evento che ha dato origine alla responsabilità.</p>
                        </li>
                        <li>
                            <h5>Applicabilità delle Limitazioni</h5>
                            <p>Queste limitazioni di responsabilità si applicano nella massima misura consentita dalla legge applicabile e sopravvivranno alla terminazione o alla scadenza di queste Condizioni di Servizio o dell'uso del servizio da parte dell'utente.</p>
                        </li>
                    </ol>
                </li>
            </ol>
        </div>
        <Footer />
    </> 
    )
}