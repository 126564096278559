import React from "react";
import { Button } from "bootstrap";
import { Alert } from "react-bootstrap";
import sendMailInfo from "../../services/sendMailInfo";

const Form = (props) => { 
    const [name, setName] = React.useState('');
    const [email, setEmail] = React.useState('');
    const [subject, setSubject] = React.useState('');
    const [message, setMessage] = React.useState(''); 

    const sendMail = async() => {
        console.log(email, name, subject, message);
        if (email.trim() !== '' && name.trim() !== '' && subject.trim !== '' && message.trim() !== '') {

            await sendMailInfo(email, name, subject, message); 
            setEmail('');
            setName(''); 
            setSubject('');
            setMessage('');

            setTimeout(() => {
                window.location.href = '/';
            }, 3000); 
        } else {
            alert('Compila tutti i campi per inviare il messaggio');
        }
    }

    return (
        <>
            <div ref={props.contattaciRef} className="sezione-100percent text-center position-relative overflow-hidden" style={{backgroundColor:'#ffffff'}}>  
                <div  className="row justify-content-center">
                    <div className="col-9">
                        <div className="row">
                            <div className="col d-flex justify-content-center text-color p-0">
                                <h2 className="text-uppercase HeeboRegular">Contattaci</h2>
                            </div> 
                        </div> 
                        <div className="row pt-2">
                            <div className="col d-flex justify-content-center text-color">
                                <p className="HeeboThin fs-5 text-center">
                                    Compila il form sottostante per ricevere maggiori informazioni sui nostri servizi.
                                </p>
                            </div>
                        </div>
                        <div className="row align-align-items-stretch justify-content-center">
                            <div className="col-sm-12 col-md-12 col-xxl-4 mt-4">
                                <div className="position-relative">
                                    <form >
                                        <div className="mb-3"> 
                                            <input placeholder="Nome*" type="name" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" onChange={
                                                (e) => setName(e.target.value)
                                            } /> 
                                        </div> 
                                        <div className="mb-3"> 
                                            <input placeholder="Email*" type="email" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" onChange={
                                                (e) => setEmail(e.target.value)
                                            } />
                                        </div>
                                        <div className="mb-3"> 
                                            <input placeholder="Oggetto*" type="surname" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" onChange={
                                                (e) => setSubject(e.target.value)
                                            } />
                                        </div>
                                        <div className="mb-3"> 
                                            <textarea rows={6} placeholder="Scrivi il messaggio*" type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" onChange={
                                                (e) => setMessage(e.target.value)
                                            } />
                                        </div>
                                        <div className="d-flex justify-content-center">
                                            <button onClick={sendMail} type="button" className="btn myButton">Invia</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div> 
                </div>
            </div>
        </>
    );
};
export default Form;
