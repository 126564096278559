import React, { useState, useEffect } from "react";
import { Alert, Card, Form } from "react-bootstrap";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Cookies from "universal-cookie";
import ReactLoading from "react-loading";
import logoAppius from "../images/h&bdark.png";
import { loginUser, useUserDispatch } from "../context/UserContext";
import "./style.css";
import { Button } from "@mui/material";

export default function Login(props) {

  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [alert, setAlert] = useState(false);

  const userDispatch = useUserDispatch();

  useEffect(() => {
    const cookies = new Cookies();
    let username = cookies.get("username", { path: "/" });
    let token = cookies.get("token", { path: "/" });
    if (username && token) {
      setLoading(true);
      window.location.href = "/dashboard";
    } else {
      setLoading(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleLogin = async () => {
    setLoading(true);
    setAlert(false);

    if (username === "" || password === "") {

      setAlert(true);
      setLoading(false);
      setMessage("Inserisci username e password");
      return;

    } else {

      await loginUser(userDispatch, username, password, props.history, setAlert, setMessage).then((res) => {
        if (!res) {
          setLoading(false);
        } else {
          setLoading(true);
        }
      }).catch((err) => {
        console.log(err)
        setLoading(false);
      });
    }

  };

  if (loading) {
    return (
      <div className="container-fluid backgroundLogin">
        <div className="d-flex vh-100 justify-content-center align-items-center">
          <ReactLoading
            type={"bubbles"}
            color={"orange"}
            height={"10%"}
            width={"10%"}
            className="align-self-center "
          />
        </div>
      </div>
    );
  }

  return (
    <div className="container-fluid backgroundLogin">
      <div className="d-flex vh-100 justify-content-center align-items-center">
        <Card style={{ height: 500, width: 450, background: "rgba(151, 151, 151, 0.468)" }}>
          <Card.Body>
          <div className="w-100 d-flex justify-content-center">
            <img
              onClick={() => (window.location.href = "/")}
              src={logoAppius}
              alt=""
              style={{ padding: 9, width: "70%", cursor: "pointer", background: 'rgb(255 255 255 / 42%)', borderTopRightRadius:50, borderBottomLeftRadius:10}}
            />
          </div>
            <Card.Title className="d-flex flex-column align-items-center">
              <FloatingLabel
                style={{ fontSize: 18, color: "grey" }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleLogin();
                  }
                }}
                controlId="floatingInput"
                label="Username"
                className="mb-1 w-100"
                onChange={(value) => {
                  setUsername(value.target.value);
                  setAlert(false);
                }}
              >
                <Form.Control style={{ background: "rgb(255 255 255 / 31%)" }} type="username" placeholder="Username" />
              </FloatingLabel>
              <FloatingLabel
                style={{ fontSize: 18, color: "grey" }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleLogin();
                  }
                }}
                controlId="floatingPassword"
                label="Password"
                className="w-100"
                onChange={(value) => {
                  setPassword(value.target.value);
                  setAlert(false);
                }}
              >
                <Form.Control style={{ background: "rgb(255 255 255 / 31%)" }} type="password" placeholder="Password" />
              </FloatingLabel>
              {alert && <Alert style={{ fontSize: 16 }} key={"danger"} variant={"danger"} className="mt-1 w-100">{message}</Alert>}
            </Card.Title>
            <div className="d-flex flex-column">
              <Button className="mt-1 myButton w-100 mb-1" variant="contained" onClick={handleLogin}>
                Accedi
              </Button>
                {/* <a className="mb-1 text-dark" href="/register">
                Non hai un account? Registrati
              </a> */}
            </div>
          </Card.Body>
        </Card>
      </div>
    </div>
  );
}
