import React, { useState, useEffect } from "react";
import BlogJson from '../utils/JsonBlog';
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

const BlogPost = ({ match }) => {
    const [blogPost, setBlogPost] = useState(null);

    useEffect(() => {
        const page = match.params.page;
        const post = BlogJson().filter(post => post.page === page)[0];
        setBlogPost(post.component);
    }, [match.params.page]);

    if (!blogPost) {
        return <div>Caricamento...</div>;
    }

    return (
        <div>
            <Navbar />
            {blogPost}
            <Footer />
        </div>
    );
};

export default BlogPost;
