import React from "react";
import "./style.css";
import Cookies from "universal-cookie";
import { faCircleXmark, faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import addPredefinito from "../services/addPredefinito";
import deletePredefinito from "../services/deletePredefinito";
import editPredefinito from "../services/editPredefinito";
import {
	Dialog,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Slide,
	DialogActions,
	TextField,
	Button,
	Select,
	MenuItem,
	FormControlLabel,
	Checkbox,
} from "@mui/material";

const cookie = new Cookies();
const username = cookie.get("username");
const token = cookie.get("token");
const id = cookie.get("id");

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

const statoOptions = [
	"Tutti",
	"Contattato",
	"In funnel",
	"Interessato",
	"Call prenotata",
	"Call effettuata",
	"Non interessato",
	"NO SHOW",
	"Bloccato",
];

export default class Preferiti extends React.Component {
	constructor(props) {
		super(props);
		this.messageRes = React.createRef();
		this.state = {
			path: "",
			newMessage: "",
			modal: false,
			prompt: false,
			sequenza: "",
			pausa: "",
			modalEdit: false,
			selectedStato: "Tutti",
			selectedPredefinito: null,
			predefinito_id: null,
			idCampagna: null,
			predefiniti: [],
		};
	}

	componentDidMount() {
		this.reload();
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.predefiniti !== this.props.predefiniti) {
			this.setState({ predefiniti: this.props.predefiniti });
		}
	}

	async reload() {
		let path = window.location.href.split("/")[3];
		if (path === "modifica-campagna") {
			this.setState({ idCampagna: this.props.idCampagna });
		}
		this.setState({ username, token, path, predefiniti: this.props.predefiniti });
	}

	handleClose = () => {
		this.setState({
			modal: false,
			sequenza: "",
			prompt: false,
			newMessage: "",
			pausa: "",
			selectedStato: "Tutti",
			predefinito_id: null,
		});
	};

	handleCloseEdit = () => {
		this.setState({
			modalEdit: false,
			sequenza: "",
			prompt: false,
			newMessage: "",
			pausa: "",
			selectedStato: "Tutti",
			predefinito_id: null,
		});
	};

	handleSave = async () => {
		const { newMessage, sequenza, pausa, selectedStato, prompt } = this.state;
		const predefinitoEsistente = this.state.predefiniti.some(predefinito => predefinito.sequenza === sequenza);

		if (predefinitoEsistente) {
			alert("Esiste già un predefinito con la stessa sequenza");
			return;
		}

		const newPredefinito = {
			messaggio: newMessage,
			sequenza: sequenza,
			pausa: pausa,
			stato: selectedStato,
			user_id: this.props.selectedUser,
			cestino: 0,
			prompt: prompt,
		};
		this.props.onAddPredefinito(newPredefinito);
		this.handleClose();
	};

	setPredefiniti = predefiniti => {
		this.setState({ predefiniti });
	};

	handleEdit = async () => {
		const { username, token, newMessage, sequenza, pausa, selectedStato, predefinito_id, prompt } = this.state;

		const predefinitoEsistente = this.state.predefiniti.some(
			predefinito => predefinito.sequenza == sequenza && predefinito.id !== predefinito_id
		);

		if (predefinitoEsistente) {
			alert("Esiste già un predefinito con la stessa sequenza");
			return;
		}

		const predefiniti = this.state.predefiniti;
		const minSequenza = Math.min(...predefiniti.map(predefinito => predefinito.sequenza));
		if (sequenza <= minSequenza && newMessage.length > 220) {
			alert("Il messaggio è troppo lungo");
			return;
		}

		await editPredefinito(username, token, newMessage, predefinito_id, sequenza, pausa, selectedStato, prompt);
		this.props.onAddPredefinito();
		this.setState({ modalEdit: false, sequenza: "", newMessage: "", pausa: "", selectedStato: "Tutti", prompt });
	};

	editAddPredefinito = async () => {
		const predefinitoEsistente = this.state.predefiniti.some(
			predefinito => predefinito.sequenza === this.state.sequenza
		);

		if (predefinitoEsistente) {
			alert("Esiste già un predefinito con la stessa sequenza");
			return;
		}

		if (!this.state.newMessage || !this.state.sequenza || !this.state.pausa) {
			alert("Inserisci tutti i campi");
			return;
		}

		await addPredefinito(
			username,
			token,
			this.state.newMessage,
			this.state.sequenza,
			this.state.pausa,
			this.props.selectedUser,
			this.state.selectedStato,
			this.state.idCampagna,
			this.state.prompt
		);
		this.props.onAddPredefinito();
		this.handleClose();
	};

	render() {
		return (
			<div
				style={{ width: "50%", display: "flex", maxHeight: "50vh", marginTop: 5 }}
				className="p-3 overflow-scroll"
			>
				<div className="predefiniti w-100">
					<div className="row">
						<div className="col-12 d-flex align-items-center justify-content-end">
							<Button
								className="myButton"
								variant="contained"
								onClick={() => this.setState({ modal: true })}
							>
								Nuovo messaggio
							</Button>
							<Dialog
								maxWidth="sm"
								fullWidth={true}
								open={this.state.modal}
								TransitionComponent={Transition}
								keepMounted
								onClose={this.handleClose}
								aria-describedby="alert-dialog-slide-description"
							>
								<DialogTitle>Aggiungi messaggio</DialogTitle>
								<DialogContent>
									<DialogContentText id="alert-dialog-slide-description">
										Programma la sequenza e l'intermittenza del tuo prossimo messaggio
									</DialogContentText>
									<TextField
										label="Inserisci la sequenza del messaggio"
										type="number"
										fullWidth
										value={this.state.sequenza}
										onChange={e => this.setState({ sequenza: e.target.value })}
										margin="normal"
									/>
									<Select
										label="Stato del contatto"
										fullWidth
										value={this.state.selectedStato}
										onChange={e => this.setState({ selectedStato: e.target.value })}
										margin="normal"
									>
										{statoOptions.map((stato, i) => {
											return (
												<MenuItem key={i} value={stato}>
													{stato}
												</MenuItem>
											);
										})}
									</Select>
									<TextField
										label="Inserisci le ore dopo la quale questo messaggio deve essere inviato"
										type="number"
										fullWidth
										value={this.state.pausa}
										onChange={e => this.setState({ pausa: e.target.value })}
										margin="normal"
									/>
									<FormControlLabel
										control={
											<Checkbox
												checked={this.state.prompt}
												onChange={e => this.setState({ prompt: e.target.checked })}
												name="prompt"
											/>
										}
										label="Il messaggio è un prompt"
									/>
									<TextField
										label="Inserisci messaggio"
										multiline
										fullWidth
										rows={10}
										value={this.state.newMessage}
										onChange={e => this.setState({ newMessage: e.target.value })}
										margin="normal"
									/>
									<div className="d-flex px-3 mb-3">
										<Button
											className="myButton"
											variant="contained"
											onClick={() =>
												this.setState({ newMessage: this.state.newMessage + " %%nome%% " })
											}
										>
											Nome
										</Button>
										<Button
											className="myButton ms-3"
											variant="contained"
											onClick={() =>
												this.setState({ newMessage: this.state.newMessage + " %%user%% " })
											}
										>
											User
										</Button>
										{this.props.obiettivi &&
											this.props.obiettivi.length > 0 &&
											this.props.obiettivi.map((obiettivo, i) => {
												return (
													<Button
														key={i}
														className="myButton ms-3"
														variant="contained"
														onClick={() =>
															this.setState({
																newMessage:
																	this.state.newMessage +
																	` %%dati_aggiuntivi_${obiettivo.ordine}%% `,
															})
														}
													>
														dati_aggiuntivi_{obiettivo.ordine}
													</Button>
												);
											})}
									</div>
								</DialogContent>
								<DialogActions>
									<Button className="myButton" variant="contained" onClick={this.handleClose}>
										Annulla
									</Button>
									<Button
										className="myButton"
										variant="contained"
										onClick={
											this.state.path === "modifica-campagna"
												? this.editAddPredefinito
												: this.handleSave
										}
									>
										Salva
									</Button>
								</DialogActions>
							</Dialog>
						</div>
					</div>
					{this.state.predefiniti &&
						this.state.predefiniti.length > 0 &&
						this.state.predefiniti.map((predefinito, i) => {
							if (predefinito.cestino == 0)
								return (
									<div key={i} className="row m-2 w-75">
										<div className="col position-relative align-items-center border-bottom border-dark pb-3 mt-3">
											<p className="m-0 text-dark fw-bold fs-6">
												Sequenza: {predefinito.sequenza}
											</p>
											<p className="m-0 text-dark fw-bold fs-6">
												Tipo: {predefinito.prompt === 1 ? "Prompt" : "Messaggio"}
											</p>
											<p className="m-0 text-dark fw-bold fs-6">Pausa: {predefinito.pausa}</p>
											<p className="m-0 text-dark fw-bold fs-6">
												Stato del contatto: {predefinito.stato}
											</p>
											<p className="m-0 text-dark fs-7">{predefinito.messaggio}</p>
											<FontAwesomeIcon
												icon={faCircleXmark}
												style={{
													color: "red",
													fontSize: 16,
													position: "absolute",
													top: 0,
													right: 0,
													cursor: "pointer",
												}}
												onClick={async () => {
													if (
														window.confirm(
															"Sei sicuro di voler eliminare il messaggio predefinito ?"
														)
													) {
														if (this.state.path === "modifica-campagna") {
															await deletePredefinito(
																this.state.username,
																this.state.token,
																predefinito.id
															);
															this.props.onAddPredefinito();
														} else {
															this.props.onDeletePredefinito(predefinito.sequenza);
														}
													}
												}}
											/>
											{this.state.path !== "modifica-campagna" ? null : (
												<FontAwesomeIcon
													icon={faPenToSquare}
													style={{
														color: "green",
														fontSize: 16,
														position: "absolute",
														top: 0,
														right: 40,
														cursor: "pointer",
													}}
													onClick={() => {
														this.setState({
															modalEdit: true,
															sequenza: predefinito.sequenza,
															newMessage: predefinito.messaggio,
															pausa: predefinito.pausa,
															selectedStato: predefinito.stato,
															predefinito_id: predefinito.id,
															prompt: predefinito.prompt === 1 ? true : false,
														});
													}}
												/>
											)}
										</div>
									</div>
								);
						})}
					<Dialog
						maxWidth="sm"
						fullWidth={true}
						open={this.state.modalEdit}
						TransitionComponent={Transition}
						keepMounted
						onClose={this.handleClose}
						aria-describedby="alert-dialog-slide-description"
					>
						<DialogTitle>Modifica messaggio</DialogTitle>
						<DialogContent>
							<DialogContentText id="alert-dialog-slide-description">
								Modifica la sequenza e l'intermittenza del tuo prossimo messaggio
							</DialogContentText>
							<TextField
								label="Inserisci la sequenza del messaggio"
								type="number"
								fullWidth
								value={this.state.sequenza}
								onChange={e => this.setState({ sequenza: e.target.value })}
								margin="normal"
								InputLabelProps={{
									shrink: true,
								}}
							/>
							<TextField
								label="Inserisci le ore dopo la quale questo messaggio deve essere inviato"
								type="number"
								fullWidth
								value={this.state.pausa}
								onChange={e => this.setState({ pausa: e.target.value })}
								margin="normal"
								InputLabelProps={{
									shrink: true,
								}}
							/>
							<Select
								label="Stato del contatto"
								fullWidth
								value={this.state.selectedStato}
								onChange={e => this.setState({ selectedStato: e.target.value })}
								margin="normal"
								InputLabelProps={{
									shrink: this.state.selectedStato,
								}}
							>
								{statoOptions.map((stato, i) => {
									return (
										<MenuItem key={i} value={stato}>
											{stato}
										</MenuItem>
									);
								})}
							</Select>
							<FormControlLabel
								control={
									<Checkbox
										checked={this.state.prompt}
										onChange={e => this.setState({ prompt: e.target.checked })}
										name="prompt"
									/>
								}
								label="Il messaggio è un prompt"
							/>
							<TextField
								label="Inserisci messaggio"
								multiline
								fullWidth
								rows={10}
								value={this.state.newMessage}
								onChange={e => this.setState({ newMessage: e.target.value })}
								margin="normal"
								InputLabelProps={{
									shrink: Boolean(this.state.newMessage),
								}}
							/>
							<div className="d-flex px-3 mb-3">
								<Button
									className="myButton"
									variant="contained"
									onClick={() => this.setState({ newMessage: this.state.newMessage + " %%nome%% " })}
								>
									Nome
								</Button>
								<Button
									className="myButton ms-3"
									variant="contained"
									onClick={() => this.setState({ newMessage: this.state.newMessage + " %%user%% " })}
								>
									User
								</Button>
								{this.props.obiettivi &&
									this.props.obiettivi.length > 0 &&
									this.props.obiettivi.map((obiettivo, i) => {
										return (
											<Button
												key={i}
												className="myButton ms-3"
												variant="contained"
												onClick={() =>
													this.setState({
														newMessage:
															this.state.newMessage +
															` %%dati_aggiuntivi_${obiettivo.ordine}%% `,
													})
												}
											>
												dati_aggiuntivi_{obiettivo.ordine}
											</Button>
										);
									})}
							</div>
						</DialogContent>
						<DialogActions>
							<Button className="myButton" variant="contained" onClick={this.handleCloseEdit}>
								Annulla
							</Button>
							<Button className="myButton" variant="contained" onClick={() => this.handleEdit()}>
								Salva
							</Button>
						</DialogActions>
					</Dialog>
				</div>
			</div>
		);
	}
}
