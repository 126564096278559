import React from 'react'
import Cookies from 'universal-cookie';

//BOOTSTRAP 
import { Button, MenuItem, Select, TextField, Tooltip } from '@material-ui/core';

//MUI 
import ReactLoading from 'react-loading';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper'; 
import { Typography } from '@mui/material';

import ReactList from "react-list";
import LazyLoading from 'react-list-lazy-load';

//API
import getAccountSalesNavigatorLeadList from '../../services/getAccountSalesNavigatorLeadList';
import getUserAccountSalesNavigator from '../../services/getUserAccountSalesNavigator';
import searchAllAccountSalesNavigatorByName from '../../services/searchAllAccountSalesNavigatorByName';
import deleteAccountSalesNavigator from '../../services/deleteAccountSalesNavigator';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan } from '@fortawesome/free-solid-svg-icons';

const cookie = new Cookies()
const username = cookie.get('username', { path: "/" })
const token = cookie.get('token', { path: "/" })
const type = cookie.get('tipo', { path: "/" })

const headCells = [
    {
        width: 100,
        label: 'Nome',
        id: 'nome',
    },
    {
        width: 100,
        label: 'Azienda',
        id: 'azienda',
    },
    {
        width: 100,
        label: 'Zona',
        id: 'zona',
    },
    {
        width: 100,
        label: 'Job Title',
        id: 'job_title',
    },
    {
        width: 100,
        label: 'Lead list',
        id: 'lead_list',
    },
    {
        width: 100,
        label: 'Contattato',
        id: 'contacted',
    },
    {
        width: 100,
        label: 'Azioni',
        id: 'azioni',
    }
];

const inputPadding = { style: { padding: 8, width: 'fit-content' }, readOnly: true }

export default class ListeTable extends React.Component {
    constructor(props) {
        super(props);
		this.state = {
			rows: [],
			account_sales_navigator_lead_list: [],
            account_sales_navigator_lead_list_selected: 'Tutti',
			page: 1,
			maxPage: 1,
            total: 0,
            to_contacted: 0,
            not_contactebale: 0,
            contacted: 0,
            filter: '',
            reorder: false,
            user: 0,
            loading: true,
		};
    }

    componentDidMount() {
        this.reload();
    }

    async reload() {
        this.setState({ loading: true })
		await getAccountSalesNavigatorLeadList(username, token).then(res => {
			if (res["result"] === "KO") {
				this.setState({
					toastAlert: {
						open: true,
						message: "Errore durante il caricamento delle liste sales navigator",
						severity: "error",
					},
				});
				return;
			}
			this.setState({ account_sales_navigator_lead_list: res});
		});


		await getUserAccountSalesNavigator(username, token/*, this.state.page*/).then(res => {
			if (res["result"] === "KO") {
				this.setState({
					toastAlert: {
						open: true,
						message: "Errore durante il caricamento degli account",
						severity: "error",
					},
				});
				return;
			}

            this.setState({ rows: res.profili,  total: res.total, to_contacted: res.to_contacted, not_contactebale: res.not_contactebale, contacted: res.contacted, loading: false })
		});
	}

    handleFilter = async (filter, reorder, lead_list, user) => {
        this.setState({ loading: true })
        let res = await searchAllAccountSalesNavigatorByName(username, token, 1, filter, reorder, lead_list, user);
        this.setState({ rows: res.profili, filter, page: 1, total: res.total, to_contacted: res.to_contacted ? res.to_contacted : 0, not_contactebale: res.not_contactebale ? res.not_contactebale : 0, contacted: res.contacted ? res.contacted : 0, loading: false })
    }

    handlePageChange = async (page) => {
        if(!this.state.filter) {
            await getUserAccountSalesNavigator(username, token, page).then(res => {
                if (res["result"] === "KO") {
                    this.setState({
                        toastAlert: {
                            open: true,
                            message: "Errore durante il caricamento degli account",
                            severity: "error",
                        },
                    });
                    return;
                }
                this.setState({ rows: res.profili, maxPage: res.max_page, page: page })
            });
        } else {
            let res = await searchAllAccountSalesNavigatorByName(username, token, page, this.state.filter, this.state.reorder, this.state.account_sales_navigator_lead_list_selected);
            this.setState({ rows: res.profili, maxPage: res.max_page, page: page })
        }
    }

    handleDelete = async (id) => {
        let res = await deleteAccountSalesNavigator(username, token, id);
        if (res.result === 'ok') {
            searchAllAccountSalesNavigatorByName(username, token, this.state.page, this.state.filter, this.state.reorder, this.state.account_sales_navigator_lead_list_selected).then(res => {
                this.setState({ rows: res.profili, maxPage: res.max_page, total: res.total, to_contacted: res.to_contacted ? res.to_contacted : 0, not_contactebale: res.not_contactebale ? res.not_contactebale : 0, contacted: res.contacted ? res.contacted : 0 })
            })
        }
    }

    tableBodyRef = React.createRef();

    render() {

        //PAGINATON
        // let items = []
        // if (this.state.page !== 1) {
        //     items.push(<Pagination.First key='first' onClick={() => this.handlePageChange(1)} />)
        //     items.push(<Pagination.Prev key='prev' onClick={() => this.handlePageChange(this.state.page - 1)} />)
        // }
        // if (this.state.maxPage <= 5) {
        //     for (let number = 1; number <= this.state.maxPage; number++) {
        //         items.push(
        //             <Pagination.Item key={number} active={number === this.state.page} onClick={() => this.handlePageChange(number)}>
        //                 {number}
        //             </Pagination.Item>,
        //         )
        //     }
        // } else {
        //     if (this.state.page > 1) { items.push(<Pagination.Ellipsis key='elipsis1' />) }

        //     for (let number = this.state.page - 3; number <= this.state.page + 3; number++) {
        //         if (number > 0 && number <= this.state.maxPage) {
        //             items.push(
        //                 <Pagination.Item key={number} active={number === this.state.page} onClick={() => this.handlePageChange(number)}>
        //                     {number}
        //                 </Pagination.Item>,
        //             )
        //         }
        //     }

        //     if (this.state.page < this.state.maxPage) { items.push(<Pagination.Ellipsis key='elipsis2' />) }
        // }


        // if (this.state.page < this.state.maxPage) {
        //     items.push(<Pagination.Next key='next' onClick={() => this.handlePageChange(this.state.page + 1)} />)
        //     items.push(<Pagination.Last key='last' onClick={() => this.handlePageChange(this.state.maxPage)} />)
        // }

        return (
            <div style={{ width: '92%', marginInline: 'auto', marginTop: '20px', marginBottom: '20px', maxHeight: '90%', overflow: 'hidden' }}>

                {/* HEADER */}
                <div className='d-flex align-items-center'>
                    <TextField
                        InputProps={{ 
                            disableUnderline: true,
                        }}
                        label='Cerca'
                        variant="standard"
                        className='p-0 me-2 mb-2 mt-2'
                        onChange={(event) => {
                            this.setState({ filter: event.target.value })
                        }}
                        onKeyDown={(event) => {
                            if (event.key === 'Enter') {
                                this.handleFilter(event.target.value, this.state.reorder, this.state.account_sales_navigator_lead_list_selected, this.state.user)
                            }
                        }}
                        onBlur={(event) => {
                            this.handleFilter(event.target.value, this.state.reorder, this.state.account_sales_navigator_lead_list_selected, this.state.user)
                        }}
                    />
                    <div className='ms-3 d-flex'>
                        <div className='me-2'>
                            <Typography variant='p'>Lead list</Typography>
                            <Select
                                value={this.state.account_sales_navigator_lead_list_selected}
                                onChange={(event) => {
                                    this.setState({ account_sales_navigator_lead_list_selected: event.target.value })
                                    this.handleFilter(this.state.filter, this.state.reorder, event.target.value, this.state.user)
                                }}
                            >
                                <MenuItem value='Tutti'>Tutti</MenuItem>
                                {this.state.account_sales_navigator_lead_list.map((list, i) => {
                                    return (
                                        <MenuItem key={i} value={list.id}>{list.name_cliente}</MenuItem>
                                    )
                                })}
                            </Select>
                        </div>
                    </div>
                    {type === '1' &&
                        <div className='ms-3 d-flex'>
                            <div className='me-2'>
                                <Typography variant='p'>Clienti</Typography>
                                <Select defaultValue='Tutti' onChange={(event) => {
                                    this.setState({ user: event.target.value })
                                    this.handleFilter(this.state.filter, this.state.reorder, this.state.account_sales_navigator_lead_list_selected, event.target.value)
                                }}>
                                    <MenuItem value='Tutti'>Tutti</MenuItem>
                                    {this.props.users.map((user) => (
                                        <MenuItem key={user.id} value={user.id}>{user.nome_azienda} - {user.nome} {user.cognome}</MenuItem>
                                    ))}
                                </Select>
                            </div>
                        </div>
                    }
                    {/* <Pagination className='m-0 me-2 ms-auto'>
                        {items}
                    </Pagination> */}
                </div>
                <div className='d-flex'>
                    <p className='mb-1 me-2'><strong>Contatti totali: </strong> {this.state.total}</p>
                    <p className='mb-1 me-2'><strong>Contattati: </strong>{this.state.contacted}</p>
                    <p className='mb-1 me-2'><strong>Non contattabili: </strong>{this.state.not_contactebale}</p>
                    <p className='mb-1 me-2'><strong>Da contattare: </strong>{this.state.to_contacted}</p>
                </div>
                {/* TABLE */}
                <TableContainer component={Paper} sx={{ overflow: 'auto', height: '80vh'}}>
                        <Table striped bordered hover responsive ref={this.tableBodyRef}>
                            <TableHead>
                                <TableRow>
                                    {headCells.map(headCell => (
                                        <TableCell key={headCell.id} style={{ width: headCell.width + 'px' }}>{headCell.label}</TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <>
                                {!this.state.loading && this.state.rows.length > 0 && <LazyLoading length={this.state.rows.length} items={this.state.rows} onRequestPage={() => { }} >
                                    <ReactList 
                                        itemsRenderer={(items, ref) =>  <TableBody key="table_body" ref={ref}>{items}</TableBody>}
                                        itemRenderer={(index, key) => {
                                        let row = this.state.rows[index];
                                        return (
                                            <TableRow
                                                key={key}
                                            >
                                                <TableCell sx={{padding: '5px 10px'}} >
                                                    <TextField
                                                    InputProps={{ 
                                                            disableUnderline: true,
                                                            ...inputPadding
                                                        }}
                                                        defaultValue={row.nome}
                                                    />
                                                </TableCell>
                                                <TableCell sx={{padding: '5px 10px'}} >
                                                    <TextField
                                                    InputProps={{ 
                                                            disableUnderline: true,
                                                            ...inputPadding
                                                        }}
                                                        defaultValue={row.azienda}
                                                    />
                                                </TableCell>
                                                <TableCell sx={{padding: '5px 10px'}} >
                                                    <TextField
                                                    InputProps={{ 
                                                            disableUnderline: true,
                                                            ...inputPadding
                                                        }}
                                                        defaultValue={row.zona}
                                                    />
                                                </TableCell>
                                                <TableCell sx={{padding: '5px 10px'}} >
                                                    <TextField
                                                    InputProps={{ 
                                                            disableUnderline: true,
                                                            ...inputPadding
                                                        }}
                                                        defaultValue={row.job_title}
                                                    />
                                                </TableCell>
                                                <TableCell sx={{padding: '5px 10px'}} >
                                                    <TextField
                                                    InputProps={{ 
                                                            disableUnderline: true,
                                                            ...inputPadding
                                                        }}
                                                        defaultValue={row.list_name_cliente}
                                                    />
                                                </TableCell>
                                                <TableCell sx={{padding: '5px 10px'}} >
                                                    <TextField
                                                    InputProps={{ 
                                                            disableUnderline: true,
                                                            ...inputPadding
                                                        }}
                                                        defaultValue={row.contacted === 1 ? 'Si' : 'No'}
                                                    />
                                                </TableCell>
                                                <TableCell sx={{padding: '5px 10px'}} >
                                                    <Tooltip title="Elimina account" placement="top">
                                                        <Button
                                                            onClick={() =>
                                                                window.confirm(
                                                                    `Sei sicuro di voler eliminare l'account ${row.nome}?`
                                                                )
                                                                    ? this.handleDelete(row.id)
                                                                    : null
                                                            }
                                                            variant="text"
                                                            color="inherit" 
                                                        >
                                                            <FontAwesomeIcon size={"md"} icon={faTrashCan} />
                                                        </Button>
                                                    </Tooltip>
                                                </TableCell>
                                            </TableRow>
                                        )
                                    }} length={this.state.rows.length} type={"simple"} />
                                </LazyLoading> }
                                {this.state.loading && <TableRow style={{height: '60vh'}}>
                                    <TableCell colSpan={7} >
                                        <div className="d-flex justify-content-center align-items-center" style={{height: '100%', width: '100%'}}>
                                            <ReactLoading type="bubbles" color="orange" height={'15%'} width={'15%'} />
                                        </div>
                                    </TableCell>
                                </TableRow>}
                                {!this.state.loading && this.state.rows.length === 0 && (
                                    <tr>
                                        <TableCell colSpan={headCells.length} style={{ textAlign: 'center' }}>Nessun risultato</TableCell>
                                    </tr>
                                )}
                            </>
                        </Table>
                    </TableContainer>
                {/* <div className='d-flex align-items-center justify-content-between'>
                    <div className='d-flex align-items-center'>
                        <Switch 
                            checked={this.state.reorder}
                            onChange={() => {
                                this.setState({ reorder: !this.state.reorder })
                                this.handleFilter(this.state.filter, !this.state.reorder, this.state.account_sales_navigator_lead_list_selected, this.state.user)
                            }}
                        />
                        <p className='mb-0 me-2'>Ordina per "Da contattare"</p>
                    </div>
                </div> */}
            </div>
        )
    }
}