
import React, { useEffect } from "react"; 
import * as Icon from 'react-feather';
import { Link } from 'react-router-dom';
import BlogSidebar from '../../components/Blog/BlogSidebar.js'; 
import Blog from '../../utils/JsonBlog.js';
import PageBanner from "../../components/PageBanner.js";

const articleData = {
    "id": 3,
    "title": "Lead Generation",
    "desc": "La Chiave per Mantenere una Pipeline di Vendita Salutare",
    "text": "",
    "img": "/blog-image/pipelineLG.png", 
    "page": "pipeline-lead-generation",
    "data": "23 Nov 2023",
    "author": "Staff", 
}

const LeadGeneration = () => {
    const [isMobile, setMobile] = React.useState(false);

    const handleResize = () => {
        if (window.innerWidth < 1275) {
            setMobile(true);
        } else {
            setMobile(false);
        }
    }

    useEffect(() => {
        window.addEventListener("resize", handleResize);
        handleResize();

        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return( 
        <>
            <PageBanner pageTitle={articleData.title} /> 
            <div className="blog-details-area ptb-80">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 col-md-12">
                            <div className="blog-details-desc">
                                <div className="article-image">
                                    <img className="w-100" src={require('../../images' + articleData.img)} alt="imge" />
                                </div>

                                <div className="article-content">
                                    <div className="entry-meta">
                                        <ul>
                                            <li>
                                                <Icon.Clock /> {articleData.data}
                                            </li>
                                            <li>
                                                <Icon.User /> <Link className="text-decoration-none" to="#">{articleData.author}</Link>
                                            </li>
                                        </ul>
                                    </div>  
                                    <p>Identificare e nutrire opportunità di business di qualità è il punto di partenza cruciale della lead generation. Questo processo non è solo una strategia per aumentare il numero di contatti, ma piuttosto il motore che garantisce un flusso costante di opportunità di business, mantenendo così la vitalità della pipeline di vendita.</p>  
                                    <p>La lead generation sostiene una crescita graduale e costante, alimentando la pipeline di vendita con opportunità continue anziché affidarsi a picchi occasionali di attività. Questo approccio costante mantiene l'azienda in uno stato di movimento continuo verso il successo.</p>
                                    <p>Concentrandosi sulla qualità delle lead anziché sulla quantità, la lead generation ottimizza l'efficienza delle risorse di vendita. Questo significa indirizzare gli sforzi e le risorse verso prospettive più promettenti, riducendo gli sprechi di tempo su lead poco qualificate e massimizzando il rendimento delle attività di vendita.</p>
                                    <p>La lead generation comprende l'intero ciclo di vita della lead, dalla sua identificazione fino alla conversione in un cliente effettivo. Mantenere un coinvolgimento costante attraverso ogni fase del ciclo di vita è essenziale per garantire che la pipeline di vendita rimanga salutare e dinamica.</p>
                                    <p>L'uso di strumenti avanzati come il Sales Navigator su piattaforme come LinkedIn si rivela fondamentale per una lead generation efficace. Questi strumenti consentono di identificare con precisione prospettive di qualità, stabilire connessioni significative e alimentare la pipeline di vendita con lead altamente qualificate.</p>
                                    <p>Misurare le performance e ottimizzare continuamente le strategie sono parte integrante della lead generation. Analizzando costantemente le metriche di conversione e adattandosi alle mutevoli dinamiche di mercato, le aziende possono perfezionare le proprie strategie nel tempo, garantendo una pipeline di vendita sempre sana e resiliente.</p>
                                    <p>La lead generation è quindi  la chiave che mantiene la pipeline di vendita in salute. Identificare e nutrire opportunità di qualità, sostenere una crescita graduale, ottimizzare l'efficienza delle risorse di vendita e utilizzare strumenti avanzati sono passi cruciali per garantire una pipeline di vendita robusta e pronta per affrontare le sfide del mercato. Investire in una strategia di lead generation solida è un passo cruciale verso il mantenimento di una pipeline di vendita salutare e dinamica nel lungo termine.</p>
                                </div> 
                                <div style={{ marginTop: 30 }} className="startp-post-navigation"> 
                                {
                                    Blog().filter(a => a.id !== articleData.id).slice(0, 2).map(article => {
                                        return <div className="prev-link-wrapper">
                                        <div className="info-prev-link-wrapper">
                                            <Link to={"/" + article.page}>
                                                <span className="image-prev">
                                                    <img className="w-100" src={require(`../../images${article.img}`)} alt="imge" />
                                                    <span className="post-nav-title">VAI</span>
                                                </span>
            
                                                <span className="prev-link-info-wrapper">
                                                    <span className="prev-title">{article.title}</span>
                                                    <span className="meta-wrapper">
                                                        <span className="date-post">{article.data}</span>
                                                    </span>
                                                </span>
                                            </Link>
                                        </div>
                                    </div>
                                    })
                                }
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-12">
                            <BlogSidebar  id={articleData.id}/>
                        </div>
                    </div>
                </div>
            </div> 
        </>
    )
}
export default LeadGeneration;